export enum CompanyAdminRoutes {
  companyAdminBase = '/company-admin',
  companyAdminDashboard = '/company-admin/dashboard',
  companyAdminDashboardWithId = '/company-admin/:companyid/dashboard',
  companyAdminAmbassadors = '/company-admin/:companyid/ambassadors',
  companyAdminAmbassadorsArchived = '/company-admin/:companyid/ambassadors/archived',
  companyAdminAmbassadorsAdd = '/company-admin/:companyid/ambassadors/add',
  companyAdminImportEmployees = '/company-admin/:companyid/ambassadors/import',
  companyAdminConfiguration = '/company-admin/:companyid/configuration',
  companyAdminShowAmbassador = '/company-admin/:companyid/ambassadors/:employeeid',
  companyAdminEditAmbassador = '/company-admin/:companyid/ambassadors/:employeeid/edit',
  companyAdminEditAmbassadorAvatar = '/company-admin/:companyid/ambassadors/:employeeid/edit/avatar',
  companyAdminEditDescription = '/company-admin/:companyid/description/edit',
  companyAdminAppointments = '/company-admin/appointments',
  companyAdminAppointmentsWithId = '/company-admin/:companyid/appointments',
  companyAdminAppointmentsDetails = '/company-admin/:companyid/appointments/:appointmentid/details',
}

export enum MJGAdminRoutes {
  mjgAdminBase = '/mjg-admin',
  // companies
  mjgAdminCompaniesBase = '/mjg-admin/companies',
  mjgAdminCompanies = '/mjg-admin/companies/:companyid',
  mjgAdminCompaniesShow = '/mjg-admin/companies/:companyid/show',
  mjgAdminCompaniesDashboard = '/mjg-admin/companies/:companyid/dashboard',
  mjgAdminCompaniesArchived = '/mjg-admin/companies/:companyid/archived',
  mjgAdminCompaniesAddEmployee = '/mjg-admin/companies/:companyid/ambassadors/add',
  mjgAdminCompaniesImportEmployees = '/mjg-admin/companies/:companyid/ambassadors/import',
  mjgAdminCompaniesConfiguration = '/mjg-admin/companies/:companyid/configuration',
  mjgAdminCompaniesShowEmployee = '/mjg-admin/companies/:companyid/ambassadors/:employeeid',
  mjgAdminCompaniesEditEmployee = '/mjg-admin/companies/:companyid/ambassadors/:employeeid/edit',
  mjgAdminCompaniesEditEmployeeAvatar = '/mjg-admin/companies/:companyid/ambassadors/:employeeid/edit/avatar',
  mjgAdminCompaniesEditDescription = '/mjg-admin/companies/:companyid/description/edit',
  mjgAdminCompaniesAppointmentsWithId = '/mjg-admin/companies/:companyid/appointments',
  mjgAdminCompaniesAppointmentsDetails = '/mjg-admin/companies/:companyid/appointments/:appointmentid/details',
  // FDR Configuration
  mjgAdminFdrConfiguration = '/mjg-admin/fdr/configuration',
  mjgAdminFdrConfigurationSuccess = '/mjg-admin/fdr/configuration/success',
}

export enum SchoolAssignmentsRoutes {
  schoolAssignmentsBase = '/school/assignments',
}

enum DefaultRoutes {
  // Search
  search = '/search',

  // Professional
  professional = '/professionals/:id',
  oneDayWithACeo = '/one-day-with-chef-etat-major-marine',
  conversationCreation = '/professionals/:id/conversation/new',
  studentWishlist = '/student/wishlist',

  // Messaging
  conversations = '/conversations',
  conversation = '/conversations/:id',
  conversationReportInterlocutor = '/conversations/:id/report',
  bookAppointment = '/conversations/:id/appointments/create',
  claimAppointment = '/conversations/:id/appointments/claim',
  updateAppointment = '/conversations/:id/appointments/:appointment_id/update',
  deleteAppointment = '/conversations/:id/appointments/:appointment_id/delete',
  downloadConversationAppointmentIcalendar = '/conversations/:id/appointments/:appointment_id/download',
  appointments = '/appointments',
  pastAppointments = '/appointments#past',

  // Review
  reviewView = '/review/:appointmentId',
  claimReviewView = '/claim-review/:id',

  // Personal Space
  profile = '/profile',
  profileUncompletedSection = '/profile?first_uncompleted=true',
  profileAvatarEdit = '/profile/avatar/edit',
  account = '/account',
  settings = '/settings',
  settingsHibernation = '/settings/hibernation',
  settingsSoftDeletion = '/settings/optout',
  dashboard = '/dashboard',
  dashboardAcceptProgramInvitation = '/dashboard/program/accept-invitation/:invitationId',
  dashboardJoinProgram = '/dashboard/program/join',

  // Ambivalence pages
  profileAmbivalence = '/v2/profile',
  profileAmbivalenceAvailableFor = '/v2/profile?tab=MEETING_PREFERENCES',

  // Special Registrations
  ambassadorRegistrationEmailLink = '/registrations/company/employees/new',
  ambassadorRegistrationEmailLinkWithLinkedin = '/users/auth/linkedin',
  resendConfirmationEmail = '/users/confirmation/new?user%5Bemail%5D=:email',

  redCarpets = '/red-carpets',

  // fdr routes
  studentAffiliationList = '/student/affiliation',

  // old website
  oldObservatoryOfInitialEmployment = 'https://www.myjobglasses.com/observatoire-du-premier-emploi/articles/ipsos-janvier-2020/',
  mjgAdminOnChronos = '/mjg_admin',

  // softDeletion
  softDeletionGoodbye = '/😭',
  accountRestrictedPage = '/⛔️',

  // Static
  home = '/',
}

const Routes = {
  ...DefaultRoutes,
  ...CompanyAdminRoutes,
  ...MJGAdminRoutes,
  ...SchoolAssignmentsRoutes,
};
type Routes = DefaultRoutes | CompanyAdminRoutes | MJGAdminRoutes | SchoolAssignmentsRoutes;
export default Routes;
