import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';

import frMessages from './fr';
import enMessages from './en';

addLocaleData([...en, ...fr]);

export const localeData = {
  fr: frMessages,
  en: enMessages,
};

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
const language =
  (window.localStorage && window.localStorage.getItem('language')) ||
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

// Split locales with a region code
export const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

// Try full locale, fallback to locale without region code, fallback to fr
export const messages = localeData[languageWithoutRegionCode] || localeData[language] || localeData.fr;

const intlProvider = new IntlProvider({ locale: languageWithoutRegionCode, messages }, {});
export const { intl } = intlProvider.getChildContext();
