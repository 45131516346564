import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CurrentUserContext } from 'contexts/current-user-context/CurrentUserContext';
import { t } from '../../../utils/translate';
import LanguageSelectorSimple from './components/language-selector-simple/LanguageSelectorSimple';
import translations from './Footer.translations';
import SocialMediaLink from './components/social-media-link/SocialMediaLink';

import styles from './Footer.module.scss';

export default function Footer() {
  const { currentUser } = useContext(CurrentUserContext);
  const isMinor = useMemo(() => moment().diff(currentUser?.birthdate, 'years', false) < 15, []);

  return (
    <footer className={styles.footer}>
      <LanguageSelectorSimple />
      <div className={styles.linkSection}>
        <Link
          to={{ pathname: 'https://help.myjobglasses.com/fr/' }}
          target="_blank"
          className={styles.link}
        >
          {t(translations.faq)}
        </Link>
        {isMinor ? (
          <Link
            to={{
              pathname: 'https://assets.myjobglasses.com/cgu/2024-04-confidentialite-mineurs-numeriques.pdf',
            }}
            target="_blank"
            className={styles.link}
          >
            {t(translations.privacy)}
          </Link>
        ) : (
          <Link
            to={{
              pathname: 'https://www.myjobglasses.com/confidentialite-communaute-connect',
            }}
            target="_blank"
            className={styles.link}
          >
            {t(translations.privacy)}
          </Link>
        )}
        <Link
          to={{
            pathname: 'https://assets.myjobglasses.com/cgu/2025-05-28-cgu-v10.0.pdf',
          }}
          target="_blank"
          className={styles.link}
        >
          {t(translations.cgu)}
        </Link>
        <Link
          to={{ pathname: 'https://www.myjobglasses.com/mentions-legales' }}
          target="_blank"
          className={styles.link}
        >
          {t(translations.legal)}
        </Link>
        <p className={styles.branding}>
          Tous droits réservés &copy;&#32;MyJobGlasses&#32;&nbsp;
          {new Date().getFullYear()}
        </p>
      </div>
      <SocialMediaLink />
    </footer>
  );
}
