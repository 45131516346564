import { TypePolicies } from '@apollo/client';
import moment from 'moment-timezone';
import { MessageLocalStatus, QueryStoredMessagesArgs } from './@types/graphql.d';
import { LocalStorageMessage, StorageEntries, StoragePersistedMessagesEntry } from './@types/storage-entries';
import { avatar, firstName, profileId, profileType, unreadMessageCount } from './variables/privateData';
import { errorTracker } from 'error-tracker/error-tracker';

const typePolicies: TypePolicies = {
  CompanyReportTopEmployees: {
    keyFields: ['id', 'appointmentCount'],
  },
  Message: {
    fields: {
      status: {
        merge: true,
        read(_, helpers) {
          const messageId = helpers.readField<string>('id');
          if (messageId?.startsWith('fake-message')) {
            return MessageLocalStatus.Delivering;
          }
          return MessageLocalStatus.Delivered;
        },
      },
    },
  },
  Query: {
    fields: {
      localME: {
        read() {
          return {
            id: profileId(),
            profileType: profileType(),
            firstName: firstName(),
            avatar: avatar(),
            unreadMessageCount: unreadMessageCount(),
          };
        },
      },
      storedMessages: {
        read(_, { args }) {
          const { conversationId } = args as QueryStoredMessagesArgs;
          let persistedConversations: StoragePersistedMessagesEntry = {};
          try {
            persistedConversations = JSON.parse(
              localStorage.getItem(StorageEntries.persistedMessages) || '{}'
            ) as StoragePersistedMessagesEntry;
          } catch (e) {
            errorTracker.sendJSONApiError('Type policies', `Failed to persist conversation ${conversationId}`, {
              error: JSON.stringify(e),
            });
          }
          const conversationPersistedMessages = persistedConversations?.[conversationId] || [];
          return conversationPersistedMessages.map((message: LocalStorageMessage) => ({
            id: message.id,
            text: message.text,
            status: MessageLocalStatus.Error,
            createdAt: moment().toISOString(),
            fromMessageTemplateId: message.fromMessageTemplateId || null,
            sender: {
              id: profileId(),
              avatar: avatar(),
            },
          }));
        },
      },
    },
  },
};

export default typePolicies;
