import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import UsernavContext from '../../../../context/UsernavContext';
import { UsernavItemLinkProps } from 'scenes/layout/header/@types/Usernav';

const UsernavItemLink = ({ to, label, className, icon: Icon }: UsernavItemLinkProps) => {
  const { closeUsernavMenu } = useContext(UsernavContext);

  return (
    <Link
      to={to}
      className={className}
      onClick={() => closeUsernavMenu()}
    >
      {Icon && <Icon className="icon" />}
      {label}
    </Link>
  );
};

export default UsernavItemLink;
