import 'intl';
import React from 'react';
import { initializeModal } from '@myjobglasses/dsm';
import { utils, Button, Row, Jumbotron, Carousel } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import 'react-dates/initialize';
import 'moment/locale/fr';
import 'moment/locale/en-in';
import Router from './Router';
const { bootstrapUtils } = utils;
import ahoy from 'ahoy.js';

ahoy.configure({
  visitsUrl:
    window.__RUNTIME_CONFIG__.REACT_APP_AHOY_VISITS_ENDPOINT || '/ahoy/visits',
  // eslint-disable-next-line
  eventsUrl:
    window.__RUNTIME_CONFIG__.REACT_APP_AHOY_EVENTS_ENDPOINT || '/ahoy/events',
  // eslint-disable-next-line
  cookieDomain: window.__RUNTIME_CONFIG__.REACT_APP_AHOY_COOKIE_DOMAIN || '',
});

import './tarteaucitron.scss';

Modal.setAppElement('#react-modal');
initializeModal('#react-modal');

bootstrapUtils.addStyle(Button, 'default-blue');
bootstrapUtils.addStyle(Button, 'remove');
bootstrapUtils.addStyle(Button, 'cancel');
bootstrapUtils.addStyle(Button, 'filters');
bootstrapUtils.addStyle(Button, 'tag');
bootstrapUtils.addStyle(Button, 'dismiss');
bootstrapUtils.addStyle(Button, 'cta-block');
bootstrapUtils.addStyle(Button, 'vip');
bootstrapUtils.addStyle(Row, 'cover');
bootstrapUtils.addStyle(Jumbotron, 'cover-content');
bootstrapUtils.addStyle(Row, 'press-article');
bootstrapUtils.addStyle(Row, 'cover-overlay');
bootstrapUtils.addStyle(Row, 'background-video');
bootstrapUtils.addStyle(Row, 'scroll-down');
bootstrapUtils.addStyle(Carousel, 'plans');

ReactDOM.render(<Router />, document.getElementById('root'));
