import React, { PropsWithChildren } from 'react';
import PropTypes from 'prop-types';
import { Col, Grid, Row } from 'react-bootstrap';
import classNames from 'classnames';
import Footer from '../../scenes/layout/footer/Footer';
import Header from '../../scenes/layout/header/Header';

import './Layout.scss';
import 'react-loading-skeleton/dist/skeleton.css';

const SHOW_INFORMATION_BANNER = false;

const Layout = ({ children }: PropsWithChildren<{}>) => (
  <Grid
    className="layout"
    fluid
  >
    <Header />
    <Row
      className={classNames('view', {
        'view--with-banner': SHOW_INFORMATION_BANNER,
      })}
    >
      <Col md={12}>{children}</Col>
    </Row>
    <Footer />
  </Grid>
);

Layout.propTypes = {
  flow: PropTypes.string,
  isFooterVisible: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default Layout;
