import Routes from '../../../../../routes';
import { t } from '../../../../../utils/translate';
import { HeaderConfigItem } from '../../@types/HeaderConfig';
import MainMenuItem from '../../components/main-menu-item/MainMenuItem';
import UsernavItem from '../../components/usernav-item/UsernavItem';
import translations from './connect.translations';
import BarChartIcon from 'icons/component-icons/BarChartIcon';
import CalendarIcon from 'icons/component-icons/CalendarIcon';
import UsersIcon from 'icons/component-icons/UsersIcon';

// eslint-disable-next-line
const accountEndpoint = window.__RUNTIME_CONFIG__.REACT_APP_ACCOUNT_FRONTEND_URL || '';
const hermesEndpoint = window.__RUNTIME_CONFIG__.REACT_APP_MJG_ADMIN_BACKOFFICE_URL || '';

const config: { main: HeaderConfigItem[]; connected: HeaderConfigItem[] } = {
  main: [
    {
      component: MainMenuItem,
      type: 'company-link',
      key: 'dashboard',
      className: 'main-menu-item-link-white',
      to: Routes.companyAdminDashboardWithId,
      label: t(translations.dashboard),
      icon: BarChartIcon,
    },
    {
      key: 'employees',
      component: MainMenuItem,
      type: 'company-link',
      className: 'main-menu-item-link-white',
      to: Routes.companyAdminAmbassadors,
      icon: UsersIcon,
      label: t(translations.ambassador),
    },
    {
      component: MainMenuItem,
      type: 'company-link',
      key: 'appointments',
      className: 'main-menu-item-link-white',
      to: Routes.companyAdminAppointmentsWithId,
      label: t(translations.appointments),
      icon: CalendarIcon,
    },
  ],
  connected: [
    {
      component: UsernavItem,
      type: 'company-link',
      key: 'configuration',
      to: Routes.companyAdminConfiguration,
      label: t(translations.configuration),
      condition: ({ currentUser }) => !!currentUser && !currentUser.isMjgAdmin,
    },
    {
      component: UsernavItem,
      type: 'company-link',
      key: 'configuration',
      to: Routes.mjgAdminCompaniesConfiguration,
      label: t(translations.configuration),
      condition: ({ currentUser }) => !!currentUser && currentUser.isMjgAdmin,
    },
    {
      component: UsernavItem,
      type: 'external-link',
      key: 'mjg-admin-new-bo',
      to: `${hermesEndpoint}/admin`,
      label: t(translations.mjgNewBO),
      condition: ({ currentUser }) => !!currentUser && currentUser.isMjgAdmin,
    },
    {
      component: UsernavItem,
      type: 'link',
      key: 'settings',
      to: Routes.settings,
      label: t(translations.settings),
    },
    {
      component: UsernavItem,
      type: 'external-link',
      key: 'logout',
      to: `${accountEndpoint}/signout`,
      label: t(translations.logout),
    },
    {
      component: UsernavItem,
      type: 'company-switch',
      key: 'company-switch',
    },
    {
      component: UsernavItem,
      type: 'switch-account',
      key: 'switch-account',
      to: Routes.dashboard,
      label: t(translations.employeeMode),
    },
  ],
};

export default config;
