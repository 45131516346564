import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getWidthHeightAvatar } from '../../utils/avatar-sizes';

const getSizeProps = (size) => (size instanceof Object ? size : getWidthHeightAvatar(size));

const Avatar = ({ className, avatar, size }) => {
  return (
    <img
      alt={'avatar'}
      className={className}
      src={avatar?.url || 'https://assets.myjobglasses.com/images/avatar-placeholder.png'}
      style={{ ...getSizeProps(size), borderRadius: '50%' }}
    />
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.shape({
    url: PropTypes.string,
  }),
  type: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number.isRequired,
    }),
  ]),
  fallback: PropTypes.string,
};

export const AvatarWithLink = ({ href, ...props }) => (
  <Link to={href}>
    <Avatar {...props} />
  </Link>
);
AvatarWithLink.propTypes = {
  href: PropTypes.string.isRequired,
  ...Avatar.propTypes,
};

export default Avatar;
