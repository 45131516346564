import React, { useContext, useMemo } from 'react';
import classnames from 'classnames';
import Avatar from '../../../../../components/avatar/Avatar';
import { useDropdownFeature } from '../../../../../utils/hooks/useOutsideClickEffect';
import { CurrentUserContext } from 'contexts/current-user-context/CurrentUserContext';
import UsernavContext from '../../context/UsernavContext';

import './UsernavConnected.scss';
import { HeaderConfigItem } from '../../@types/HeaderConfig';
import UsernavItem from '../usernav-item/UsernavItem';
import { generatePath } from 'react-router';
import Routes from '../../../../../routes';

interface UsernavConnected {
  config?: HeaderConfigItem[];
}

const UsernavConnected = ({ config }: UsernavConnected) => {
  const { currentUser } = useContext(CurrentUserContext);
  const [ref, toggle, close, { isActive }] = useDropdownFeature();

  const configWithCompanySwitchs = useMemo<HeaderConfigItem[] | undefined>(() => {
    if (!config || !currentUser?.companyAdminProfiles) return undefined;
    const companyLinks = currentUser.companyAdminProfiles.map(
      (profile) =>
        ({
          component: UsernavItem,
          type: 'switch-account',
          key: profile.company.id || '',
          to: generatePath(Routes.companyAdminDashboardWithId, {
            companyid: profile.company.id,
          }),
          label: profile.company.name,
        } as HeaderConfigItem)
    );
    return [...config, ...companyLinks];
  }, [currentUser?.companyAdminProfiles, config]);

  return (
    <UsernavContext.Provider value={{ closeUsernavMenu: close }}>
      <div
        className="usernav-connected"
        ref={ref}
      >
        <button
          type="button"
          className={classnames('usernav-connected-avatar', {
            active: isActive,
          })}
          onClick={toggle}
        >
          <Avatar
            avatar={currentUser?.avatars}
            className="avatar"
          />
        </button>
        {Array.isArray(config) && isActive && (
          <ul className="usernav-connected-dropdown">
            {(configWithCompanySwitchs || config).map(
              ({
                component: WrappedComponent,
                condition,
                type,
                className,
                label,
                to,
                key,
                onClick,
                isNotificationSelector,
                icon,
              }) =>
                !condition || condition({ currentUser }) ? (
                  <li
                    className="usernav-connected-dropdown-item"
                    key={key}
                  >
                    <WrappedComponent
                      type={type}
                      data-testid={key}
                      className={className}
                      label={label}
                      to={to}
                      onClick={onClick}
                      isNotificationSelector={isNotificationSelector}
                      icon={icon}
                    />
                  </li>
                ) : null
            )}
          </ul>
        )}
      </div>
    </UsernavContext.Provider>
  );
};

export default UsernavConnected;
