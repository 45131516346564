import React from 'react';
import MainMenuItemLink from './components/main-menu-item-link/MainMenuItemLink';
import MainMenuItemCompany from './components/main-menu-item-company/MainMenuItemCompany';
import { MenuItemProps } from '../../@types/MainMenu';

const MainMenuItem = (props: MenuItemProps) => {
  switch (props.type) {
    case 'company-link':
      return <MainMenuItemCompany {...props} />;
    case 'link':
      return <MainMenuItemLink {...props} />;
    default:
      return null;
  }
};

export default MainMenuItem;
