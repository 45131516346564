import React, { Suspense } from 'react';
import { errorTracker } from 'error-tracker/error-tracker';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import { IntlProvider } from 'react-intl';
import { Route, Switch } from 'react-router';
import { SettingsContextProvider } from 'contexts/settings-context/SettingsContext';
import { CurrentUserContextProvider } from 'contexts/current-user-context/CurrentUserContext';
import OptionalIntercomContainer from './containers/intercom/IntercomContainer';
import Layout from './containers/layout/Layout';
import client from './graphql';
import { languageWithoutRegionCode, messages } from './locales/configure';
import Routes from './routes';
import NoNetwork from './scenes/common/no-network/NoNetwork';
import PageLoader from './scenes/common/page-loader/PageLoader';
import ModalContextProvider from 'components/modal/Modal.context';

import styles from './Router.module.scss';
import './Router.scss';
import { BrowserRouter } from 'react-router-dom';

const MainApp = React.lazy(() => import('./App'));
const MJGAdminRouter = React.lazy(() => import('./scenes/mjg-admin/MJGAdminRouter'));
const CompanyAdminRouter = React.lazy(() => import('./scenes/company-admin/CompanyAdminRouter'));
const SchoolAssignmentsRouter = React.lazy(() => import('./scenes/school-assignments/SchoolAssignmentsRouter'));

class Router extends React.Component<{}, { hasError: boolean }> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
    this.componentDidCatch = errorTracker.getBoundaryErrorHandler();
  }

  static getDerivedStateFromError(e: Error) {
    if (e?.name === 'ChunkLoadError') {
      return { hasError: true };
    }
    return { hasError: false };
  }

  render() {
    const { hasError } = this.state;
    return (
      <ApolloProvider client={client}>
        <IntlProvider
          locale={languageWithoutRegionCode}
          messages={messages}
        >
          <Suspense fallback={<PageLoader />}>
            <BrowserRouter>
              <OptionalIntercomContainer>
                <SettingsContextProvider>
                  <CurrentUserContextProvider>
                    <Layout>
                      <SnackbarProvider
                        maxSnack={5}
                        classes={{
                          variantSuccess: styles.successToastr,
                          variantError: styles.errorToastr,
                          variantWarning: styles.warningToastr,
                          variantInfo: styles.infoToastr,
                        }}
                        hideIconVariant
                      >
                        <ModalContextProvider>
                          {hasError ? (
                            <NoNetwork />
                          ) : (
                            <Switch>
                              <Route
                                path={Routes.companyAdminBase}
                                component={CompanyAdminRouter}
                              />
                              <Route
                                path={Routes.mjgAdminBase}
                                component={MJGAdminRouter}
                              />
                              <Route
                                path={Routes.schoolAssignmentsBase}
                                component={SchoolAssignmentsRouter}
                              />
                              <Route component={MainApp} />
                            </Switch>
                          )}
                        </ModalContextProvider>
                      </SnackbarProvider>
                    </Layout>
                  </CurrentUserContextProvider>
                </SettingsContextProvider>
              </OptionalIntercomContainer>
            </BrowserRouter>
          </Suspense>
        </IntlProvider>
      </ApolloProvider>
    );
  }
}

export default Router;
