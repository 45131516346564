import React from 'react';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import Loader from 'react-loaders';
import {
  Get_Unreadmessages_For_Mobile_NotificationQuery,
  Get_Unreadmessages_For_Mobile_NotificationQueryVariables,
} from '../../../../../@types/graphql.d';
import Icon from '../../../../../components/icon/Icon';
import { GET_UNREADMESSAGES_FOR_MOBILE_NOTIFICATION } from './MainMenuMobileToggle.gql';

import './MainMenuMobileToggle.scss';

interface MainMenuMobileToggleProps {
  onClick: () => void;
  className?: string;
}
function MainMenuMobileToggle({ onClick, className }: MainMenuMobileToggleProps) {
  const { data } = useQuery<
    Get_Unreadmessages_For_Mobile_NotificationQuery,
    Get_Unreadmessages_For_Mobile_NotificationQueryVariables
  >(GET_UNREADMESSAGES_FOR_MOBILE_NOTIFICATION);
  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames('main-menu-mobile-toggle', className)}
    >
      <Icon name="menu" />
      {(data?.localME.unreadMessageCount || 0) > 0 && (
        <Loader
          type="ball-scale-multiple"
          active
        />
      )}
    </button>
  );
}

export default MainMenuMobileToggle;
