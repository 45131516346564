import { UsernavItemExternalLinkProps } from 'scenes/layout/header/@types/Usernav';
import React, { useContext } from 'react';
import UsernavContext from '../../../../context/UsernavContext';

function UsernavItemExternalLink({ to, label, className, icon: Icon }: UsernavItemExternalLinkProps) {
  const { closeUsernavMenu } = useContext(UsernavContext);

  function onClick() {
    closeUsernavMenu();
  }

  return (
    <a
      href={to}
      className={className}
      onClick={onClick}
    >
      {Icon && <Icon className="icon" />}
      {label}
    </a>
  );
}

export default UsernavItemExternalLink;
