import React, { PropsWithChildren, createContext, useState } from 'react';
import { languageWithoutRegionCode } from 'locales/configure';
import { useQuery } from '@apollo/client';
import { AvailableLanguagesEnum, Get_SettingsQuery, Get_SettingsQueryVariables } from '../../@types/graphql.d';
import { GET_SETTINGS } from './SettingsContext.gql';
import { errorTracker } from 'error-tracker/error-tracker';
import useTrackerBreadcrumbs from 'hooks/use-tracker-breadcrumbs';

export const SettingsContext = createContext<Get_SettingsQuery | null>(null);

export function SettingsContextProvider({ children }: PropsWithChildren<{}>) {
  useTrackerBreadcrumbs();

  const [settings, setSettings] = useState<Get_SettingsQuery | null>(null);

  useQuery<Get_SettingsQuery, Get_SettingsQueryVariables>(GET_SETTINGS, {
    variables: {
      locale: languageWithoutRegionCode.toLowerCase() === 'en' ? AvailableLanguagesEnum.En : AvailableLanguagesEnum.Fr,
    },
    onCompleted: (data) => {
      if (data) setSettings(data);
      if (data?.currentUserV2?.id) errorTracker.userId = data?.currentUserV2?.id;
    },
  });

  return <SettingsContext.Provider value={settings}>{children}</SettingsContext.Provider>;
}
