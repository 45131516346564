export const getWidthHeightAvatar = (size) =>
  ({
    small: {
      height: 32,
      width: 32,
    },
    medium: {
      height: 64,
      width: 64,
    },
    large: {
      height: 128,
      width: 128,
    },
    xl: {
      height: 256,
      width: 256,
    },
  }[size]);
