import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import { matchPath, useRouteMatch } from 'react-router';
import Routes, { CompanyAdminRoutes, MJGAdminRoutes } from '../../../routes';
import LogoZone from './components/logo-zone/LogoZone';
import MainMenu from './components/main-menu/MainMenu';
import MainMenuMobileToggle from './components/main-menu-mobile-toggle/MainMenuMobileToggle';
import MainMenuMobileContext from './context/MainMenuMobileContext';

import styles from './Header.module.scss';
import UsernavConnected from './components/usernav-connected/UsernavConnected';
import { CompanyViewParams } from '../../../@types/routes-params.d';
import marketplaceConfig from './config/marketplace/marketplace';
import connectConfig from './config/connect/connect';
import useIsMobile from 'hooks/useIsMobile';

const Header = () => {
  const pathname = window.location.pathname;
  const isMobile = useIsMobile();

  // Use route match for company-specific routes
  const match = useRouteMatch<CompanyViewParams>({
    path: [...Object.values(CompanyAdminRoutes), ...Object.values(MJGAdminRoutes)],
    exact: true,
  });

  // Determine the header logo, variant, and configuration based on the current route
  const { headerLogo, headerVariant, config } = useMemo(() => {
    const isCompanyRoute = !!match?.params?.companyid;
    return {
      headerLogo: isCompanyRoute ? 'connect' : 'defaultLogo',
      headerVariant: isCompanyRoute ? styles.blueVariant : '',
      config: isCompanyRoute ? connectConfig : marketplaceConfig,
    };
  }, [match]);

  // Manage the state of the mobile main menu
  const [isMainMenuMobileOpened, setMainMenuMobileOpened] = useState(false);
  const toggleMainMenuMobile = () => setMainMenuMobileOpened((prev) => !prev);

  // Don't display header on mobile for specific routes
  const hideOnMobile = useMemo(() => {
    return isMobile && matchPath(pathname, Routes.conversation);
  }, [isMobile, pathname]);

  if (hideOnMobile) {
    return null;
  }

  return (
    <MainMenuMobileContext.Provider value={{ isMainMenuMobileOpened, setMainMenuMobileOpened }}>
      <header
        id="header"
        className={classnames(styles.header, headerVariant)}
      >
        <MainMenuMobileToggle
          className={styles.mainMenuMobileToggle}
          onClick={toggleMainMenuMobile}
        />
        <LogoZone
          className={styles.logoZone}
          variant={headerLogo}
        />
        <div className={styles.rowMenu}>
          <UsernavConnected config={config.connected} />
          <MainMenu config={config.main} />
        </div>
      </header>
    </MainMenuMobileContext.Provider>
  );
};

export default Header;
