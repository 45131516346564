import classnames from 'classnames';
import { CurrentUserContext } from 'contexts/current-user-context/CurrentUserContext';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LinkMenuItemProps } from 'scenes/layout/header/@types/MainMenu';

import './MainMenuItemLink.scss';

function MainMenuItemLink({
  to,
  className,
  label,
  icon: IconComponent,
  active,
  closeMainMenuMenu,
  notificationKey,
}: LinkMenuItemProps) {
  const { currentUser } = useContext(CurrentUserContext);
  const [lastRefetchAt, setLastRefetchAt] = useState<number>(Date.now());

  useEffect(() => {
    if (window.location.pathname.includes('conversations/') && Date.now() - lastRefetchAt > 10000) {
      setLastRefetchAt(Date.now());
    }
  }, [lastRefetchAt]);

  let notificationCount;
  if (notificationKey === 'unreadMessageCount') {
    notificationCount =
      (currentUser?.newMessagesFromMembersCount || 0) + (currentUser?.newMessagesFromAmbassadorsCount || 0);
  } else if (notificationKey === 'wishedProfessionalsCount') {
    notificationCount = currentUser?.wishesCount;
  }

  if (!to) {
    return (
      <span className={classnames(className, 'main-menu-item-link')}>
        <span className="main-menu-item-label">{label}</span>
      </span>
    );
  }

  return (
    <Link
      to={to}
      className={classnames(className, {
        'main-menu-item-link': true,
        active,
      })}
      data-label={label}
      data-cy={label.toLowerCase()}
      onClick={closeMainMenuMenu}
    >
      {IconComponent && <IconComponent />}
      <span className="main-menu-item-label">{label}</span>
      {!!notificationCount && <span className="notification-count">{notificationCount}</span>}
    </Link>
  );
}

export default MainMenuItemLink;
