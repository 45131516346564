export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string;
  /** A URL of an avatar image */
  AvatarUrl: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: string;
  /** A URL of a company logo */
  LogoUrl: any;
  /** A int value between 0 and 100 */
  Percentage: any;
  /** An email */
  Email: any;
  Upload: any;
};

export enum AcceptInvitationErrorEnum {
  /** Invitation already accepted */
  AlreadyAccepted = 'ALREADY_ACCEPTED',
  /** Invitation already claimed by another user */
  AlreadyClaimed = 'ALREADY_CLAIMED',
  /** Not found */
  NotFound = 'NOT_FOUND',
  /** Unauthorized / Unauthenticated */
  Unauthorized = 'UNAUTHORIZED'
}

export enum AcknowledgeConversationMutationErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

/** Autogenerated return type of AddRecruitingWorkingArea. */
export type AddRecruitingWorkingAreaPayload = {
  __typename?: 'AddRecruitingWorkingAreaPayload';
  result: Scalars['Boolean'];
  workingAreas: Array<Scalars['String']>;
};

/** The result of a AffiliateToAssignment mutation */
export type AffiliationResult = {
  __typename?: 'AffiliationResult';
  assignmentActivityStartsAt: Scalars['ISO8601DateTime'];
  earlyAffiliationWarning: Scalars['Boolean'];
};

export type AmbassadorAutocompleteResult = {
  __typename?: 'AmbassadorAutocompleteResult';
  matchingResults: Array<MatchingResult>;
  type: Scalars['String'];
};

export enum AmbassadorBadgeEnum {
  AppointmentNo_100Occurred = 'APPOINTMENT_NO_100_OCCURRED',
  AppointmentNo_10Occurred = 'APPOINTMENT_NO_10_OCCURRED',
  AppointmentNo_150Occurred = 'APPOINTMENT_NO_150_OCCURRED',
  AppointmentNo_15Occurred = 'APPOINTMENT_NO_15_OCCURRED',
  AppointmentNo_1Occurred = 'APPOINTMENT_NO_1_OCCURRED',
  AppointmentNo_1Reviewed = 'APPOINTMENT_NO_1_REVIEWED',
  AppointmentNo_200Occurred = 'APPOINTMENT_NO_200_OCCURRED',
  AppointmentNo_20Occurred = 'APPOINTMENT_NO_20_OCCURRED',
  AppointmentNo_300Occurred = 'APPOINTMENT_NO_300_OCCURRED',
  AppointmentNo_30Occurred = 'APPOINTMENT_NO_30_OCCURRED',
  AppointmentNo_3Occurred = 'APPOINTMENT_NO_3_OCCURRED',
  AppointmentNo_500Occurred = 'APPOINTMENT_NO_500_OCCURRED',
  AppointmentNo_50Occurred = 'APPOINTMENT_NO_50_OCCURRED',
  AppointmentNo_5Occurred = 'APPOINTMENT_NO_5_OCCURRED',
  AppointmentNo_75Occurred = 'APPOINTMENT_NO_75_OCCURRED',
  FirstConversationReceived = 'FIRST_CONVERSATION_RECEIVED',
  FirstConversationReplied = 'FIRST_CONVERSATION_REPLIED',
  ProfileCompletedForTheFirstTime = 'PROFILE_COMPLETED_FOR_THE_FIRST_TIME',
  ProfilePublishedForTheFirstTime = 'PROFILE_PUBLISHED_FOR_THE_FIRST_TIME',
  Registration = 'REGISTRATION'
}

/** Search result for professionals */
export type AmbassadorResults = InterlocutorInterface & UserProfileTypeInterface & {
  __typename?: 'AmbassadorResults';
  acquiredDiplomaAcquisitionYear?: Maybe<Scalars['Int']>;
  acquiredDiplomaBeginYear?: Maybe<Scalars['Int']>;
  acquiredDiplomaLevel?: Maybe<Diploma>;
  acquiredDiplomaSchoolName?: Maybe<Scalars['String']>;
  acquiredDiplomaTitle?: Maybe<Scalars['String']>;
  ambassadorPresentation?: Maybe<Scalars['String']>;
  anonymizedOrDeleted?: Maybe<Scalars['Boolean']>;
  aspirationPresentation?: Maybe<Scalars['String']>;
  avatar: Avatar;
  avatars: Avatar;
  banner?: Maybe<OrganizationCardBanner>;
  companyDescription?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<OrganizationLogo>;
  companyName?: Maybe<Scalars['String']>;
  currentGrade?: Maybe<Grade>;
  currentGradeOrDiploma?: Maybe<Scalars['String']>;
  currentSchoolCity?: Maybe<Scalars['String']>;
  currentSchoolDepartment?: Maybe<Scalars['String']>;
  currentSchoolLogo?: Maybe<OrganizationLogo>;
  currentSchoolName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  expertisePresentation?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  firstPublishedAt?: Maybe<Scalars['String']>;
  gender: GenderEnum;
  id: Scalars['ID'];
  interestsPresentation?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Language>>;
  lastName?: Maybe<Scalars['String']>;
  meetingPlace?: Maybe<Scalars['String']>;
  memberPresentation?: Maybe<Scalars['String']>;
  minor: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  positiveLikeCount?: Maybe<Scalars['Int']>;
  premium?: Maybe<Scalars['Boolean']>;
  preparedDiplomaTitle?: Maybe<Diploma>;
  /** @deprecated Use company_logo instead */
  previousCompanyLogo?: Maybe<OrganizationLogo>;
  /** @deprecated Use company_name instead */
  previousCompanyName?: Maybe<Scalars['String']>;
  /** @deprecated Use position instead */
  previousPosition?: Maybe<Scalars['String']>;
  /** @deprecated Use years_of_experience instead */
  previousPositionYearsOfExperiences?: Maybe<Scalars['Int']>;
  primarySituation?: Maybe<PrimarySituation>;
  professionalId: Scalars['ID'];
  profileType: UserProfile;
  publicLastName?: Maybe<Scalars['Boolean']>;
  schoolName?: Maybe<Scalars['String']>;
  secondarySituation?: Maybe<SecondarySituation>;
  softDeleted: Scalars['Boolean'];
  superMinor: Scalars['Boolean'];
  unavailableUntil?: Maybe<Scalars['ISO8601DateTime']>;
  visibilityStatus: VisibilityStatusEnum;
  yearsOfExperience?: Maybe<Scalars['Int']>;
};

export enum AmbassadorsAutocompleteQueryErrorEnum {
  /** Term is too short. Please provide a term with at least 3 characters. */
  TermTooShort = 'TERM_TOO_SHORT'
}

export type AmbassadorSearchResult = {
  __typename?: 'AmbassadorSearchResult';
  results: Array<AmbassadorResults>;
  total: Scalars['Int'];
};

export type AmbivalentUser = UserProfileTypeInterface & {
  __typename?: 'AmbivalentUser';
  acceptGroupConversations: Scalars['Boolean'];
  acceptedConversationTypes: Array<ConversationType>;
  accessToken?: Maybe<Scalars['String']>;
  accompaniedStudyLevels?: Maybe<Array<Grade>>;
  acquiredDiplomaAcquisitionYear?: Maybe<Scalars['Int']>;
  acquiredDiplomaBeginYear?: Maybe<Scalars['Int']>;
  acquiredDiplomaLevel?: Maybe<DiplomaEnum>;
  acquiredDiplomaSchoolName?: Maybe<Scalars['String']>;
  acquiredDiplomaTitle?: Maybe<Scalars['String']>;
  ambassadorBadges: Array<Badget>;
  ambassadorPresentation?: Maybe<Scalars['String']>;
  ambassadorProfileViewsCount: Scalars['Int'];
  ambivalenceActivated: Scalars['Boolean'];
  apprentice?: Maybe<Scalars['Boolean']>;
  aspirationPresentation?: Maybe<Scalars['String']>;
  aspirations: Array<Aspiration>;
  available: Scalars['Boolean'];
  availableConversationTypes: Array<ConversationType>;
  availableGrades: Array<GradeEnum>;
  avatar: Avatar;
  avatarUrl?: Maybe<Scalars['String']>;
  avatars: Avatar;
  birthdate?: Maybe<Scalars['ISO8601Date']>;
  bypassAvailabilityRules?: Maybe<Scalars['Boolean']>;
  bypassContactLimitRules?: Maybe<Scalars['Boolean']>;
  bypassInteractionRules?: Maybe<Scalars['Boolean']>;
  bypassMeetingPreferencesRules?: Maybe<Scalars['Boolean']>;
  canAcceptGroupConversation: Scalars['Boolean'];
  canBecomeAmbassador?: Maybe<Scalars['Boolean']>;
  canContact: Scalars['Boolean'];
  canEditCompany: Scalars['Boolean'];
  canSearchProfessionals: Scalars['Boolean'];
  canSearchStudents: Scalars['Boolean'];
  canShare: Scalars['Boolean'];
  careerStartedAt?: Maybe<Scalars['ISO8601Date']>;
  collectiveMeetingsAsAmbassadorCount: Scalars['Int'];
  companyAdminProfiles?: Maybe<Array<CompanyAdminProfile>>;
  companyDescription?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<OrganizationLogo>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companySize?: Maybe<CompanySize>;
  completionFor: ContextualCompletion;
  completionStatuses: Array<FieldCompletionStatus>;
  conversationsAsAmbassadorCount: Scalars['Int'];
  conversationsAsMemberCount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  currentGrade?: Maybe<GradeEnum>;
  currentHibernation?: Maybe<Hibernation>;
  currentScholarityEndAt?: Maybe<Scalars['ISO8601Date']>;
  currentScholarityStartAt?: Maybe<Scalars['ISO8601Date']>;
  currentSchoolCity?: Maybe<Scalars['String']>;
  currentSchoolDepartment?: Maybe<Scalars['String']>;
  currentSchoolLogo?: Maybe<OrganizationLogo>;
  currentSchoolName?: Maybe<Scalars['String']>;
  currentUserAuthorizations: UserAuthorization;
  currentUserCanContact: Scalars['Boolean'];
  currentUserCanContactErrors: Array<InteractionServiceErrorEnum>;
  currentUserWishedAmbassador: Scalars['Boolean'];
  dontSharePhoneNumber: Scalars['Boolean'];
  educations: Array<ProfileEducation>;
  email: Scalars['String'];
  expertisePresentation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstPublishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  franceTravailIdentityNonce?: Maybe<Scalars['String']>;
  galleryImages: Array<GalleryImage>;
  gamificationRank?: Maybe<Scalars['Float']>;
  gamificationScore: Scalars['Int'];
  gamificationScoreDetails: Array<GamificationScoreEntry>;
  gender?: Maybe<GenderEnum>;
  googlePlaceId?: Maybe<Scalars['String']>;
  headTeacher?: Maybe<Scalars['Boolean']>;
  hibernations: Array<Hibernation>;
  id: Scalars['ID'];
  impactedMembersAsAmbassadorCount: Scalars['Int'];
  intercomHash?: Maybe<Scalars['String']>;
  interestsPresentation?: Maybe<Scalars['String']>;
  isAdminRhModeratable: Scalars['Boolean'];
  isAmbassador: Scalars['Boolean'];
  isCoach: Scalars['Boolean'];
  isInModeration: Scalars['Boolean'];
  isMinor?: Maybe<Scalars['Boolean']>;
  isMjgAdmin: Scalars['Boolean'];
  isModeratable: Scalars['Boolean'];
  isPaidAmbassador: Scalars['Boolean'];
  isPremiumAmbassador: Scalars['Boolean'];
  isPublishable: Scalars['Boolean'];
  isUnpublishable: Scalars['Boolean'];
  jobSeekerSupportOrganizationType?: Maybe<Scalars['String']>;
  jobSeekerSupportPrograms?: Maybe<Array<Scalars['String']>>;
  /** @deprecated Use position on AmbivalentUser */
  jobTitle?: Maybe<Scalars['String']>;
  languages: Array<Language>;
  lastName?: Maybe<Scalars['String']>;
  likesReceivedCount: Scalars['Int'];
  linkedinIdentityNonce?: Maybe<Scalars['String']>;
  locatedInLaReunion: Scalars['Boolean'];
  mainAdminEmail?: Maybe<Scalars['String']>;
  mandatorySkills: Array<Scalars['String']>;
  maxContactsPerWeek?: Maybe<Scalars['Int']>;
  maxMeetingsAsAmbassadorPerMonth?: Maybe<Scalars['Int']>;
  maxMeetingsPerMonth?: Maybe<Scalars['Int']>;
  meetingPlace?: Maybe<Scalars['String']>;
  meetingPreferences?: Maybe<Array<MeetingPreference>>;
  meetingsAsAmbassadorCount: Scalars['Int'];
  meetingsAsMemberCount: Scalars['Int'];
  memberBadges: Array<Badget>;
  memberPresentation?: Maybe<Scalars['String']>;
  mergedAmbassadorPresentation: Scalars['String'];
  messageExplanationDraft?: Maybe<Scalars['String']>;
  messagePresentationDraft?: Maybe<Scalars['String']>;
  mustConfirmEmail: Scalars['Boolean'];
  mustConfirmTutorEmail: Scalars['Boolean'];
  neverWorked?: Maybe<Scalars['Boolean']>;
  newMessageCount: Scalars['Int'];
  newMessagesFromAmbassadorsCount: Scalars['Int'];
  newMessagesFromMembersCount: Scalars['Int'];
  onlyFrenchWarning: Scalars['Boolean'];
  onlyFrenchWarningConfirmText?: Maybe<Scalars['String']>;
  onlyFrenchWarningText?: Maybe<Scalars['String']>;
  participations: Array<Participation>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  phoneWithoutCountryCode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  premiumAmbassador: Scalars['Boolean'];
  premiumSchoolAmbassador: Scalars['Boolean'];
  preparedDiplomaTitle?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  presentationTranslations: PresentationTranslations;
  /** @deprecated Use company_logo instead */
  previousCompanyLogo?: Maybe<OrganizationLogo>;
  /** @deprecated Use company_name instead */
  previousCompanyName?: Maybe<Scalars['String']>;
  /** @deprecated Use position instead */
  previousPosition?: Maybe<Scalars['String']>;
  /** @deprecated Use years_of_experience instead */
  previousPositionYearsOfExperiences?: Maybe<Scalars['Int']>;
  primarySituation?: Maybe<PrimarySituation>;
  professionalId?: Maybe<Scalars['String']>;
  profileBackgroundUrl?: Maybe<Scalars['String']>;
  profileBannerDesktop?: Maybe<OrganizationProfileBannerDesktop>;
  profileBannerMobile?: Maybe<OrganizationProfileBannerMobile>;
  profileType: UserProfile;
  publicationStatus: PublicationStatuses;
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  recommendedProfessionals: Array<AmbassadorResults>;
  recommendedQualifications: Array<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  remainingContactsCount: Scalars['Int'];
  remainingMeetingsAsAmbassadorThisMonth?: Maybe<Scalars['Int']>;
  requestProfileModificationUpdate?: Maybe<Scalars['Boolean']>;
  responseRateAsAmbassador?: Maybe<Scalars['Float']>;
  responseRateAsMember?: Maybe<Scalars['Float']>;
  responseTimeAsAmbassador?: Maybe<Scalars['Int']>;
  schoolAdminProfiles?: Maybe<Array<CompanyAdminProfile>>;
  schoolDescription?: Maybe<Scalars['String']>;
  secondarySituation?: Maybe<SecondarySituation>;
  seenProfilesCount: Scalars['Int'];
  showCongratulationsPanel: Scalars['Boolean'];
  showWelcomePanel: Scalars['Boolean'];
  signedUpAsHighSchoolTeacher: Scalars['Boolean'];
  signedUpAsOrianeAmbassador: Scalars['Boolean'];
  skills: Array<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  studyLevels: Array<StudyLevel>;
  teachedSchoolSubjects?: Maybe<Array<Scalars['String']>>;
  tutorEmail?: Maybe<Scalars['String']>;
  tutorEmailConfirmedAt?: Maybe<Scalars['ISO8601DateTime']>;
  unacknowledgedMessagesCount: Scalars['Int'];
  unavailableUntil?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
  videoconferenceBetaActivated: Scalars['Boolean'];
  visibleInSearchEngine: Scalars['Boolean'];
  vocationCoachSignup?: Maybe<Scalars['Boolean']>;
  wantToShare?: Maybe<Scalars['Boolean']>;
  wishesCount: Scalars['Int'];
  wishesReceivedCount: Scalars['Int'];
  yearsOfExperience?: Maybe<Scalars['Int']>;
};


export type AmbivalentUserAvatarUrlArgs = {
  version?: Maybe<PictureVersion>;
};


export type AmbivalentUserCompletionForArgs = {
  completionContext: CompletionContextEnum;
};

/** Possible return values for antivirus */
export enum AntivirusStatus {
  /** attachment has been approved by antivirus */
  AttachmentAccepted = 'ATTACHMENT_ACCEPTED',
  /** attachment has been rejected by antivirus */
  AttachmentRejected = 'ATTACHMENT_REJECTED',
  /** scan is not finshed yet */
  ScanInProgress = 'SCAN_IN_PROGRESS'
}

export type Appointment = {
  __typename?: 'Appointment';
  ambassador: AppointmentInterlocutor;
  ambassadorReportedStatus?: Maybe<AppointmentStatus>;
  ambassadorReview?: Maybe<AppointmentReviewFromProfessional>;
  appointmentClaim?: Maybe<AppointmentClaim>;
  appointmentType: ConversationTypeKeyEnum;
  cancelled: Scalars['Boolean'];
  conversation: Conversation;
  conversationId: Scalars['ID'];
  createdAt: Scalars['ISO8601DateTime'];
  currentUserCanCancel: Scalars['Boolean'];
  currentUserCanMove: Scalars['Boolean'];
  currentUserCanReview: Scalars['Boolean'];
  currentUserCanSnooze: Scalars['Boolean'];
  currentUserInterlocutor: AppointmentInterlocutor;
  currentUserInterlocutorAspiration?: Maybe<StudentAspiration>;
  currentUserInterlocutorReviewMessage?: Maybe<Scalars['String']>;
  currentUserIsAmbassador: Scalars['Boolean'];
  currentUserIsMember: Scalars['Boolean'];
  currentUserIsProfessional: Scalars['Boolean'];
  currentUserMustReview: Scalars['Boolean'];
  currentUserRecommendInterlocutor: Scalars['Boolean'];
  currentUserRecommendedByInterlocutor: Scalars['Boolean'];
  currentUserReviewMessage?: Maybe<Scalars['String']>;
  from: Scalars['ISO8601DateTime'];
  googleCalendarUrl: Scalars['String'];
  googlePlaceId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interlocutor: AppointmentInterlocutor;
  interlocutorAspiration?: Maybe<StudentAspiration>;
  interlocutorReviewMessage?: Maybe<Scalars['String']>;
  meetingPlace?: Maybe<Scalars['String']>;
  member: AppointmentInterlocutor;
  memberReportedStatus?: Maybe<AppointmentStatus>;
  memberReview?: Maybe<AppointmentReviewFromStudent>;
  professional?: Maybe<PublicProfessionalProfile>;
  professionalStatus?: Maybe<Scalars['String']>;
  reviewFromProfessional?: Maybe<AppointmentReviewFromProfessional>;
  reviewFromStudent?: Maybe<AppointmentReviewFromStudent>;
  seeker?: Maybe<PublicStudentProfile>;
  startAt: Scalars['ISO8601DateTime'];
  status?: Maybe<AppointmentStatus>;
  studentStatus?: Maybe<Scalars['String']>;
  type: AppointmentTypesEnum;
  updatedAt: Scalars['ISO8601DateTime'];
  videoconferenceLink?: Maybe<Scalars['String']>;
  yahooCalendarUrl: Scalars['String'];
};

export enum AppointmentBookingErrorEnum {
  AlreadyHasAFutureAppointment = 'ALREADY_HAS_A_FUTURE_APPOINTMENT',
  AmbassadorMustHaveAReply = 'AMBASSADOR_MUST_HAVE_A_REPLY',
  ConversationIsDisabled = 'CONVERSATION_IS_DISABLED',
  MustBeTheConversationRecipient = 'MUST_BE_THE_CONVERSATION_RECIPIENT'
}

/** A record of a seeker claiming that an appointment has occurred in the past. */
export type AppointmentClaim = {
  __typename?: 'AppointmentClaim';
  accepted?: Maybe<Scalars['Boolean']>;
  ambassador: AppointmentInterlocutor;
  appointment?: Maybe<Appointment>;
  conversation: Conversation;
  createdAt: Scalars['ISO8601DateTime'];
  currentUserCanReview: Scalars['Boolean'];
  currentUserCanSnooze: Scalars['Boolean'];
  currentUserIsAmbassador: Scalars['Boolean'];
  currentUserIsMember: Scalars['Boolean'];
  currentUserMustReview: Scalars['Boolean'];
  from: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  interlocutor: AppointmentInterlocutor;
  meetingPlace?: Maybe<Scalars['String']>;
  member: AppointmentInterlocutor;
  reason?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['ISO8601DateTime']>;
  startAt: Scalars['ISO8601DateTime'];
  timeZone: Scalars['String'];
  to: Scalars['ISO8601DateTime'];
  type: AppointmentTypesEnum;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for AppointmentClaim. */
export type AppointmentClaimConnection = {
  __typename?: 'AppointmentClaimConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AppointmentClaimEdge>>;
  /** A list of nodes. */
  nodes: Array<AppointmentClaim>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type AppointmentClaimEdge = {
  __typename?: 'AppointmentClaimEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AppointmentClaim>;
};

/** List of filters for appointment claims query */
export type AppointmentClaimsFilter = {
  conversationIdEq?: Maybe<Scalars['ID']>;
  currentUserMustReview?: Maybe<Scalars['Boolean']>;
  resolved?: Maybe<Scalars['Boolean']>;
  startAtGt?: Maybe<Scalars['ISO8601DateTime']>;
  startAtLt?: Maybe<Scalars['ISO8601DateTime']>;
};

export enum AppointmentClaimsQueryErrorEnum {
  Unauthorized = 'UNAUTHORIZED'
}

/** List of sorts for appointments claim query */
export enum AppointmentClaimsSortFieldsEnum {
  StartAtAsc = 'START_AT_ASC',
  StartAtDesc = 'START_AT_DESC'
}

/** The connection type for Appointment. */
export type AppointmentConnection = {
  __typename?: 'AppointmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AppointmentEdge>>;
  /** A list of nodes. */
  nodes: Array<Appointment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export enum AppointmentDidNotTookPlaceReasonEnum {
  Cancelled = 'CANCELLED',
  DidNotShowUp = 'DID_NOT_SHOW_UP',
  ForgotToGo = 'FORGOT_TO_GO',
  Other = 'OTHER',
  Rescheduled = 'RESCHEDULED',
  WasNotScheduled = 'WAS_NOT_SCHEDULED'
}

/** An edge in a connection. */
export type AppointmentEdge = {
  __typename?: 'AppointmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Appointment>;
};

export type AppointmentInterlocutor = InterlocutorInterface & UserProfileTypeInterface & {
  __typename?: 'AppointmentInterlocutor';
  acquiredDiplomaAcquisitionYear?: Maybe<Scalars['Int']>;
  acquiredDiplomaBeginYear?: Maybe<Scalars['Int']>;
  acquiredDiplomaLevel?: Maybe<Diploma>;
  acquiredDiplomaSchoolName?: Maybe<Scalars['String']>;
  acquiredDiplomaTitle?: Maybe<Scalars['String']>;
  ambassadorPresentation?: Maybe<Scalars['String']>;
  anonymizedOrDeleted?: Maybe<Scalars['Boolean']>;
  aspirationPresentation?: Maybe<Scalars['String']>;
  avatar: Avatar;
  avatars: Avatar;
  banner?: Maybe<OrganizationCardBanner>;
  companyDescription?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<OrganizationLogo>;
  companyName?: Maybe<Scalars['String']>;
  currentGrade?: Maybe<Grade>;
  currentGradeOrDiploma?: Maybe<Scalars['String']>;
  currentSchoolCity?: Maybe<Scalars['String']>;
  currentSchoolDepartment?: Maybe<Scalars['String']>;
  currentSchoolLogo?: Maybe<OrganizationLogo>;
  currentSchoolName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  expertisePresentation?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender: GenderEnum;
  id: Scalars['ID'];
  interestsPresentation?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  meetingPlace?: Maybe<Scalars['String']>;
  memberPresentation?: Maybe<Scalars['String']>;
  minor: Scalars['Boolean'];
  normalizedPhone?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  preparedDiplomaTitle?: Maybe<Diploma>;
  /** @deprecated Use company_logo instead */
  previousCompanyLogo?: Maybe<OrganizationLogo>;
  /** @deprecated Use company_name instead */
  previousCompanyName?: Maybe<Scalars['String']>;
  /** @deprecated Use position instead */
  previousPosition?: Maybe<Scalars['String']>;
  /** @deprecated Use years_of_experience instead */
  previousPositionYearsOfExperiences?: Maybe<Scalars['Int']>;
  primarySituation?: Maybe<PrimarySituation>;
  profileType: UserProfile;
  schoolName?: Maybe<Scalars['String']>;
  secondarySituation?: Maybe<SecondarySituation>;
  softDeleted: Scalars['Boolean'];
  superMinor: Scalars['Boolean'];
  visibilityStatus: VisibilityStatusEnum;
  yearsOfExperience?: Maybe<Scalars['Int']>;
};

/** Temporal state of an appointment */
export enum AppointmentListScope {
  /** All */
  All = 'ALL',
  /** confirmed by the requesting profile */
  ConfirmedBySelf = 'CONFIRMED_BY_SELF',
  /** Only the next one */
  Next = 'NEXT',
  /** All the pasts */
  Past = 'PAST',
  /** All the futures */
  Upcoming = 'UPCOMING'
}

/** The interface of appointment review */
export type AppointmentReview = {
  answer1?: Maybe<Scalars['Int']>;
  answer2?: Maybe<Scalars['Int']>;
  answer3?: Maybe<Scalars['Int']>;
  appointmentStatus: AppointmentStatus;
  question1?: Maybe<Scalars['String']>;
  question2?: Maybe<Scalars['String']>;
  question3?: Maybe<Scalars['String']>;
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** An appointment review from professional */
export type AppointmentReviewFromProfessional = AppointmentReview & {
  __typename?: 'AppointmentReviewFromProfessional';
  answer1?: Maybe<Scalars['Int']>;
  answer2?: Maybe<Scalars['Int']>;
  answer3?: Maybe<Scalars['Int']>;
  appointmentStatus: AppointmentStatus;
  message?: Maybe<Scalars['String']>;
  messageForHr?: Maybe<Scalars['String']>;
  messageForHrRequired: Scalars['Boolean'];
  question1?: Maybe<Scalars['String']>;
  question2?: Maybe<Scalars['String']>;
  question2Skipable: Scalars['Boolean'];
  question3?: Maybe<Scalars['String']>;
  question3Skipable: Scalars['Boolean'];
  reviewQuestion1: Scalars['String'];
  reviewQuestion2: Scalars['String'];
  reviewQuestion3: Scalars['String'];
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']>;
};


/** An appointment review from professional */
export type AppointmentReviewFromProfessionalReviewQuestion1Args = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


/** An appointment review from professional */
export type AppointmentReviewFromProfessionalReviewQuestion2Args = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


/** An appointment review from professional */
export type AppointmentReviewFromProfessionalReviewQuestion3Args = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

/** An appointment review from student */
export type AppointmentReviewFromStudent = AppointmentReview & {
  __typename?: 'AppointmentReviewFromStudent';
  answer1?: Maybe<Scalars['Int']>;
  answer2?: Maybe<Scalars['Int']>;
  answer3?: Maybe<Scalars['Int']>;
  appointmentStatus: AppointmentStatus;
  aspiration?: Maybe<StudentAspiration>;
  availableWorkingAreas?: Maybe<Array<Scalars['String']>>;
  canFillAspiration: Scalars['Boolean'];
  canFillSchoolAspiration: Scalars['Boolean'];
  companySubsidiaryDesignation?: Maybe<Scalars['String']>;
  liked?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  question1?: Maybe<Scalars['String']>;
  question2?: Maybe<Scalars['String']>;
  question3?: Maybe<Scalars['String']>;
  reviewQuestion0: Scalars['String'];
  reviewQuestion1: Scalars['String'];
  reviewQuestion2: Scalars['String'];
  reviewQuestion3: Scalars['String'];
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']>;
  schoolAspiration?: Maybe<SchoolAspiration>;
  schoolInterestAvailableDates?: Maybe<Array<SchoolInterestAvailableDates>>;
  schoolInterestAvailableGrades?: Maybe<Array<SchoolInterestAvailableGrades>>;
  schoolInterestLevelAndYearQuestionTitle: Scalars['String'];
  schoolInterestLevelQuestion: Scalars['String'];
  schoolInterestWantToBeRecontactedQuestion: Scalars['String'];
  schoolInterestWantToBeRecontactedTitle: Scalars['String'];
  schoolInterestYearQuestion: Scalars['String'];
  wantToBeRecontacted?: Maybe<Scalars['Boolean']>;
  wantsToBeRecontactedBySchool?: Maybe<Scalars['Boolean']>;
};


/** An appointment review from student */
export type AppointmentReviewFromStudentCompanySubsidiaryDesignationArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


/** An appointment review from student */
export type AppointmentReviewFromStudentReviewQuestion0Args = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


/** An appointment review from student */
export type AppointmentReviewFromStudentReviewQuestion1Args = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


/** An appointment review from student */
export type AppointmentReviewFromStudentReviewQuestion2Args = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


/** An appointment review from student */
export type AppointmentReviewFromStudentReviewQuestion3Args = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


/** An appointment review from student */
export type AppointmentReviewFromStudentSchoolInterestLevelAndYearQuestionTitleArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


/** An appointment review from student */
export type AppointmentReviewFromStudentSchoolInterestLevelQuestionArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


/** An appointment review from student */
export type AppointmentReviewFromStudentSchoolInterestWantToBeRecontactedQuestionArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


/** An appointment review from student */
export type AppointmentReviewFromStudentSchoolInterestWantToBeRecontactedTitleArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


/** An appointment review from student */
export type AppointmentReviewFromStudentSchoolInterestYearQuestionArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

/** List of filters for appointments query */
export type AppointmentsFilter = {
  cancelled?: Maybe<Scalars['Boolean']>;
  conversationIdEq?: Maybe<Scalars['ID']>;
  currentUserCanReview?: Maybe<Scalars['Boolean']>;
  currentUserMustReview?: Maybe<Scalars['Boolean']>;
  fromGt?: Maybe<Scalars['ISO8601DateTime']>;
  fromLt?: Maybe<Scalars['ISO8601DateTime']>;
  idEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Scalars['ID']>>;
  idNin?: Maybe<Array<Scalars['ID']>>;
  past?: Maybe<Scalars['Boolean']>;
  seekerIdEq?: Maybe<Scalars['ID']>;
  seekerIdIn?: Maybe<Array<Scalars['ID']>>;
  startAtGt?: Maybe<Scalars['ISO8601DateTime']>;
  startAtLt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** List of sorts for appointments query */
export enum AppointmentsSortFieldsEnum {
  FromAsc = 'FROM_ASC',
  FromDesc = 'FROM_DESC',
  StartAtAsc = 'START_AT_ASC',
  StartAtDesc = 'START_AT_DESC'
}

/** status of an appointment from an individual point of view */
export enum AppointmentStatus {
  /** translation missing: en.graphql.schema.enums.appointment_status.awaiting_review */
  AwaitingReview = 'AWAITING_REVIEW',
  /** translation missing: en.graphql.schema.enums.appointment_status.both_forgot_to_go */
  BothForgotToGo = 'BOTH_FORGOT_TO_GO',
  /** translation missing: en.graphql.schema.enums.appointment_status.cancelled */
  Cancelled = 'CANCELLED',
  /** translation missing: en.graphql.schema.enums.appointment_status.conflict */
  Conflict = 'CONFLICT',
  /** translation missing: en.graphql.schema.enums.appointment_status.did_not_show_up */
  DidNotShowUp = 'DID_NOT_SHOW_UP',
  /** translation missing: en.graphql.schema.enums.appointment_status.forgot_to_go */
  ForgotToGo = 'FORGOT_TO_GO',
  /** translation missing: en.graphql.schema.enums.appointment_status.other */
  Other = 'OTHER',
  /** translation missing: en.graphql.schema.enums.appointment_status.rescheduled */
  Rescheduled = 'RESCHEDULED',
  /** translation missing: en.graphql.schema.enums.appointment_status.stood_up_by_professional */
  StoodUpByProfessional = 'STOOD_UP_BY_PROFESSIONAL',
  /** translation missing: en.graphql.schema.enums.appointment_status.stood_up_by_student */
  StoodUpByStudent = 'STOOD_UP_BY_STUDENT',
  /** translation missing: en.graphql.schema.enums.appointment_status.took_place */
  TookPlace = 'TOOK_PLACE',
  /** translation missing: en.graphql.schema.enums.appointment_status.unbooked */
  Unbooked = 'UNBOOKED',
  /** translation missing: en.graphql.schema.enums.appointment_status.upcoming */
  Upcoming = 'UPCOMING',
  /** translation missing: en.graphql.schema.enums.appointment_status.was_not_scheduled */
  WasNotScheduled = 'WAS_NOT_SCHEDULED'
}

/** Appointments meeting types */
export enum AppointmentTypesEnum {
  Phone = 'PHONE',
  Physical = 'PHYSICAL',
  Video = 'VIDEO'
}

export enum ApproveAppointmentClaimMutationErrorEnum {
  AlreadyResolved = 'ALREADY_RESOLVED',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

export type Aspiration = {
  __typename?: 'Aspiration';
  description: Scalars['String'];
  duration: InternshipDuration;
  id: Scalars['ID'];
  startsAtMonth: Scalars['Int'];
  startsAtYear: Scalars['Int'];
  status: InternshipStatus;
  type: InternshipTypeEnum;
  user: AmbivalentUser;
};

export type AspirationsInput = {
  duration: InternshipDuration;
  franceLevel1AdministrativeAreas?: Maybe<Array<FranceLevel1AdministrativeAreasEnum>>;
  internshipType: InternshipTypeEnum;
  postalCode: Scalars['String'];
  startAt: Scalars['ISO8601Date'];
  workingAreas?: Maybe<Array<Scalars['String']>>;
};

/** An assignment participant */
export type AssignmentParticipant = {
  __typename?: 'AssignmentParticipant';
  affiliatedAt: Scalars['ISO8601DateTime'];
  appointmentWithFeedbackCount?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Scalars['AvatarUrl']>;
  conversationCount?: Maybe<Scalars['Int']>;
  conversationWithoutReplyFromStudentCount?: Maybe<Scalars['Int']>;
  firstAppointmentAt?: Maybe<Scalars['ISO8601DateTime']>;
  firstConversationAt?: Maybe<Scalars['ISO8601DateTime']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  pendingAppointmentCount?: Maybe<Scalars['Int']>;
  status: StudentStatus;
};


/** An assignment participant */
export type AssignmentParticipantAvatarArgs = {
  version?: Maybe<PictureVersion>;
};

/** Temporal state of an assignment */
export enum AssignmentsListScope {
  /** The current one */
  Current = 'CURRENT',
  /** All the pasts */
  Past = 'PAST',
  /** All the futures */
  Upcoming = 'UPCOMING'
}

export type AttrChange = {
  __typename?: 'AttrChange';
  attr: Scalars['String'];
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

/** List of all available locals for translated fields */
export enum AvailableLanguagesEnum {
  En = 'EN',
  Fr = 'FR'
}

export type Avatar = {
  __typename?: 'Avatar';
  small: Image;
  thumb: Image;
  url?: Maybe<Scalars['String']>;
};


export enum BadgeEnum {
  AccountCreated = 'ACCOUNT_CREATED',
  AppointmentNo_100Occurred = 'APPOINTMENT_NO_100_OCCURRED',
  AppointmentNo_10Occurred = 'APPOINTMENT_NO_10_OCCURRED',
  AppointmentNo_150Occurred = 'APPOINTMENT_NO_150_OCCURRED',
  AppointmentNo_15Occurred = 'APPOINTMENT_NO_15_OCCURRED',
  AppointmentNo_1Occurred = 'APPOINTMENT_NO_1_OCCURRED',
  AppointmentNo_1Reviewed = 'APPOINTMENT_NO_1_REVIEWED',
  AppointmentNo_200Occurred = 'APPOINTMENT_NO_200_OCCURRED',
  AppointmentNo_20Occurred = 'APPOINTMENT_NO_20_OCCURRED',
  AppointmentNo_300Occurred = 'APPOINTMENT_NO_300_OCCURRED',
  AppointmentNo_30Occurred = 'APPOINTMENT_NO_30_OCCURRED',
  AppointmentNo_3Occurred = 'APPOINTMENT_NO_3_OCCURRED',
  AppointmentNo_500Occurred = 'APPOINTMENT_NO_500_OCCURRED',
  AppointmentNo_50Occurred = 'APPOINTMENT_NO_50_OCCURRED',
  AppointmentNo_5Occurred = 'APPOINTMENT_NO_5_OCCURRED',
  AppointmentNo_75Occurred = 'APPOINTMENT_NO_75_OCCURRED',
  FifteenthAppointmentOccurred = 'FIFTEENTH_APPOINTMENT_OCCURRED',
  FifthAppointmentOccurred = 'FIFTH_APPOINTMENT_OCCURRED',
  FiftiethAppointmentOccurred = 'FIFTIETH_APPOINTMENT_OCCURRED',
  FirstAppointmentOccurred = 'FIRST_APPOINTMENT_OCCURRED',
  FirstAppointmentReview = 'FIRST_APPOINTMENT_REVIEW',
  FirstConversationReceived = 'FIRST_CONVERSATION_RECEIVED',
  FirstConversationReplied = 'FIRST_CONVERSATION_REPLIED',
  FirstMessageSent = 'FIRST_MESSAGE_SENT',
  HundredthAppointmentOccurred = 'HUNDREDTH_APPOINTMENT_OCCURRED',
  ProfileComplete = 'PROFILE_COMPLETE',
  ProfileCompletedForTheFirstTime = 'PROFILE_COMPLETED_FOR_THE_FIRST_TIME',
  ProfilePublishedForTheFirstTime = 'PROFILE_PUBLISHED_FOR_THE_FIRST_TIME',
  Registration = 'REGISTRATION',
  SeventyFifthAppointmentOccurred = 'SEVENTY_FIFTH_APPOINTMENT_OCCURRED',
  TenthAppointmentOccurred = 'TENTH_APPOINTMENT_OCCURRED',
  ThirdAppointmentOccurred = 'THIRD_APPOINTMENT_OCCURRED',
  ThirthiethAppointmentOccurred = 'THIRTHIETH_APPOINTMENT_OCCURRED',
  TwentiethAppointmentOccurred = 'TWENTIETH_APPOINTMENT_OCCURRED'
}

export type Badget = {
  __typename?: 'Badget';
  completed: Scalars['Boolean'];
  name: BadgeEnum;
};

export type Bold = {
  __typename?: 'Bold';
  length: Scalars['Int'];
  offset: Scalars['Int'];
};

export enum BookAppointmentMutationErrorEnum {
  AlreadyHasAFutureAppointment = 'ALREADY_HAS_A_FUTURE_APPOINTMENT',
  AmbassadorMustHaveAReply = 'AMBASSADOR_MUST_HAVE_A_REPLY',
  BookAppointmentUnauthorized = 'BOOK_APPOINTMENT_UNAUTHORIZED',
  ConversationIsDisabled = 'CONVERSATION_IS_DISABLED',
  MeetingPlaceRequiredForPhysicalAppointment = 'MEETING_PLACE_REQUIRED_FOR_PHYSICAL_APPOINTMENT',
  MustBeTheConversationRecipient = 'MUST_BE_THE_CONVERSATION_RECIPIENT',
  MustHaveConversationTogether = 'MUST_HAVE_CONVERSATION_TOGETHER',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedValidationErrors = 'UNEXPECTED_VALIDATION_ERRORS'
}

export type Bucket = {
  __typename?: 'Bucket';
  docCount?: Maybe<Scalars['Int']>;
  key: Scalars['String'];
  label?: Maybe<Scalars['String']>;
};

export type Change = {
  __typename?: 'Change';
  changes: Array<AttrChange>;
  employee: CompanyEmployee;
};

export type CheckEmailAvailabilityQueryPayload = {
  __typename?: 'CheckEmailAvailabilityQueryPayload';
  available: Scalars['Boolean'];
  hasFranceTravailIdentity: Scalars['Boolean'];
  hasLinkedinIdentity: Scalars['Boolean'];
  hasPassword: Scalars['Boolean'];
  isConfirmed: Scalars['Boolean'];
  softDeleted: Scalars['Boolean'];
};

export enum ClaimAppointmentMutationErrorEnum {
  AllClaimsMustBeResolved = 'ALL_CLAIMS_MUST_BE_RESOLVED',
  AlreadyExists = 'ALREADY_EXISTS',
  AmbassadorMustHaveAReply = 'AMBASSADOR_MUST_HAVE_A_REPLY',
  AmbassadorSoftDeleted = 'AMBASSADOR_SOFT_DELETED',
  ClaimAppointmentUnauthorized = 'CLAIM_APPOINTMENT_UNAUTHORIZED',
  ClaimInvalid = 'CLAIM_INVALID',
  ConversationIsDisabled = 'CONVERSATION_IS_DISABLED',
  InitiatorSoftDeleted = 'INITIATOR_SOFT_DELETED',
  MeetingPlaceRequired = 'MEETING_PLACE_REQUIRED',
  MustInitiateAConversation = 'MUST_INITIATE_A_CONVERSATION',
  NotFound = 'NOT_FOUND',
  StartedAtMustBePast = 'STARTED_AT_MUST_BE_PAST',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedValidationErrors = 'UNEXPECTED_VALIDATION_ERRORS'
}

export enum ClaimCertificateErrorEnum {
  /** Current user cannot claim certificate */
  CannotClaimCertificate = 'CANNOT_CLAIM_CERTIFICATE',
  /** Not found */
  NotFound = 'NOT_FOUND',
  /** Unauthorized / Unauthenticated */
  Unauthorized = 'UNAUTHORIZED'
}

/** A coach assignment */
export type CoachAssignment = {
  __typename?: 'CoachAssignment';
  affiliatedStudentCount: Scalars['Int'];
  campusName: Scalars['String'];
  dataRecalculatedAt: Scalars['ISO8601DateTime'];
  diplomaLevel: DiplomaLevel;
  endsAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  participants: Array<AssignmentParticipant>;
  schoolName: Scalars['String'];
  size: Scalars['Int'];
  startsAt: Scalars['ISO8601DateTime'];
  studentWithAppointmentWithFeedbackCount?: Maybe<Scalars['Int']>;
  studentWithConversationCount?: Maybe<Scalars['Int']>;
};

export type CollaborationUser = {
  __typename?: 'CollaborationUser';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
};

export type ComesFrom = {
  __typename?: 'ComesFrom';
  key: ComesFromKeyEnum;
  name: Scalars['String'];
};


export type ComesFromNameArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

/** Comes From Enum */
export enum ComesFromEnum {
  Event = 'EVENT',
  FranceTravail = 'FRANCE_TRAVAIL',
  Media = 'MEDIA',
  Other = 'OTHER',
  SearchEngine = 'SEARCH_ENGINE',
  SocialNetwork = 'SOCIAL_NETWORK',
  Webinar = 'WEBINAR',
  WordOfMouth = 'WORD_OF_MOUTH',
  YourCompany = 'YOUR_COMPANY',
  YourSchool = 'YOUR_SCHOOL'
}

export enum ComesFromKeyEnum {
  Event = 'EVENT',
  FranceTravail = 'FRANCE_TRAVAIL',
  Media = 'MEDIA',
  Other = 'OTHER',
  SearchEngine = 'SEARCH_ENGINE',
  SocialNetwork = 'SOCIAL_NETWORK',
  Webinar = 'WEBINAR',
  WordOfMouth = 'WORD_OF_MOUTH',
  YourCompany = 'YOUR_COMPANY',
  YourSchool = 'YOUR_SCHOOL'
}

/** Available language in email and communication */
export enum CommunicationLanguage {
  /** English */
  English = 'ENGLISH',
  /** French */
  French = 'FRENCH'
}

/** A company */
export type Company = {
  __typename?: 'Company';
  activeProfilePercentage: Scalars['Percentage'];
  companyLogo?: Maybe<Scalars['LogoUrl']>;
  companySector?: Maybe<Scalars['String']>;
  companySize?: Maybe<CompanySize>;
  createdAt: Scalars['ISO8601DateTime'];
  crmActivation: Scalars['Boolean'];
  customAttributesSet: CustomAttributesSet;
  description?: Maybe<Scalars['String']>;
  employeePackSize: Scalars['Int'];
  employeesAvailablePlacesCount: Scalars['Int'];
  employeesCustomAttribute1Distribution?: Maybe<CustomAttributeDistribution>;
  employeesCustomAttribute2Distribution?: Maybe<CustomAttributeDistribution>;
  employeesCustomAttribute3Distribution?: Maybe<CustomAttributeDistribution>;
  employeesIcedUpCount: Scalars['Int'];
  employeesInCrisisCount: Scalars['Int'];
  employeesVisibleCount: Scalars['Int'];
  establishmentType?: Maybe<SecondarySituation>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  isClient: Scalars['Boolean'];
  isPremiumSchool: Scalars['Boolean'];
  lastReportGeneratedAt?: Maybe<Scalars['ISO8601DateTime']>;
  name: Scalars['String'];
  publishedEmployeesCount: Scalars['Int'];
  recruitingWorkingAreas: Array<Scalars['String']>;
};

/** A company admin profile */
export type CompanyAdminProfile = {
  __typename?: 'CompanyAdminProfile';
  company: Company;
  id: Scalars['ID'];
};

export enum CompanyAppointmentsQueryErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

/** A company employee */
export type CompanyEmployee = {
  __typename?: 'CompanyEmployee';
  ambivalent: Scalars['Boolean'];
  avatar?: Maybe<Scalars['AvatarUrl']>;
  canApprove: Scalars['Boolean'];
  canArchive: Scalars['Boolean'];
  canPublish: Scalars['Boolean'];
  canReceiveInvitationReminder: Scalars['Boolean'];
  canUnpublish: Scalars['Boolean'];
  companyAttribute1?: Maybe<Scalars['String']>;
  companyAttribute2?: Maybe<Scalars['String']>;
  companyAttribute3?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  lastInvitationEmailSentAt?: Maybe<Scalars['ISO8601DateTime']>;
  lastName: Scalars['String'];
  preparedDiplomaTitle?: Maybe<Scalars['String']>;
  status: EmployeeStatus;
  userId?: Maybe<Scalars['ID']>;
  userMetTag?: Maybe<UserMetTag>;
  visibleInSearch?: Maybe<Scalars['Boolean']>;
};


/** A company employee */
export type CompanyEmployeeAvatarArgs = {
  version?: Maybe<PictureVersion>;
};

/** A company sector */
export type CompanyEmployeeAppointment = {
  __typename?: 'CompanyEmployeeAppointment';
  from: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  member: AppointmentInterlocutor;
  professional?: Maybe<CompanyEmployee>;
  reviewFromProfessional: AppointmentReviewFromProfessional;
  reviewFromStudent: AppointmentReviewFromStudent;
};

export type CompanyEmployeeAppointmentsPayload = {
  __typename?: 'CompanyEmployeeAppointmentsPayload';
  appointments: Array<CompanyEmployeeAppointment>;
  totalCount: Scalars['Int'];
};

/** The connection type for CompanyEmployee. */
export type CompanyEmployeeConnection = {
  __typename?: 'CompanyEmployeeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CompanyEmployeeEdge>>;
  /** A list of nodes. */
  nodes: Array<CompanyEmployee>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type CompanyEmployeeEdge = {
  __typename?: 'CompanyEmployeeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CompanyEmployee>;
};

export enum CompanyEmployeesQueryErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

export type CompanyEmployeesQueryFiltersCustomAttributeInput = {
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export enum CompanyEmployeesQueryFiltersHrTableStatusEnum {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  AtRisk = 'AT_RISK',
  CompleteProfile = 'COMPLETE_PROFILE',
  Hibernated = 'HIBERNATED',
  Inactive = 'INACTIVE',
  IncompleteProfile = 'INCOMPLETE_PROFILE',
  Invited = 'INVITED',
  InvitedMultipleTimes = 'INVITED_MULTIPLE_TIMES',
  Unpublished = 'UNPUBLISHED'
}

export enum CompanyEmployeesQuerySortEnum {
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC'
}

/** A company employee in the context of a company report */
export type CompanyReport = {
  __typename?: 'CompanyReport';
  appointmentCount: Scalars['Int'];
  bestProfiles: Array<CompanyReportTopEmployee>;
  company: Company;
  companyId: Scalars['ID'];
  contactedEmployeesCount: Scalars['Int'];
  employeesViewCount: Scalars['Int'];
  from: Scalars['ISO8601DateTime'];
  isPremiumSchool: Scalars['Boolean'];
  lastUpdatedAt: Scalars['ISO8601DateTime'];
  likeCount: Scalars['Int'];
  newConversationCount: Scalars['Int'];
  publishedEmployeeCount: Scalars['Int'];
  publishedEmployeesWithAppointmentRate: Scalars['Percentage'];
  responseRate: Scalars['Percentage'];
  studentsSeeingThemselvesInTheSectorRate: Scalars['Percentage'];
  to: Scalars['ISO8601DateTime'];
  unfollowedConversationsCount: Scalars['Int'];
  uniqueProfileViewsFromStudents: Scalars['Int'];
  wishCount: Scalars['Int'];
};


/** A company employee in the context of a company report */
export type CompanyReportBestProfilesArgs = {
  limit?: Maybe<Scalars['Int']>;
};

/** All the company sizes */
export enum CompanyReportRange {
  /** from the beginning until now */
  FromTheBeginning = 'FROM_THE_BEGINNING',
  /** from the beginning of last month until the end of last month */
  LastMonth = 'LAST_MONTH',
  /** from the beginning of the month until now */
  ThisMonth = 'THIS_MONTH'
}

/** A company employee in the context of a company report */
export type CompanyReportTopEmployee = {
  __typename?: 'CompanyReportTopEmployee';
  appointmentCount: Scalars['Int'];
  avatar?: Maybe<Scalars['AvatarUrl']>;
  companyId: Scalars['ID'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  medal: Scalars['Int'];
};


/** A company employee in the context of a company report */
export type CompanyReportTopEmployeeAvatarArgs = {
  version?: Maybe<PictureVersion>;
};

/** A company sector */
export type CompanySector = {
  __typename?: 'CompanySector';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** All the company sizes */
export enum CompanySize {
  /** One employee */
  OnlyOne = 'ONLY_ONE',
  /** Over 10 000 employees */
  Over_10000 = 'OVER_10000',
  /** Between 1 and 10 employees */
  Under_10 = 'UNDER_10',
  /** Between 500 and 1 000 employees */
  Under_1000 = 'UNDER_1000',
  /** Between 5 000 and 10 000 employees */
  Under_10000 = 'UNDER_10000',
  /** Between 50 and 200 employees */
  Under_200 = 'UNDER_200',
  /** Between 10 and 50 employees */
  Under_50 = 'UNDER_50',
  /** Between 200 and 500 employees */
  Under_500 = 'UNDER_500',
  /** Between 1 000 and 5 000 employees */
  Under_5000 = 'UNDER_5000',
  /** Unknown number of employees */
  Unknown = 'UNKNOWN'
}

export type CompenyEmployeesQueryFilter = {
  archivedEq?: Maybe<Scalars['Boolean']>;
  companyAttributeIn?: Maybe<Array<CompanyEmployeesQueryFiltersCustomAttributeInput>>;
  hrTableStatusIn?: Maybe<Array<CompanyEmployeesQueryFiltersHrTableStatusEnum>>;
  q?: Maybe<Scalars['String']>;
};

export enum CompletionContextEnum {
  ContactAmbassador = 'CONTACT_AMBASSADOR',
  Edition = 'EDITION',
  Publication = 'PUBLICATION',
  RequestPublication = 'REQUEST_PUBLICATION',
  StepByStep = 'STEP_BY_STEP'
}

export enum ConfirmEmailErrorEnum {
  /** Email is already confirmed */
  AlreadyConfirmed = 'ALREADY_CONFIRMED',
  /** __Signin__: Needs email confirmation */
  EmailUnconfirmed = 'EMAIL_UNCONFIRMED',
  /** User not found */
  NotFound = 'NOT_FOUND',
  /** __Signin__: Needs tutor email confirmation */
  TutorEmailUnconfirmed = 'TUTOR_EMAIL_UNCONFIRMED',
  /** __Signin__: User account is restricted */
  UserAccountRestricted = 'USER_ACCOUNT_RESTRICTED',
  /** __Signin__: User is soft deleted */
  UserSoftDeleted = 'USER_SOFT_DELETED'
}

export enum ConfirmTutorEmailErrorEnum {
  /** Tutor email is already confirmed */
  AlreadyConfirmed = 'ALREADY_CONFIRMED',
  /** User not found */
  NotFound = 'NOT_FOUND'
}

export type ContextualCompletion = {
  __typename?: 'ContextualCompletion';
  complete: Scalars['Boolean'];
  fieldsCompletion: Array<FieldCompletionStatus>;
  fieldsTotalPoints: Scalars['Int'];
  percentage: Scalars['Int'];
  userTotalPoints: Scalars['Int'];
};

export type Conversation = {
  __typename?: 'Conversation';
  actionRequired: Scalars['Boolean'];
  appointmentClaims: Array<AppointmentClaim>;
  conversationType: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  currentUserAcknowledged: Scalars['Boolean'];
  currentUserAnswered: Scalars['Boolean'];
  currentUserCanBookAppointment: Scalars['Boolean'];
  currentUserCanBookAppointmentErrors: Array<AppointmentBookingErrorEnum>;
  currentUserCanClaimAppointment: Scalars['Boolean'];
  currentUserIsInitiator: Scalars['Boolean'];
  currentUserUnreadMessagesCount: Scalars['Int'];
  disabled: Scalars['Boolean'];
  groupSession?: Maybe<GroupSession>;
  id: Scalars['ID'];
  initiator: AmbivalentUser;
  interlocutor?: Maybe<AmbivalentUser>;
  interlocutorV2?: Maybe<ConversationInterlocutor>;
  lastMessage?: Maybe<Message>;
  messages: Array<Message>;
  professional?: Maybe<PublicProfessionalProfile>;
  recipient: AmbivalentUser;
  student?: Maybe<PublicStudentProfile>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Conversation. */
export type ConversationConnection = {
  __typename?: 'ConversationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ConversationEdge>>;
  /** A list of nodes. */
  nodes: Array<Conversation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type ConversationEdge = {
  __typename?: 'ConversationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Conversation>;
};

export type ConversationInterlocutor = InterlocutorInterface & UserProfileTypeInterface & {
  __typename?: 'ConversationInterlocutor';
  acquiredDiplomaAcquisitionYear?: Maybe<Scalars['Int']>;
  acquiredDiplomaBeginYear?: Maybe<Scalars['Int']>;
  acquiredDiplomaLevel?: Maybe<Diploma>;
  acquiredDiplomaSchoolName?: Maybe<Scalars['String']>;
  acquiredDiplomaTitle?: Maybe<Scalars['String']>;
  ambassadorPresentation?: Maybe<Scalars['String']>;
  anonymizedOrDeleted?: Maybe<Scalars['Boolean']>;
  aspirationPresentation?: Maybe<Scalars['String']>;
  avatar: Avatar;
  avatars: Avatar;
  banner?: Maybe<OrganizationCardBanner>;
  companyDescription?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<OrganizationLogo>;
  companyName?: Maybe<Scalars['String']>;
  currentGrade?: Maybe<Grade>;
  currentGradeOrDiploma?: Maybe<Scalars['String']>;
  currentSchoolCity?: Maybe<Scalars['String']>;
  currentSchoolDepartment?: Maybe<Scalars['String']>;
  currentSchoolLogo?: Maybe<OrganizationLogo>;
  currentSchoolName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  expertisePresentation?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender: GenderEnum;
  id: Scalars['ID'];
  interestsPresentation?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  meetingPlace?: Maybe<Scalars['String']>;
  memberPresentation?: Maybe<Scalars['String']>;
  minor: Scalars['Boolean'];
  normalizedPhone?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  preparedDiplomaTitle?: Maybe<Diploma>;
  /** @deprecated Use company_logo instead */
  previousCompanyLogo?: Maybe<OrganizationLogo>;
  /** @deprecated Use company_name instead */
  previousCompanyName?: Maybe<Scalars['String']>;
  /** @deprecated Use position instead */
  previousPosition?: Maybe<Scalars['String']>;
  /** @deprecated Use years_of_experience instead */
  previousPositionYearsOfExperiences?: Maybe<Scalars['Int']>;
  primarySituation?: Maybe<PrimarySituation>;
  profileType: UserProfile;
  receivedReviewAsMembers: Array<PastReview>;
  schoolName?: Maybe<Scalars['String']>;
  secondarySituation?: Maybe<SecondarySituation>;
  softDeleted: Scalars['Boolean'];
  superMinor: Scalars['Boolean'];
  visibilityStatus: VisibilityStatusEnum;
  yearsOfExperience?: Maybe<Scalars['Int']>;
};

/** List of filters for conversations query */
export type ConversationsFilter = {
  createdAtGt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAtLt?: Maybe<Scalars['ISO8601DateTime']>;
  currentUserAcknowledged?: Maybe<Scalars['Boolean']>;
  currentUserActionNeeded?: Maybe<Scalars['Boolean']>;
  currentUserIsInitiator?: Maybe<Scalars['Boolean']>;
  currentUserNotAnswered?: Maybe<Scalars['Boolean']>;
  excludeAnswered?: Maybe<Scalars['Boolean']>;
  excludeInitiated?: Maybe<Scalars['Boolean']>;
  excludeReceived?: Maybe<Scalars['Boolean']>;
  excludeSeen?: Maybe<Scalars['Boolean']>;
  idEq?: Maybe<Scalars['ID']>;
  idIn?: Maybe<Array<Scalars['ID']>>;
  idNin?: Maybe<Array<Scalars['ID']>>;
  initiatorIdEq?: Maybe<Scalars['ID']>;
  initiatorIdIn?: Maybe<Array<Scalars['ID']>>;
  updatedAtGt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAtLt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ConversationType = {
  __typename?: 'ConversationType';
  key: ConversationTypeKeyEnum;
  name: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};


export type ConversationTypeNameArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

export enum ConversationTypeKeyEnum {
  ClassGroup = 'CLASS_GROUP',
  Individual = 'INDIVIDUAL',
  ProgramGroup = 'PROGRAM_GROUP'
}

export enum CreateConversationMutationErrorEnum {
  AmbassadorMeetingPreferencesPreventsContact = 'AMBASSADOR_MEETING_PREFERENCES_PREVENTS_CONTACT',
  AmbassadorUnavailable = 'AMBASSADOR_UNAVAILABLE',
  ContactUnauthorized = 'CONTACT_UNAUTHORIZED',
  ConversationAlreadyExists = 'CONVERSATION_ALREADY_EXISTS',
  ConversationStartFailed = 'CONVERSATION_START_FAILED',
  GroupSessionCreationFailed = 'GROUP_SESSION_CREATION_FAILED',
  MemberProfileNotComplete = 'MEMBER_PROFILE_NOT_COMPLETE',
  MessageCannotBeBlank = 'MESSAGE_CANNOT_BE_BLANK',
  NotFound = 'NOT_FOUND',
  NoRemainingContact = 'NO_REMAINING_CONTACT',
  ParticipantsCountCannotBeBlank = 'PARTICIPANTS_COUNT_CANNOT_BE_BLANK',
  Unauthorized = 'UNAUTHORIZED',
  UsersMustBeDifferent = 'USERS_MUST_BE_DIFFERENT',
  UserInteractionGroupPreventsContact = 'USER_INTERACTION_GROUP_PREVENTS_CONTACT'
}

export enum CreateGouvSchoolMutationErrorEnum {
  NotFound = 'NOT_FOUND'
}

export enum CreateHibernationMutationErrorEnum {
  /** Hibernation exceed the 62 days limit */
  HibernationTooBroad = 'HIBERNATION_TOO_BROAD',
  /** Ambassador account is restricted and cannot hibernate (iced_up) */
  IcedUpAmbassadorCannotHibernate = 'ICED_UP_AMBASSADOR_CANNOT_HIBERNATE',
  /** Only ambassador can hibernate */
  OnlyAmbassadorCanHibernate = 'ONLY_AMBASSADOR_CAN_HIBERNATE',
  /** Two hibernations overlaps */
  OverlappingHibernations = 'OVERLAPPING_HIBERNATIONS',
  /** Ambassador account is restricted and cannot hibernate (restricted) */
  RestrictedAmbassadorCannotHibernate = 'RESTRICTED_AMBASSADOR_CANNOT_HIBERNATE',
  /** Start at must be before start at */
  StartAtMustBeBeforeEndAt = 'START_AT_MUST_BE_BEFORE_END_AT',
  /** Start at must be in the future */
  StartAtMustBeFuture = 'START_AT_MUST_BE_FUTURE',
  /** Unauthenticated */
  Unauthenticated = 'UNAUTHENTICATED',
  /** Ambassador account is unpublished and cannot hibernate */
  UnpublishedAmbassadorCannotHibernate = 'UNPUBLISHED_AMBASSADOR_CANNOT_HIBERNATE'
}

export enum CreateMessageTemplateMutationErrorEnum {
  MessageTemplateInvalid = 'MESSAGE_TEMPLATE_INVALID',
  NotFound = 'NOT_FOUND',
  TitleBlank = 'TITLE_BLANK',
  TitleTooShort = 'TITLE_TOO_SHORT',
  Unauthorized = 'UNAUTHORIZED'
}

export type CustomAttributeDistribution = {
  __typename?: 'CustomAttributeDistribution';
  distribution: Array<CustomAttributeDistributionItem>;
  name: Scalars['String'];
  total: Scalars['Int'];
};

export type CustomAttributeDistributionItem = {
  __typename?: 'CustomAttributeDistributionItem';
  count: Scalars['Int'];
  key: Scalars['String'];
  percentage: Scalars['Float'];
};

/** A set that contains all the custom employee attributes of a company */
export type CustomAttributesSet = {
  __typename?: 'CustomAttributesSet';
  companyAttributeValues1: Array<Scalars['String']>;
  companyAttributeValues2: Array<Scalars['String']>;
  companyAttributeValues3: Array<Scalars['String']>;
  customEmployeeAttribute1?: Maybe<Scalars['String']>;
  customEmployeeAttribute1NormalizedName?: Maybe<Scalars['String']>;
  customEmployeeAttribute2?: Maybe<Scalars['String']>;
  customEmployeeAttribute2NormalizedName?: Maybe<Scalars['String']>;
  customEmployeeAttribute3?: Maybe<Scalars['String']>;
  customEmployeeAttribute3NormalizedName?: Maybe<Scalars['String']>;
};

export type DefaultMessageTemplate = {
  __typename?: 'DefaultMessageTemplate';
  body: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};


export type DefaultMessageTemplateBodyArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type DefaultMessageTemplateTitleArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

export enum DefaultMessageTemplatesQueryErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

export enum DenyAppointmentClaimMutationErrorEnum {
  AlreadyResolved = 'ALREADY_RESOLVED',
  DescriptionRequired = 'DESCRIPTION_REQUIRED',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

export enum DestroyHibernationMutationErrorEnum {
  /** Hibernation 'effectiveWakeAt' is already defined and past */
  CannotDestroyWakedHibernation = 'CANNOT_DESTROY_WAKED_HIBERNATION',
  /** Hibernation is not found or belongs to another user */
  NotFound = 'NOT_FOUND',
  /** Unauthenticated */
  Unauthenticated = 'UNAUTHENTICATED'
}

export enum DestroyMessageTemplateMutationErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

export type Diploma = {
  __typename?: 'Diploma';
  key: DiplomaKeyEnum;
  name: Scalars['String'];
};


export type DiplomaNameArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

export enum DiplomaEnum {
  Bac_2 = 'BAC_2',
  Bac_3 = 'BAC_3',
  Bac_4 = 'BAC_4',
  Bac_5 = 'BAC_5',
  BacGt = 'BAC_GT',
  BacPro = 'BAC_PRO',
  Bep = 'BEP',
  Brevet = 'BREVET',
  Cap = 'CAP',
  Cep = 'CEP',
  Doctorat = 'DOCTORAT',
  None = 'NONE'
}

export enum DiplomaKeyEnum {
  Bac_2 = 'BAC_2',
  Bac_3 = 'BAC_3',
  Bac_4 = 'BAC_4',
  Bac_5 = 'BAC_5',
  BacGt = 'BAC_GT',
  BacPro = 'BAC_PRO',
  Bep = 'BEP',
  Brevet = 'BREVET',
  Cap = 'CAP',
  Cep = 'CEP',
  Doctorat = 'DOCTORAT',
  None = 'NONE'
}

/** All the diploma levels allowed in a FDR configuration */
export enum DiplomaLevel {
  /** L1 */
  L1 = 'L1',
  /** L2 */
  L2 = 'L2',
  /** L3 */
  L3 = 'L3',
  /** M1 */
  M1 = 'M1',
  /** M2 */
  M2 = 'M2',
  /** Other */
  Other = 'OTHER',
  /** PhD */
  Phd = 'PHD'
}

/** An education */
export type Education = {
  __typename?: 'Education';
  diplomaTitle?: Maybe<Scalars['String']>;
  diplomaType?: Maybe<DiplomaEnum>;
  endsAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  schoolName: Scalars['String'];
  startsAt?: Maybe<Scalars['ISO8601DateTime']>;
};


/** The status of the employee profile from the perspective of the HR admin */
export enum EmployeeStatus {
  /** The employee is active on the platform */
  Active = 'ACTIVE',
  /** The employee profile is anonymized */
  Anonymized = 'ANONYMIZED',
  /** The employee's profile is complete and approved but is not yet published */
  Approved = 'APPROVED',
  /** The employee profile is archived */
  Archived = 'ARCHIVED',
  /** The employee is at risk of becoming inactive */
  AtRisk = 'AT_RISK',
  /** The employee has never been published but has a complete profile */
  CompleteProfile = 'COMPLETE_PROFILE',
  /** The employee is in hibernation mode */
  Hibernated = 'HIBERNATED',
  /** The employee is inactive on the platform */
  Inactive = 'INACTIVE',
  /** The employee has never been published and has an incomplete profile */
  IncompleteProfile = 'INCOMPLETE_PROFILE',
  /** The employee has been invited to the platform but has not yet signed up */
  Invited = 'INVITED',
  /** translation missing: en.graphql.schema.enums.employee_status.invited_multiple_times */
  InvitedMultipleTimes = 'INVITED_MULTIPLE_TIMES',
  /** The employee has been unpublished from the platform */
  Unpublished = 'UNPUBLISHED'
}

export enum ExtractInfosFromInvitationMutationErrorEnum {
  AlreadyClaimed = 'ALREADY_CLAIMED',
  Invalid = 'INVALID'
}

export enum FacetKeyEnum {
  Companies = 'COMPANIES',
  CompanySizes = 'COMPANY_SIZES',
  CurrentGrade = 'CURRENT_GRADE',
  CurrentSchoolName = 'CURRENT_SCHOOL_NAME',
  JobCategory = 'JOB_CATEGORY',
  JobTitle = 'JOB_TITLE',
  Languages = 'LANGUAGES',
  LevelOfExperience = 'LEVEL_OF_EXPERIENCE',
  Location = 'LOCATION',
  PreparedDiplomaTitle = 'PREPARED_DIPLOMA_TITLE',
  ProfessionalTypes = 'PROFESSIONAL_TYPES',
  RecommendedQualifications = 'RECOMMENDED_QUALIFICATIONS',
  SchoolNames = 'SCHOOL_NAMES',
  SecondarySituations = 'SECONDARY_SITUATIONS',
  Sectors = 'SECTORS',
  TagNames = 'TAG_NAMES'
}

/** A FDR */
export type Fdr = {
  __typename?: 'FDR';
  id: Scalars['ID'];
  operationName: Scalars['String'];
};

/** a fdr configuration success */
export type FdrConfigurationSuccess = {
  __typename?: 'FDRConfigurationSuccess';
  fdrCoachLink: Scalars['String'];
};

export enum FieldCompletionName {
  AcquiredDiploma = 'ACQUIRED_DIPLOMA',
  AmbassadorPresentation = 'AMBASSADOR_PRESENTATION',
  AspirationPresentation = 'ASPIRATION_PRESENTATION',
  Avatar = 'AVATAR',
  Birthdate = 'BIRTHDATE',
  CompanyLogo = 'COMPANY_LOGO',
  CompanyName = 'COMPANY_NAME',
  CompanySector = 'COMPANY_SECTOR',
  CompanySize = 'COMPANY_SIZE',
  CurrentScholarityInfos = 'CURRENT_SCHOLARITY_INFOS',
  CurrentSchoolDepartment = 'CURRENT_SCHOOL_DEPARTMENT',
  CurrentSchoolName = 'CURRENT_SCHOOL_NAME',
  CurrentSchoolType = 'CURRENT_SCHOOL_TYPE',
  EditionMemberPresentation = 'EDITION_MEMBER_PRESENTATION',
  ExpertisePresentation = 'EXPERTISE_PRESENTATION',
  FirstName = 'FIRST_NAME',
  Gender = 'GENDER',
  InterestsPresentation = 'INTERESTS_PRESENTATION',
  JobSeekerSupportOrganizationType = 'JOB_SEEKER_SUPPORT_ORGANIZATION_TYPE',
  JobSeekerSupportPrograms = 'JOB_SEEKER_SUPPORT_PROGRAMS',
  JobSeekerSupportProgramsAndPositionPanel = 'JOB_SEEKER_SUPPORT_PROGRAMS_AND_POSITION_PANEL',
  JobTitle = 'JOB_TITLE',
  JoinProgram = 'JOIN_PROGRAM',
  Languages = 'LANGUAGES',
  LastName = 'LAST_NAME',
  LookingForOpportunity = 'LOOKING_FOR_OPPORTUNITY',
  MaxMeetingPerMonth = 'MAX_MEETING_PER_MONTH',
  MeetingPlace = 'MEETING_PLACE',
  MeetingPreferences = 'MEETING_PREFERENCES',
  MemberPresentation = 'MEMBER_PRESENTATION',
  MemberPresentationStudent = 'MEMBER_PRESENTATION_STUDENT',
  MustWantToShare = 'MUST_WANT_TO_SHARE',
  NeverWorked = 'NEVER_WORKED',
  Orientation = 'ORIENTATION',
  Password = 'PASSWORD',
  Phone = 'PHONE',
  PreparedDiplomaTitle = 'PREPARED_DIPLOMA_TITLE',
  PreviousCompanyName = 'PREVIOUS_COMPANY_NAME',
  PreviousJobTitle = 'PREVIOUS_JOB_TITLE',
  PreviousPositionYearsOfExperiences = 'PREVIOUS_POSITION_YEARS_OF_EXPERIENCES',
  PrimarySituation = 'PRIMARY_SITUATION',
  ReturningToStudy = 'RETURNING_TO_STUDY',
  SecondarySituation = 'SECONDARY_SITUATION',
  ShowCongratulationsPanel = 'SHOW_CONGRATULATIONS_PANEL',
  ShowCongratulationsPanelUpdate = 'SHOW_CONGRATULATIONS_PANEL_UPDATE',
  StudentSupportCurrentSchoolAndPositionPanel = 'STUDENT_SUPPORT_CURRENT_SCHOOL_AND_POSITION_PANEL',
  SupportMemberPresentation = 'SUPPORT_MEMBER_PRESENTATION',
  WantToShare = 'WANT_TO_SHARE',
  WantToShareStudent = 'WANT_TO_SHARE_STUDENT',
  Welcome = 'WELCOME',
  WelcomeUpdate = 'WELCOME_UPDATE',
  YearsOfExperience = 'YEARS_OF_EXPERIENCE'
}

export type FieldCompletionStatus = {
  __typename?: 'FieldCompletionStatus';
  complete: Scalars['Boolean'];
  disabled: Scalars['Boolean'];
  name: FieldCompletionName;
  points: Scalars['Int'];
};

export type FranceLevel1AdministrativeArea = {
  __typename?: 'FranceLevel1AdministrativeArea';
  key: FranceLevel1AdministrativeAreasEnum;
  name: Scalars['String'];
};


export type FranceLevel1AdministrativeAreaNameArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

export enum FranceLevel1AdministrativeAreasEnum {
  AuvergneRhoneAlpes = 'AUVERGNE_RHONE_ALPES',
  BourgogneFrancheComte = 'BOURGOGNE_FRANCHE_COMTE',
  Bretagne = 'BRETAGNE',
  CentreValDeLoire = 'CENTRE_VAL_DE_LOIRE',
  Corse = 'CORSE',
  GrandEst = 'GRAND_EST',
  HautsDeFrance = 'HAUTS_DE_FRANCE',
  IleDeFrance = 'ILE_DE_FRANCE',
  Normandie = 'NORMANDIE',
  NouvelleAquitaine = 'NOUVELLE_AQUITAINE',
  Occitanie = 'OCCITANIE',
  PaysDeLaLoire = 'PAYS_DE_LA_LOIRE',
  ProvenceAlpesCoteDAzur = 'PROVENCE_ALPES_COTE_D_AZUR'
}

export type GalleryImage = {
  __typename?: 'GalleryImage';
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  user: AmbivalentUser;
};

export type GamificationScoreEntry = {
  __typename?: 'GamificationScoreEntry';
  formula: Array<Scalars['Int']>;
  name: GamificationScoreNameEnum;
  total: Scalars['Int'];
};

export enum GamificationScoreNameEnum {
  Appointments = 'APPOINTMENTS',
  Bonus = 'BONUS',
  Replies = 'REPLIES',
  Views = 'VIEWS'
}

export enum GenderEnum {
  Female = 'FEMALE',
  Male = 'MALE',
  Unspecified = 'UNSPECIFIED'
}

/** Goverment School */
export type GouvSchool = {
  __typename?: 'GouvSchool';
  academyLabel?: Maybe<Scalars['String']>;
  agriculturalSchool?: Maybe<Scalars['Boolean']>;
  communeName?: Maybe<Scalars['String']>;
  departmentLabel?: Maybe<Scalars['String']>;
  establishmentCategory?: Maybe<Array<Scalars['String']>>;
  establishmentType?: Maybe<Scalars['String']>;
  generalPath?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lastApiUpdate?: Maybe<Scalars['ISO8601DateTime']>;
  militarySchool?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  priorityEducation?: Maybe<Scalars['Boolean']>;
  professionalPath?: Maybe<Scalars['Boolean']>;
  publicPrivateStatus?: Maybe<Scalars['String']>;
  regionLabel?: Maybe<Scalars['String']>;
  sigle?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  technologicalPath?: Maybe<Scalars['Boolean']>;
  uai?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export enum GouvSchoolErrorEnum {
  DepartmentLabelCannotBeEmpty = 'DEPARTMENT_LABEL_CANNOT_BE_EMPTY'
}

/** Filters for GouvSchool search query */
export type GouvSchoolSearchFilter = {
  /** Filter by city */
  communeName?: Maybe<Scalars['String']>;
  /** Filter by department */
  departmentLabel?: Maybe<Scalars['String']>;
  /** Filter by establishment type */
  establishmentType?: Maybe<SecondarySituationEnum>;
};

/** Search result for schools */
export type GouvSchoolSearchResults = {
  __typename?: 'GouvSchoolSearchResults';
  academyLabel?: Maybe<Scalars['String']>;
  agriculturalSchool?: Maybe<Scalars['Boolean']>;
  communeName?: Maybe<Scalars['String']>;
  departmentLabel?: Maybe<Scalars['String']>;
  establishmentType?: Maybe<Scalars['String']>;
  generalPath?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  militarySchool?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  priorityEducation?: Maybe<Scalars['Boolean']>;
  professionalPath?: Maybe<Scalars['Boolean']>;
  publicPrivateStatus?: Maybe<Scalars['String']>;
  regionLabel?: Maybe<Scalars['String']>;
  sigle?: Maybe<Scalars['String']>;
  technologicalPath?: Maybe<Scalars['Boolean']>;
  uai?: Maybe<Scalars['String']>;
};

export type Grade = {
  __typename?: 'Grade';
  key: GradeKeyEnum;
  name: Scalars['String'];
};


export type GradeNameArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

export enum GradeEnum {
  Autre = 'AUTRE',
  Bts1 = 'BTS1',
  Bts2 = 'BTS2',
  But1 = 'BUT1',
  But2 = 'BUT2',
  But3 = 'BUT3',
  Cap1 = 'CAP1',
  Cap2 = 'CAP2',
  Cfa1 = 'CFA1',
  Cfa2 = 'CFA2',
  Cfat = 'CFAT',
  College3 = 'COLLEGE3',
  College4 = 'COLLEGE4',
  College5 = 'COLLEGE5',
  College6 = 'COLLEGE6',
  Cpge1 = 'CPGE1',
  Cpge2 = 'CPGE2',
  Dcg1 = 'DCG1',
  Dcg2 = 'DCG2',
  Dcg3 = 'DCG3',
  DiplomeUniversitaire = 'DIPLOME_UNIVERSITAIRE',
  Doctorat = 'DOCTORAT',
  Ec1 = 'EC1',
  Ec2 = 'EC2',
  Ec3 = 'EC3',
  Ec4 = 'EC4',
  Ec5 = 'EC5',
  Ec6 = 'EC6',
  Ei1 = 'EI1',
  Ei2 = 'EI2',
  Ei3 = 'EI3',
  Ei4 = 'EI4',
  Ei5 = 'EI5',
  Ei6 = 'EI6',
  Licence1 = 'LICENCE1',
  Licence2 = 'LICENCE2',
  Licence3 = 'LICENCE3',
  Lycee1 = 'LYCEE1',
  Lycee2 = 'LYCEE2',
  LyceeT = 'LYCEE_T',
  Master1 = 'MASTER1',
  Master2 = 'MASTER2',
  Medecine1 = 'MEDECINE1',
  Medecine2 = 'MEDECINE2',
  Medecine3 = 'MEDECINE3'
}

export enum GradeKeyEnum {
  Autre = 'AUTRE',
  Bts1 = 'BTS1',
  Bts2 = 'BTS2',
  But1 = 'BUT1',
  But2 = 'BUT2',
  But3 = 'BUT3',
  Cap1 = 'CAP1',
  Cap2 = 'CAP2',
  Cfa1 = 'CFA1',
  Cfa2 = 'CFA2',
  Cfat = 'CFAT',
  College3 = 'COLLEGE3',
  College4 = 'COLLEGE4',
  College5 = 'COLLEGE5',
  College6 = 'COLLEGE6',
  Cpge1 = 'CPGE1',
  Cpge2 = 'CPGE2',
  Dcg1 = 'DCG1',
  Dcg2 = 'DCG2',
  Dcg3 = 'DCG3',
  DiplomeUniversitaire = 'DIPLOME_UNIVERSITAIRE',
  Doctorat = 'DOCTORAT',
  Ec1 = 'EC1',
  Ec2 = 'EC2',
  Ec3 = 'EC3',
  Ec4 = 'EC4',
  Ec5 = 'EC5',
  Ec6 = 'EC6',
  Ei1 = 'EI1',
  Ei2 = 'EI2',
  Ei3 = 'EI3',
  Ei4 = 'EI4',
  Ei5 = 'EI5',
  Ei6 = 'EI6',
  Licence1 = 'LICENCE1',
  Licence2 = 'LICENCE2',
  Licence3 = 'LICENCE3',
  Lycee1 = 'LYCEE1',
  Lycee2 = 'LYCEE2',
  LyceeT = 'LYCEE_T',
  Master1 = 'MASTER1',
  Master2 = 'MASTER2',
  Medecine1 = 'MEDECINE1',
  Medecine2 = 'MEDECINE2',
  Medecine3 = 'MEDECINE3'
}

/** Group session, attached to a conversation which reflects: Rencontres collectives */
export type GroupSession = {
  __typename?: 'GroupSession';
  classes?: Maybe<Array<GradeEnum>>;
  conversations?: Maybe<Array<Conversation>>;
  createdAt: Scalars['ISO8601DateTime'];
  femaleCount: Scalars['Int'];
  id: Scalars['ID'];
  maleCount: Scalars['Int'];
  participantCount: Scalars['Int'];
  programs?: Maybe<Array<Scalars['String']>>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type GroupSessionInput = {
  classes?: Maybe<Array<Scalars['String']>>;
  femaleCount: Scalars['Int'];
  maleCount: Scalars['Int'];
  programs?: Maybe<Array<Scalars['String']>>;
};

export type HermesContact = {
  __typename?: 'HermesContact';
  anonymized?: Maybe<Scalars['Boolean']>;
  appointmentContactMethod?: Maybe<Scalars['String']>;
  appointmentDate?: Maybe<Scalars['ISO8601DateTime']>;
  avatarUrl?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companySector?: Maybe<Scalars['String']>;
  conversationId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  professionalFirstName?: Maybe<Scalars['String']>;
  professionalUrl?: Maybe<Scalars['String']>;
  state?: Maybe<HermesContactStateEnum>;
};

export enum HermesContactStateEnum {
  InvalidatedAppointment = 'INVALIDATED_APPOINTMENT',
  NoScheduledAppointment = 'NO_SCHEDULED_APPOINTMENT',
  PastAndConfirmed = 'PAST_AND_CONFIRMED',
  PastAwaitingConfirmation = 'PAST_AWAITING_CONFIRMATION',
  ProfileReportedByTheProfessional = 'PROFILE_REPORTED_BY_THE_PROFESSIONAL',
  UnfollowedConversationWithThisProfessional = 'UNFOLLOWED_CONVERSATION_WITH_THIS_PROFESSIONAL',
  Upcoming = 'UPCOMING'
}

/**
 * An ambassador request to stop being contacted or visible in search between two date
 * 
 * Also see:
 * - [createHibernation](#mutation-createHibernation)
 * - [destroyHibernation](#mutation-destroyHibernation)
 * - [AmbivalentUser](#definition-AmbivalentUser)
 */
export type Hibernation = {
  __typename?: 'Hibernation';
  effectiveWakeAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  requestedWakeAt: Scalars['ISO8601Date'];
  scheduledStartAt: Scalars['ISO8601Date'];
};

export type Image = {
  __typename?: 'Image';
  url?: Maybe<Scalars['String']>;
};

/** Interaction group keys */
export enum InteractionGroupKeyEnum {
  GraduateStudent = 'GRADUATE_STUDENT',
  HighSchoolStudent = 'HIGH_SCHOOL_STUDENT',
  InActivity = 'IN_ACTIVITY',
  MiddleSchoolStudent = 'MIDDLE_SCHOOL_STUDENT'
}

export enum InteractionServiceErrorEnum {
  AmbassadorMeetingPreferencesPreventsContact = 'AMBASSADOR_MEETING_PREFERENCES_PREVENTS_CONTACT',
  AmbassadorUnavailable = 'AMBASSADOR_UNAVAILABLE',
  MemberProfileNotComplete = 'MEMBER_PROFILE_NOT_COMPLETE',
  NoRemainingContact = 'NO_REMAINING_CONTACT',
  UserInteractionGroupPreventsContact = 'USER_INTERACTION_GROUP_PREVENTS_CONTACT'
}

export type InterlocutorInterface = {
  acquiredDiplomaAcquisitionYear?: Maybe<Scalars['Int']>;
  acquiredDiplomaBeginYear?: Maybe<Scalars['Int']>;
  acquiredDiplomaLevel?: Maybe<Diploma>;
  acquiredDiplomaSchoolName?: Maybe<Scalars['String']>;
  acquiredDiplomaTitle?: Maybe<Scalars['String']>;
  ambassadorPresentation?: Maybe<Scalars['String']>;
  anonymizedOrDeleted?: Maybe<Scalars['Boolean']>;
  aspirationPresentation?: Maybe<Scalars['String']>;
  avatar: Avatar;
  avatars: Avatar;
  banner?: Maybe<OrganizationCardBanner>;
  companyDescription?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<OrganizationLogo>;
  companyName?: Maybe<Scalars['String']>;
  currentGrade?: Maybe<Grade>;
  currentGradeOrDiploma?: Maybe<Scalars['String']>;
  currentSchoolCity?: Maybe<Scalars['String']>;
  currentSchoolDepartment?: Maybe<Scalars['String']>;
  currentSchoolLogo?: Maybe<OrganizationLogo>;
  currentSchoolName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  expertisePresentation?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender: GenderEnum;
  id: Scalars['ID'];
  interestsPresentation?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  meetingPlace?: Maybe<Scalars['String']>;
  memberPresentation?: Maybe<Scalars['String']>;
  minor: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  preparedDiplomaTitle?: Maybe<Diploma>;
  /** @deprecated Use company_logo instead */
  previousCompanyLogo?: Maybe<OrganizationLogo>;
  /** @deprecated Use company_name instead */
  previousCompanyName?: Maybe<Scalars['String']>;
  /** @deprecated Use position instead */
  previousPosition?: Maybe<Scalars['String']>;
  /** @deprecated Use years_of_experience instead */
  previousPositionYearsOfExperiences?: Maybe<Scalars['Int']>;
  primarySituation?: Maybe<PrimarySituation>;
  schoolName?: Maybe<Scalars['String']>;
  secondarySituation?: Maybe<SecondarySituation>;
  softDeleted: Scalars['Boolean'];
  superMinor: Scalars['Boolean'];
  visibilityStatus: VisibilityStatusEnum;
  yearsOfExperience?: Maybe<Scalars['Int']>;
};

export enum InternshipDuration {
  InternshipDuration_10Months = 'INTERNSHIP_DURATION_10_MONTHS',
  InternshipDuration_11Months = 'INTERNSHIP_DURATION_11_MONTHS',
  InternshipDuration_12Months = 'INTERNSHIP_DURATION_12_MONTHS',
  InternshipDuration_13Months = 'INTERNSHIP_DURATION_13_MONTHS',
  InternshipDuration_14Months = 'INTERNSHIP_DURATION_14_MONTHS',
  InternshipDuration_15Months = 'INTERNSHIP_DURATION_15_MONTHS',
  InternshipDuration_16Months = 'INTERNSHIP_DURATION_16_MONTHS',
  InternshipDuration_17Months = 'INTERNSHIP_DURATION_17_MONTHS',
  InternshipDuration_18Months = 'INTERNSHIP_DURATION_18_MONTHS',
  InternshipDuration_19Months = 'INTERNSHIP_DURATION_19_MONTHS',
  InternshipDuration_1Months = 'INTERNSHIP_DURATION_1_MONTHS',
  InternshipDuration_20Months = 'INTERNSHIP_DURATION_20_MONTHS',
  InternshipDuration_21Months = 'INTERNSHIP_DURATION_21_MONTHS',
  InternshipDuration_22Months = 'INTERNSHIP_DURATION_22_MONTHS',
  InternshipDuration_23Months = 'INTERNSHIP_DURATION_23_MONTHS',
  InternshipDuration_24Months = 'INTERNSHIP_DURATION_24_MONTHS',
  InternshipDuration_2Months = 'INTERNSHIP_DURATION_2_MONTHS',
  InternshipDuration_3Months = 'INTERNSHIP_DURATION_3_MONTHS',
  InternshipDuration_4Months = 'INTERNSHIP_DURATION_4_MONTHS',
  InternshipDuration_5Months = 'INTERNSHIP_DURATION_5_MONTHS',
  InternshipDuration_6Months = 'INTERNSHIP_DURATION_6_MONTHS',
  InternshipDuration_7Months = 'INTERNSHIP_DURATION_7_MONTHS',
  InternshipDuration_8Months = 'INTERNSHIP_DURATION_8_MONTHS',
  InternshipDuration_9Months = 'INTERNSHIP_DURATION_9_MONTHS',
  InternshipDurationUndetermined = 'INTERNSHIP_DURATION_UNDETERMINED'
}

export enum InternshipStatus {
  HaveFound = 'HAVE_FOUND',
  LookingFor = 'LOOKING_FOR'
}

export type InternshipType = {
  __typename?: 'InternshipType';
  key: InternshipTypeEnum;
  name: Scalars['String'];
};


export type InternshipTypeNameArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

export enum InternshipTypeEnum {
  Apprenticeship = 'APPRENTICESHIP',
  Cdd = 'CDD',
  Cdi = 'CDI',
  Internship = 'INTERNSHIP',
  Via = 'VIA',
  Vie = 'VIE'
}

export type Invitation = {
  __typename?: 'Invitation';
  email: Scalars['String'];
  firstCustomAttribute: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  position: Scalars['String'];
  secondCustomAttribute: Scalars['String'];
  thirdCustomAttribute: Scalars['String'];
};

export type InvitationInfos = {
  __typename?: 'InvitationInfos';
  email: Scalars['Email'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};



export type JobSeekerSupportOrganizationType = {
  __typename?: 'JobSeekerSupportOrganizationType';
  key: JobSeekerSupportOrganizationTypeKeyEnum;
  name: Scalars['String'];
};


export type JobSeekerSupportOrganizationTypeNameArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

export enum JobSeekerSupportOrganizationTypeKeyEnum {
  Apec = 'APEC',
  CapEmploi = 'CAP_EMPLOI',
  FranceTravail = 'FRANCE_TRAVAIL',
  MissionLocale = 'MISSION_LOCALE',
  Other = 'OTHER',
  Pareo = 'PAREO'
}

export type JobSeekerSupportProgram = {
  __typename?: 'JobSeekerSupportProgram';
  key: JobSeekerSupportProgramKeyEnum;
  name: Scalars['String'];
};


export type JobSeekerSupportProgramNameArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

export enum JobSeekerSupportProgramKeyEnum {
  AccompagnementGlobal = 'ACCOMPAGNEMENT_GLOBAL',
  Aij = 'AIJ',
  Cej = 'CEJ',
  Csp = 'CSP',
  DynamicSeniors = 'DYNAMIC_SENIORS',
  EquipEmploi = 'EQUIP_EMPLOI',
  EquipRecrut = 'EQUIP_RECRUT',
  Mobilite = 'MOBILITE',
  Other = 'OTHER',
  PortefeuilleGuide = 'PORTEFEUILLE_GUIDE',
  PortefeuilleRenforce = 'PORTEFEUILLE_RENFORCE',
  PortefeuilleSuivi = 'PORTEFEUILLE_SUIVI'
}

export type JobTitle = {
  __typename?: 'JobTitle';
  key: JobTitleKeyEnum;
  name: Scalars['String'];
};


export type JobTitleNameArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

export enum JobTitleKeyEnum {
  EducationalAdviser = 'EDUCATIONAL_ADVISER',
  HeadOfSchool = 'HEAD_OF_SCHOOL',
  OrientationCounselor = 'ORIENTATION_COUNSELOR',
  Other = 'OTHER',
  Teacher = 'TEACHER'
}

export enum JoinProgramErrorEnum {
  /** Current user is already part of the program */
  AlreadyAffiliated = 'ALREADY_AFFILIATED',
  /** Program not found */
  NotFound = 'NOT_FOUND',
  /** The program cannot have more participants */
  ProgramReachedParticipationLimit = 'PROGRAM_REACHED_PARTICIPATION_LIMIT',
  /** Unauthorized */
  Unauthorized = 'UNAUTHORIZED'
}

export enum Language {
  Afrikaans = 'AFRIKAANS',
  Albanian = 'ALBANIAN',
  Arabic = 'ARABIC',
  Armenian = 'ARMENIAN',
  Azeri = 'AZERI',
  Basque = 'BASQUE',
  Belarusian = 'BELARUSIAN',
  Bengali = 'BENGALI',
  Bosnian = 'BOSNIAN',
  Bulgarian = 'BULGARIAN',
  Burmese = 'BURMESE',
  Catalan = 'CATALAN',
  Chinese = 'CHINESE',
  Croatian = 'CROATIAN',
  Czech = 'CZECH',
  Danish = 'DANISH',
  Dutch = 'DUTCH',
  English = 'ENGLISH',
  Estonian = 'ESTONIAN',
  Filipino = 'FILIPINO',
  Finnish = 'FINNISH',
  French = 'FRENCH',
  Galician = 'GALICIAN',
  Georgian = 'GEORGIAN',
  German = 'GERMAN',
  Greek = 'GREEK',
  Guarani = 'GUARANI',
  Gujarati = 'GUJARATI',
  Hebrew = 'HEBREW',
  Hindi = 'HINDI',
  Hungarian = 'HUNGARIAN',
  Icelandic = 'ICELANDIC',
  Indonesian = 'INDONESIAN',
  Italian = 'ITALIAN',
  Japanese = 'JAPANESE',
  Javanese = 'JAVANESE',
  Kannada = 'KANNADA',
  Kazakh = 'KAZAKH',
  Khmer = 'KHMER',
  Kinyarwanda = 'KINYARWANDA',
  Korean = 'KOREAN',
  Kurdish = 'KURDISH',
  Latvian = 'LATVIAN',
  Lithuanian = 'LITHUANIAN',
  Macedonian = 'MACEDONIAN',
  Malay = 'MALAY',
  Malayalam = 'MALAYALAM',
  Marathi = 'MARATHI',
  Mongolian = 'MONGOLIAN',
  Nepalese = 'NEPALESE',
  Norwegian = 'NORWEGIAN',
  Oriya = 'ORIYA',
  Pashto = 'PASHTO',
  Persian = 'PERSIAN',
  Polish = 'POLISH',
  Portuguese = 'PORTUGUESE',
  Punjabi = 'PUNJABI',
  Romanian = 'ROMANIAN',
  Russian = 'RUSSIAN',
  Serbian = 'SERBIAN',
  Sinhalese = 'SINHALESE',
  Slovak = 'SLOVAK',
  Slovenian = 'SLOVENIAN',
  Spanish = 'SPANISH',
  Swahili = 'SWAHILI',
  Swedish = 'SWEDISH',
  Tajik = 'TAJIK',
  Tamazight = 'TAMAZIGHT',
  Tamil = 'TAMIL',
  Thai = 'THAI',
  Turkish = 'TURKISH',
  Ukrainian = 'UKRAINIAN',
  Urdu = 'URDU',
  Uzbek = 'UZBEK',
  Vietnamese = 'VIETNAMESE',
  Visayan = 'VISAYAN',
  Welsh = 'WELSH'
}


/** A mandatory skill */
export type MandatorySkill = {
  __typename?: 'MandatorySkill';
  text: Scalars['String'];
};

export enum MandatorySkillsAutocompleteErrorEnum {
  /** Term is too short. Please provide a term with at least 3 characters. */
  TermTooShort = 'TERM_TOO_SHORT'
}

export enum MarkAllNotificationsAsSeenMutationErrorEnum {
  Unauthorized = 'UNAUTHORIZED'
}

export enum MarkNotificationAsSeenMutationErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

export type MarkNotificationsAsSeenPayload = {
  __typename?: 'MarkNotificationsAsSeenPayload';
  modifiedCount?: Maybe<Scalars['Int']>;
};

export type MatchingResult = {
  __typename?: 'MatchingResult';
  bold: Array<Bold>;
  score: Scalars['Float'];
  text: Scalars['String'];
};

export type MeetingPreference = {
  __typename?: 'MeetingPreference';
  description: Scalars['String'];
  key: InteractionGroupKeyEnum;
  name: Scalars['String'];
  value?: Maybe<Scalars['Boolean']>;
};


export type MeetingPreferenceDescriptionArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type MeetingPreferenceNameArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

export type MeetingPreferenceInput = {
  accept: Scalars['Boolean'];
  key: InteractionGroupKeyEnum;
};

export enum MemberBadgeEnum {
  AccountCreated = 'ACCOUNT_CREATED',
  FifteenthAppointmentOccurred = 'FIFTEENTH_APPOINTMENT_OCCURRED',
  FifthAppointmentOccurred = 'FIFTH_APPOINTMENT_OCCURRED',
  FiftiethAppointmentOccurred = 'FIFTIETH_APPOINTMENT_OCCURRED',
  FirstAppointmentOccurred = 'FIRST_APPOINTMENT_OCCURRED',
  FirstAppointmentReview = 'FIRST_APPOINTMENT_REVIEW',
  FirstMessageSent = 'FIRST_MESSAGE_SENT',
  HundredthAppointmentOccurred = 'HUNDREDTH_APPOINTMENT_OCCURRED',
  ProfileComplete = 'PROFILE_COMPLETE',
  SeventyFifthAppointmentOccurred = 'SEVENTY_FIFTH_APPOINTMENT_OCCURRED',
  TenthAppointmentOccurred = 'TENTH_APPOINTMENT_OCCURRED',
  ThirdAppointmentOccurred = 'THIRD_APPOINTMENT_OCCURRED',
  ThirthiethAppointmentOccurred = 'THIRTHIETH_APPOINTMENT_OCCURRED',
  TwentiethAppointmentOccurred = 'TWENTIETH_APPOINTMENT_OCCURRED'
}

/** A message */
export type Message = {
  __typename?: 'Message';
  attachment?: Maybe<MessageAttachment>;
  createdAt: Scalars['ISO8601DateTime'];
  fromSystem: Scalars['Boolean'];
  id: Scalars['ID'];
  seenAt?: Maybe<Scalars['ISO8601DateTime']>;
  sender?: Maybe<PublicProfessionalProfile | PublicStudentProfile | SchoolCoach>;
  status: MessageLocalStatus;
  text: Scalars['String'];
  user: ConversationInterlocutor;
};

/** A message attachment */
export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  name?: Maybe<Scalars['String']>;
  scannedByAntivirus?: Maybe<AntivirusStatus>;
  url: Scalars['String'];
};

export enum MessageLocalStatus {
  Delivering = 'DELIVERING',
  Error = 'ERROR',
  Delivered = 'DELIVERED'
}

/** An MJG employee */
export type MjgEmployee = {
  __typename?: 'MJGEmployee';
  avatar: Scalars['AvatarUrl'];
  firstName: Scalars['String'];
  funSentence?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle: Scalars['String'];
};


/** An MJG employee */
export type MjgEmployeeAvatarArgs = {
  version?: Maybe<PictureVersion>;
};

export type MobileNotificationSubscriptions = {
  __typename?: 'MobileNotificationSubscriptions';
  appointmentCanceled: Scalars['Boolean'];
  appointmentComingIn10mn: Scalars['Boolean'];
  appointmentCreated: Scalars['Boolean'];
  appointmentModified: Scalars['Boolean'];
  evaluationToComplete: Scalars['Boolean'];
  newMessageReceived: Scalars['Boolean'];
};

export enum MobileNotificationSubscriptionsQueryErrorEnum {
  Unauthorized = 'UNAUTHORIZED'
}

export enum MoveAppointmentMutationErrorEnum {
  CannotMoveInThePast = 'CANNOT_MOVE_IN_THE_PAST',
  CannotMovePastAppointment = 'CANNOT_MOVE_PAST_APPOINTMENT',
  MustBeTheAmbassador = 'MUST_BE_THE_AMBASSADOR',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedValidationErrors = 'UNEXPECTED_VALIDATION_ERRORS'
}

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Accept an invitation to a Vocation program ([program](#definition-program))
   * Creates a participation ([participation](#definition-participation))
   * 
   * __Errors definition__: [AcceptInvitationErrorEnum](#definition-AcceptInvitationErrorEnum)
   */
  acceptInvitation: Scalars['Boolean'];
  acknowledgeConversation: Scalars['Boolean'];
  addCompanyRecruitingWorkArea: AddRecruitingWorkingAreaPayload;
  affiliateToAssignment: AffiliationResult;
  approveAppointmentClaim: AppointmentClaim;
  approveEmployee: Scalars['Boolean'];
  archiveEmployee: Scalars['Boolean'];
  bookAppointment: Appointment;
  claimAppointment: AppointmentClaim;
  /**
   * Send the Vocation certificate by email
   * 
   * __Errors definition__: [ClaimCertificateErrorEnum](#definition-ClaimCertificateErrorEnum)
   */
  claimCertificate: Scalars['Boolean'];
  configureContractualAssignment: Scalars['Boolean'];
  /**
   * Confirms the user email
   * 
   * __Errors definition__: [ConfirmEmailErrorEnum](#definition-ConfirmEmailErrorEnum)
   */
  confirmEmail?: Maybe<AmbivalentUser>;
  /**
   * Confirm the tutor emails, only required for minor users
   * 
   * __Errors definition__: [ConfirmTutorEmailErrorEnum](#definition-ConfirmTutorEmailErrorEnum)
   */
  confirmTutorEmail?: Maybe<Scalars['Boolean']>;
  createAspiration?: Maybe<Aspiration>;
  /** Create a coach profile  on the current user */
  createCoachProfile: Scalars['Boolean'];
  createContractualFdr: FdrConfigurationSuccess;
  createConversation?: Maybe<Conversation>;
  createEducation?: Maybe<ProfileEducation>;
  /** Create a government school record which will go through moderation */
  createGouvSchool?: Maybe<GouvSchool>;
  /**
   *  Make the current user uncontactable and not visible in the search between start_at and end_at
   * 
   * __Errors definitions__: [CreateHibernationMutationErrorEnum](#definition-CreateHibernationMutationErrorEnum)
   */
  createHibernation?: Maybe<Hibernation>;
  createMessageTemplate: UserMessageTemplate;
  denyAppointmentClaim: AppointmentClaim;
  desafilliateStudentFromAssignment: Scalars['Boolean'];
  destroyAspiration?: Maybe<Scalars['Boolean']>;
  destroyEducation?: Maybe<Scalars['Boolean']>;
  /**
   *  Destroy future hibernation, or awake fron ongoing hibernation
   * 
   * __Errors definitions__: [DestroyHibernationMutationErrorEnum](#definition-DestroyHibernationMutationErrorEnum)
   */
  destroyHibernation?: Maybe<Scalars['Boolean']>;
  destroyMessageTemplate: Scalars['Boolean'];
  extractInfosFromInvitation: InvitationInfos;
  importEmployeesList?: Maybe<Scalars['Boolean']>;
  inviteEmployee: Scalars['Boolean'];
  joinProgram: Participation;
  markAllNotificationsAsSeen: MarkNotificationsAsSeenPayload;
  markNotificationAsSeen: Notification;
  moveAppointment: Appointment;
  newPassword?: Maybe<Scalars['Boolean']>;
  oauth?: Maybe<OauthPayload>;
  openidConnect?: Maybe<OpenidConnectPayload>;
  publish: Scalars['Boolean'];
  /**
   * Create a new access_token that last 4 hours
   * 
   * Authenticating mutations:
   * - [signin](#mutation-signin)
   * - [signup](#mutation-signup)
   * - [confirmEmail](#mutation-confirmEmail)
   * - [oauth](#mutation-oauth)
   * 
   * Errors: [refreshAccessTokenErrorEnum](#definition-refreshAccessTokenErrorEnum)
   */
  refreshAccessToken?: Maybe<RefreshAccessTokenPayload>;
  removeCompanyRecruitingWorkArea: RemoveRecruitingWorkingAreaPayload;
  removeGalleryImage?: Maybe<AmbivalentUser>;
  reportAppointmentDidNotTookPlace: Appointment;
  reportMember?: Maybe<Report>;
  requestEmailAddressConfirmationEmail?: Maybe<Scalars['Boolean']>;
  requestModeration: Scalars['Boolean'];
  /** Request a new password / password forgotten */
  requestNewPassword?: Maybe<Scalars['Boolean']>;
  reviewAppointmentAsAmbassador: Appointment;
  reviewAppointmentAsMember: Appointment;
  /** Saves the user's push notification token */
  saveNotificationToken: SaveTokenFields;
  sendEmployeeInvitationEmail: Scalars['Boolean'];
  sendMessage: Message;
  signin?: Maybe<User>;
  signout?: Maybe<Scalars['Boolean']>;
  /**
   * Register a user
   * __Errors definition__:
   * [signupErrorEnum](#definition-signupErrorEnum):
   * 
   * description: "[signupErrorEnum](#definition-signupErrorEnum):
   * "
   */
  signup?: Maybe<AmbivalentUser>;
  snoozeAppointmentClaimReview: AppointmentClaim;
  snoozeReview: Appointment;
  /**
   * Soft delete an account, unpublishing him and making his account unaccessible without admin intervention
   * 
   * __Errors definition__: [SoftDeleteMutationErrorEnum](#definition-SoftDeleteMutationErrorEnum)
   */
  softDelete?: Maybe<Scalars['Boolean']>;
  unbookAppointment: Appointment;
  unpublish: Scalars['Boolean'];
  unwishAmbassador: Scalars['Boolean'];
  updateAspiration?: Maybe<Aspiration>;
  updateCompany: Scalars['Boolean'];
  updateContractualAssignment: Scalars['Boolean'];
  updateCustomAttribute: Scalars['Boolean'];
  updateEducation?: Maybe<ProfileEducation>;
  updateMessageTemplate: UserMessageTemplate;
  updateMobileNotificationSubscriptions: MobileNotificationSubscriptions;
  /**
   * Updates a user
   * 
   * __Errors definition__: [UpdateUserErrorEnum](#definition-UpdateUserErrorEnum)
   */
  updateUser?: Maybe<AmbivalentUser>;
  uploadGalleryImage?: Maybe<GalleryImage>;
  verifyEmployeesList?: Maybe<VerifyEmployeesListPayload>;
  wishAmbassador: Scalars['Boolean'];
  wishProfessional?: Maybe<Scalars['Boolean']>;
};


export type MutationAcceptInvitationArgs = {
  invitationId: Scalars['ID'];
};


export type MutationAcknowledgeConversationArgs = {
  conversationId: Scalars['ID'];
};


export type MutationAddCompanyRecruitingWorkAreaArgs = {
  companyId: Scalars['ID'];
  workingArea: Scalars['String'];
};


export type MutationAffiliateToAssignmentArgs = {
  assignmentAffiliationToken: Scalars['String'];
};


export type MutationApproveAppointmentClaimArgs = {
  appointmentClaimId: Scalars['ID'];
};


export type MutationApproveEmployeeArgs = {
  employeeId: Scalars['ID'];
};


export type MutationArchiveEmployeeArgs = {
  employeeId: Scalars['ID'];
};


export type MutationBookAppointmentArgs = {
  googlePlaceId?: Maybe<Scalars['String']>;
  meetingPlace?: Maybe<Scalars['String']>;
  startAt: Scalars['ISO8601DateTime'];
  type: AppointmentTypesEnum;
  userId: Scalars['ID'];
  videoconferenceLink?: Maybe<Scalars['String']>;
};


export type MutationClaimAppointmentArgs = {
  conversationId: Scalars['ID'];
  googlePlaceId?: Maybe<Scalars['String']>;
  meetingPlace?: Maybe<Scalars['String']>;
  startAt: Scalars['ISO8601DateTime'];
  type: AppointmentTypesEnum;
  userId: Scalars['ID'];
};


export type MutationClaimCertificateArgs = {
  participationId: Scalars['ID'];
};


export type MutationConfigureContractualAssignmentArgs = {
  emailLanguage: CommunicationLanguage;
  fdrId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
};


export type MutationConfirmEmailArgs = {
  confirmationToken: Scalars['String'];
};


export type MutationConfirmTutorEmailArgs = {
  confirmationToken: Scalars['String'];
};


export type MutationCreateAspirationArgs = {
  description: Scalars['String'];
  duration: InternshipDuration;
  startsAtMonth: Scalars['Int'];
  startsAtYear: Scalars['Int'];
  status: InternshipStatus;
  type: InternshipTypeEnum;
  userId: Scalars['ID'];
};


export type MutationCreateContractualFdrArgs = {
  academicYear: Scalars['String'];
  appointmentBonusThreshold1?: Maybe<Scalars['Int']>;
  appointmentBonusThreshold2?: Maybe<Scalars['Int']>;
  campusName: Scalars['String'];
  conversationBonusActivated: Scalars['Boolean'];
  conversationBonusDeadline?: Maybe<Scalars['Int']>;
  conversationBonusMessageCount?: Maybe<Scalars['Int']>;
  conversationPenaltyActivated: Scalars['Boolean'];
  customIdentifier?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  diplomaLevel: Array<DiplomaLevel>;
  emailReminderActivated: Scalars['Boolean'];
  emailReminderLanguage?: Maybe<CommunicationLanguage>;
  emailReminderWeeklyRate?: Maybe<Scalars['Int']>;
  endsAt: Scalars['ISO8601DateTime'];
  numberOfMandatoryAppointmentsPerStudent: Scalars['Int'];
  schoolName: Scalars['String'];
  startsAt: Scalars['ISO8601DateTime'];
  supervisorEmail?: Maybe<Scalars['String']>;
};


export type MutationCreateConversationArgs = {
  conversationType?: Maybe<ConversationTypeKeyEnum>;
  groupSessionInput?: Maybe<GroupSessionInput>;
  message: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationCreateEducationArgs = {
  diplomaTitle: Scalars['String'];
  diplomaType: DiplomaEnum;
  endedAt?: Maybe<Scalars['ISO8601DateTime']>;
  schoolName: Scalars['String'];
  startedAt?: Maybe<Scalars['ISO8601DateTime']>;
  userId: Scalars['ID'];
};


export type MutationCreateGouvSchoolArgs = {
  communeName?: Maybe<Scalars['String']>;
  departmentLabel?: Maybe<Scalars['String']>;
  establishmentType?: Maybe<SecondarySituationEnum>;
  name: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationCreateHibernationArgs = {
  endAt: Scalars['ISO8601Date'];
  startAt: Scalars['ISO8601Date'];
};


export type MutationCreateMessageTemplateArgs = {
  body: Scalars['String'];
  fromTemplate?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationDenyAppointmentClaimArgs = {
  appointmentClaimId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  reason: AppointmentDidNotTookPlaceReasonEnum;
};


export type MutationDesafilliateStudentFromAssignmentArgs = {
  assignmentId: Scalars['ID'];
  participationId: Scalars['ID'];
};


export type MutationDestroyAspirationArgs = {
  aspirationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationDestroyEducationArgs = {
  educationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationDestroyHibernationArgs = {
  id: Scalars['ID'];
};


export type MutationDestroyMessageTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationExtractInfosFromInvitationArgs = {
  invitationToken: Scalars['String'];
};


export type MutationImportEmployeesListArgs = {
  companyId: Scalars['ID'];
  file: Scalars['Upload'];
};


export type MutationInviteEmployeeArgs = {
  companyAttribute1?: Maybe<Scalars['String']>;
  companyAttribute2?: Maybe<Scalars['String']>;
  companyAttribute3?: Maybe<Scalars['String']>;
  companyId: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


export type MutationJoinProgramArgs = {
  programCode: Scalars['String'];
};


export type MutationMarkNotificationAsSeenArgs = {
  id: Scalars['ID'];
};


export type MutationMoveAppointmentArgs = {
  googlePlaceId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meetingPlace?: Maybe<Scalars['String']>;
  startAt: Scalars['ISO8601DateTime'];
  type: AppointmentTypesEnum;
  videoconferenceLink?: Maybe<Scalars['String']>;
};


export type MutationNewPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationOauthArgs = {
  authorizationCode: Scalars['String'];
  provider: OauthProviderEnum;
  redirectUri: Scalars['String'];
};


export type MutationOpenidConnectArgs = {
  identityToken: Scalars['String'];
  provider: OpenidConnectProvider;
};


export type MutationPublishArgs = {
  userId: Scalars['ID'];
};


export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRemoveCompanyRecruitingWorkAreaArgs = {
  companyId: Scalars['ID'];
  workingArea: Scalars['String'];
};


export type MutationRemoveGalleryImageArgs = {
  imageId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationReportAppointmentDidNotTookPlaceArgs = {
  appointmentId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  reason: AppointmentDidNotTookPlaceReasonEnum;
};


export type MutationReportMemberArgs = {
  explanation: Scalars['String'];
  reason: ReportReason;
  reportedId: Scalars['ID'];
};


export type MutationRequestEmailAddressConfirmationEmailArgs = {
  email: Scalars['Email'];
};


export type MutationRequestModerationArgs = {
  userId: Scalars['ID'];
};


export type MutationRequestNewPasswordArgs = {
  email: Scalars['Email'];
};


export type MutationReviewAppointmentAsAmbassadorArgs = {
  id: Scalars['ID'];
  message: Scalars['String'];
  messageForHr?: Maybe<Scalars['String']>;
  question1: Scalars['Int'];
  question2?: Maybe<Scalars['Int']>;
  question3?: Maybe<Scalars['Int']>;
};


export type MutationReviewAppointmentAsMemberArgs = {
  aspiration?: Maybe<AspirationsInput>;
  id: Scalars['ID'];
  liked: Scalars['Boolean'];
  message: Scalars['String'];
  question1: Scalars['Int'];
  question2: Scalars['Int'];
  question3: Scalars['Int'];
  schoolAspiration?: Maybe<SchoolAspirationInput>;
};


export type MutationSaveNotificationTokenArgs = {
  token: Scalars['String'];
};


export type MutationSendEmployeeInvitationEmailArgs = {
  employeeId: Scalars['ID'];
};


export type MutationSendMessageArgs = {
  attachment?: Maybe<Scalars['Upload']>;
  conversationId: Scalars['ID'];
  fromMessageTemplateId?: Maybe<Scalars['ID']>;
  text: Scalars['String'];
};


export type MutationSigninArgs = {
  email: Scalars['Email'];
  password: Scalars['String'];
};


export type MutationSignoutArgs = {
  fromAllDevices?: Maybe<Scalars['Boolean']>;
};


export type MutationSignupArgs = {
  birthdate?: Maybe<Scalars['ISO8601Date']>;
  comesFrom?: Maybe<ComesFromEnum>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  highSchoolTeacher?: Maybe<Scalars['Boolean']>;
  invitationToken?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  oauthNonce?: Maybe<Scalars['String']>;
  orianeAmbassador?: Maybe<Scalars['Boolean']>;
  schoolTeacherDetails?: Maybe<SchoolTeacherDetails>;
  tutorEmail?: Maybe<Scalars['String']>;
  vocationCoachSignup?: Maybe<Scalars['Boolean']>;
};


export type MutationSnoozeAppointmentClaimReviewArgs = {
  appointmentClaimId: Scalars['ID'];
};


export type MutationSnoozeReviewArgs = {
  appointmentId: Scalars['ID'];
};


export type MutationSoftDeleteArgs = {
  reason: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationUnbookAppointmentArgs = {
  id: Scalars['ID'];
};


export type MutationUnpublishArgs = {
  userId: Scalars['ID'];
};


export type MutationUnwishAmbassadorArgs = {
  userId: Scalars['ID'];
};


export type MutationUpdateAspirationArgs = {
  aspirationId: Scalars['ID'];
  description: Scalars['String'];
  duration: InternshipDuration;
  startsAtMonth: Scalars['Int'];
  startsAtYear: Scalars['Int'];
  status: InternshipStatus;
  type: InternshipTypeEnum;
  userId: Scalars['ID'];
};


export type MutationUpdateCompanyArgs = {
  companyId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  establishmentType?: Maybe<SecondarySituationEnum>;
  name?: Maybe<Scalars['String']>;
  sectorId?: Maybe<Scalars['ID']>;
  size?: Maybe<CompanySize>;
};


export type MutationUpdateContractualAssignmentArgs = {
  assignmentId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
};


export type MutationUpdateCustomAttributeArgs = {
  companyAttributeValues?: Maybe<Array<Scalars['String']>>;
  companyId: Scalars['ID'];
  customAttributeNumber: Scalars['Int'];
  customEmployeeAttribute?: Maybe<Scalars['String']>;
};


export type MutationUpdateEducationArgs = {
  diplomaTitle: Scalars['String'];
  diplomaType: DiplomaEnum;
  educationId: Scalars['ID'];
  endedAt?: Maybe<Scalars['ISO8601DateTime']>;
  schoolName: Scalars['String'];
  startedAt?: Maybe<Scalars['ISO8601DateTime']>;
  userId: Scalars['ID'];
};


export type MutationUpdateMessageTemplateArgs = {
  body: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationUpdateMobileNotificationSubscriptionsArgs = {
  appointmentCanceled?: Maybe<Scalars['Boolean']>;
  appointmentComingIn10mn?: Maybe<Scalars['Boolean']>;
  appointmentCreated?: Maybe<Scalars['Boolean']>;
  appointmentModified?: Maybe<Scalars['Boolean']>;
  evaluationToComplete?: Maybe<Scalars['Boolean']>;
  newMessageReceived?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateUserArgs = {
  acceptGroupConversations?: Maybe<Scalars['Boolean']>;
  accompaniedStudyLevels?: Maybe<Array<GradeEnum>>;
  acquiredDiplomaAcquisitionYear?: Maybe<Scalars['Int']>;
  acquiredDiplomaBeginYear?: Maybe<Scalars['Int']>;
  acquiredDiplomaLevel?: Maybe<DiplomaEnum>;
  acquiredDiplomaSchoolName?: Maybe<Scalars['String']>;
  acquiredDiplomaTitle?: Maybe<Scalars['String']>;
  ambassadorPresentation?: Maybe<Scalars['String']>;
  apprentice?: Maybe<Scalars['Boolean']>;
  aspirationPresentation?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['Upload']>;
  birthdate?: Maybe<Scalars['ISO8601Date']>;
  companyName?: Maybe<Scalars['String']>;
  currentGrade?: Maybe<GradeEnum>;
  currentPassword?: Maybe<Scalars['String']>;
  currentScholarityEndAt?: Maybe<Scalars['ISO8601Date']>;
  currentScholarityStartAt?: Maybe<Scalars['ISO8601Date']>;
  currentSchoolCity?: Maybe<Scalars['String']>;
  currentSchoolDepartment?: Maybe<Scalars['String']>;
  currentSchoolName?: Maybe<Scalars['String']>;
  dontSharePhoneNumber?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  expertisePresentation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderEnum>;
  googlePlaceId?: Maybe<Scalars['String']>;
  headTeacher?: Maybe<Scalars['Boolean']>;
  interestsPresentation?: Maybe<Scalars['String']>;
  jobSeekerSupportOrganizationType?: Maybe<Scalars['String']>;
  jobSeekerSupportPrograms?: Maybe<Array<Scalars['String']>>;
  jobSeeking?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Language>>;
  lastName?: Maybe<Scalars['String']>;
  mandatorySkills?: Maybe<Array<Scalars['String']>>;
  maxMeetingsPerMonth?: Maybe<Scalars['Int']>;
  meetingPlace?: Maybe<Scalars['String']>;
  meetingPreferences?: Maybe<Array<MeetingPreferenceInput>>;
  memberPresentation?: Maybe<Scalars['String']>;
  neverWorked?: Maybe<Scalars['Boolean']>;
  normalizedPhone?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  preparedDiplomaTitle?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  previousCompanyName?: Maybe<Scalars['String']>;
  previousPosition?: Maybe<Scalars['String']>;
  previousPositionYearsOfExperiences?: Maybe<Scalars['Int']>;
  primarySituation?: Maybe<PrimarySituationEnum>;
  programParticipationCode?: Maybe<Scalars['String']>;
  recommendedQualifications?: Maybe<Array<Scalars['String']>>;
  returningToStudy?: Maybe<Scalars['Boolean']>;
  schoolName?: Maybe<Scalars['String']>;
  secondarySituation?: Maybe<SecondarySituationEnum>;
  showCongratulationsPanel?: Maybe<Scalars['Boolean']>;
  showVocationPanel?: Maybe<Scalars['Boolean']>;
  showWelcomePanel?: Maybe<Scalars['Boolean']>;
  teachedSchoolSubjects?: Maybe<Array<Scalars['String']>>;
  userId: Scalars['ID'];
  vocationCoachSignup?: Maybe<Scalars['Boolean']>;
  wantToShare?: Maybe<Scalars['Boolean']>;
  yearsOfExperience?: Maybe<Scalars['Int']>;
};


export type MutationUploadGalleryImageArgs = {
  image: Scalars['Upload'];
  userId: Scalars['ID'];
};


export type MutationVerifyEmployeesListArgs = {
  companyId: Scalars['ID'];
  file: Scalars['Upload'];
};


export type MutationWishAmbassadorArgs = {
  userId: Scalars['ID'];
};


export type MutationWishProfessionalArgs = {
  automaticallyWished?: Maybe<Scalars['Boolean']>;
  professionalId: Scalars['ID'];
};

export type Notification = {
  __typename?: 'Notification';
  createdAt?: Maybe<Scalars['String']>;
  deepLink?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  picture?: Maybe<Avatar>;
  pushNotificationSentAt?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['ID']>;
  resourceType?: Maybe<NotificationResourceTypeEnum>;
  seen?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<NotificationTypeEnum>;
  userId?: Maybe<Scalars['ID']>;
};

/** The connection type for Notification. */
export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<NotificationEdge>>;
  /** A list of nodes. */
  nodes: Array<Notification>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Notification>;
};

export enum NotificationQueryErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

export enum NotificationResourceTypeEnum {
  Appointment = 'APPOINTMENT',
  Conversation = 'CONVERSATION'
}

export type NotificationsFiltersInput = {
  seenEq?: Maybe<Scalars['Boolean']>;
};

export enum NotificationsQueryErrorEnum {
  Unauthorized = 'UNAUTHORIZED'
}

export enum NotificationTypeEnum {
  AppointmentCanceled = 'APPOINTMENT_CANCELED',
  AppointmentComingIn_10Mn = 'APPOINTMENT_COMING_IN_10MN',
  AppointmentCreated = 'APPOINTMENT_CREATED',
  AppointmentModified = 'APPOINTMENT_MODIFIED',
  EvaluationToComplete = 'EVALUATION_TO_COMPLETE',
  NewMessageReceived = 'NEW_MESSAGE_RECEIVED'
}

export enum OauthErrorEnum {
  /** __Signin__: Needs email confirmation */
  EmailUnconfirmed = 'EMAIL_UNCONFIRMED',
  /** The provider identity is already used by another user */
  IdentityAlreadyUsedByAnotherUser = 'IDENTITY_ALREADY_USED_BY_ANOTHER_USER',
  /** Oauth provider couldn't retrieve access token */
  RequestAccessTokenFailed = 'REQUEST_ACCESS_TOKEN_FAILED',
  /** __Signin__: Needs tutor email confirmation */
  TutorEmailUnconfirmed = 'TUTOR_EMAIL_UNCONFIRMED',
  /** __Signin__: User account is restricted */
  UserAccountRestricted = 'USER_ACCOUNT_RESTRICTED',
  /** __Signin__: User is soft deleted */
  UserSoftDeleted = 'USER_SOFT_DELETED'
}

export type OauthPayload = {
  __typename?: 'OauthPayload';
  accessToken?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nonce: Scalars['String'];
  refreshToken?: Maybe<Scalars['String']>;
};

export enum OauthProviderEnum {
  FranceTravail = 'FRANCE_TRAVAIL',
  Linkedin = 'LINKEDIN'
}

export enum OpenidConnectMutationErrorEnum {
  /** __Signin__: Needs email confirmation */
  EmailUnconfirmed = 'EMAIL_UNCONFIRMED',
  /** The openid token expired */
  IdentityTokenExpired = 'IDENTITY_TOKEN_EXPIRED',
  /** The openid token has an invalid format */
  IdentityTokenInvalid = 'IDENTITY_TOKEN_INVALID',
  /** The openid token belongs to another provider */
  IdentityTokenInvalidSignature = 'IDENTITY_TOKEN_INVALID_SIGNATURE',
  /** Failed to verify the openid identity */
  OpenidConnectFailed = 'OPENID_CONNECT_FAILED',
  /** __Signin__: Needs tutor email confirmation */
  TutorEmailUnconfirmed = 'TUTOR_EMAIL_UNCONFIRMED',
  /** __Signin__: User account is restricted */
  UserAccountRestricted = 'USER_ACCOUNT_RESTRICTED',
  /** __Signin__: User is soft deleted */
  UserSoftDeleted = 'USER_SOFT_DELETED'
}

export type OpenidConnectPayload = {
  __typename?: 'OpenidConnectPayload';
  accessToken?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nonce: Scalars['String'];
  refreshToken?: Maybe<Scalars['String']>;
};

export enum OpenidConnectProvider {
  Apple = 'APPLE'
}

export type OrganizationCardBanner = {
  __typename?: 'OrganizationCardBanner';
  original?: Maybe<Image>;
  small?: Maybe<Image>;
  url?: Maybe<Scalars['String']>;
};

export type OrganizationLogo = {
  __typename?: 'OrganizationLogo';
  original?: Maybe<Image>;
  small?: Maybe<Image>;
  smaller?: Maybe<Image>;
  thumb?: Maybe<Image>;
  url?: Maybe<Scalars['String']>;
};

export type OrganizationProfileBannerDesktop = {
  __typename?: 'OrganizationProfileBannerDesktop';
  original?: Maybe<Image>;
  small?: Maybe<Image>;
  url?: Maybe<Scalars['String']>;
};

export type OrganizationProfileBannerMobile = {
  __typename?: 'OrganizationProfileBannerMobile';
  original?: Maybe<Image>;
  small?: Maybe<Image>;
  url?: Maybe<Scalars['String']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Participation = {
  __typename?: 'Participation';
  certificationStatus?: Maybe<Scalars['String']>;
  confirmedMeetingCount?: Maybe<Scalars['Int']>;
  contacts?: Maybe<Array<HermesContact>>;
  createdAt: Scalars['ISO8601DateTime'];
  hermesContacts?: Maybe<Array<HermesContact>>;
  id: Scalars['ID'];
  program?: Maybe<Program>;
  reportCount?: Maybe<Scalars['Int']>;
  unfollowedConversationCount?: Maybe<Scalars['Int']>;
};

/** A school that is partnered with MJG to operate FDRs */
export type PartnerSchool = {
  __typename?: 'PartnerSchool';
  configurableFdrs: Array<Fdr>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PastReview = {
  __typename?: 'PastReview';
  feedback: Scalars['String'];
  reviewerOrganizationName: Scalars['String'];
  reviewerTitle: Scalars['String'];
};


/** All the versions of a picture */
export enum PictureVersion {
  /** A blurred version of the picture */
  Blurred = 'BLURRED',
  /** The original picture */
  Original = 'ORIGINAL',
  /** The picture resized to 200x200 */
  Small = 'SMALL',
  /** The picture resized to 100x100 */
  Smaller = 'SMALLER',
  /** The picture resized to 50x50 */
  Thumb = 'THUMB'
}

export type PresentationTranslations = {
  __typename?: 'PresentationTranslations';
  ambassadorPresentationDescription: Scalars['String'];
  ambassadorPresentationExample: Scalars['String'];
  ambassadorPresentationQuestion: Scalars['String'];
  ambassadorPresentationTitle: Scalars['String'];
  aspirationsExample: Scalars['String'];
  aspirationsQuestion: Scalars['String'];
  expertisesExample: Scalars['String'];
  expertisesQuestion: Scalars['String'];
  interestsExample: Scalars['String'];
  interestsQuestion: Scalars['String'];
  memberPresentationDescription: Scalars['String'];
  memberPresentationExample: Scalars['String'];
  memberPresentationQuestion: Scalars['String'];
  memberPresentationTitle: Scalars['String'];
};


export type PresentationTranslationsAmbassadorPresentationDescriptionArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type PresentationTranslationsAmbassadorPresentationExampleArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type PresentationTranslationsAmbassadorPresentationQuestionArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type PresentationTranslationsAmbassadorPresentationTitleArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type PresentationTranslationsAspirationsExampleArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type PresentationTranslationsAspirationsQuestionArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type PresentationTranslationsExpertisesExampleArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type PresentationTranslationsExpertisesQuestionArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type PresentationTranslationsInterestsExampleArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type PresentationTranslationsInterestsQuestionArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type PresentationTranslationsMemberPresentationDescriptionArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type PresentationTranslationsMemberPresentationExampleArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type PresentationTranslationsMemberPresentationQuestionArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};


export type PresentationTranslationsMemberPresentationTitleArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

export type PrimarySituation = {
  __typename?: 'PrimarySituation';
  jobTitles?: Maybe<Array<JobTitle>>;
  key: PrimarySituationEnum;
  name: Scalars['String'];
  secondarySituations?: Maybe<Array<SecondarySituation>>;
};


export type PrimarySituationNameArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

/** Primary situations */
export enum PrimarySituationEnum {
  JobSeeker = 'JOB_SEEKER',
  JobSeekerSupport = 'JOB_SEEKER_SUPPORT',
  Other = 'OTHER',
  PostBacStudentSupport = 'POST_BAC_STUDENT_SUPPORT',
  ProfessionalAndSecondarySchoolStudentSupport = 'PROFESSIONAL_AND_SECONDARY_SCHOOL_STUDENT_SUPPORT',
  Retired = 'RETIRED',
  Student = 'STUDENT',
  Worker = 'WORKER'
}

/** Ambassador profile queried by its owner */
export type PrivateCompanyEmployeeProfile = PrivateProfile & {
  __typename?: 'PrivateCompanyEmployeeProfile';
  appointments: Array<Appointment>;
  appointmentsCount: Scalars['Int'];
  avatar?: Maybe<Scalars['AvatarUrl']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  lastUpdatedAt: Scalars['ISO8601DateTime'];
  signupAt: Scalars['ISO8601DateTime'];
  unreadConversationsCount: Scalars['Int'];
};


/** Ambassador profile queried by its owner */
export type PrivateCompanyEmployeeProfileAppointmentsArgs = {
  scope?: Maybe<AppointmentListScope>;
};


/** Ambassador profile queried by its owner */
export type PrivateCompanyEmployeeProfileAppointmentsCountArgs = {
  scope?: Maybe<AppointmentListScope>;
};


/** Ambassador profile queried by its owner */
export type PrivateCompanyEmployeeProfileAvatarArgs = {
  version?: Maybe<PictureVersion>;
};

export type PrivateData = {
  __typename?: 'PrivateData';
  id: Scalars['ID'];
  profileType: PrivateDataProfileType;
  firstName: Scalars['String'];
  avatar?: Maybe<Scalars['AvatarUrl']>;
  unreadMessageCount: Scalars['Int'];
};

export enum PrivateDataProfileType {
  Mentor = 'MENTOR',
  Employee = 'EMPLOYEE',
  Student = 'STUDENT'
}

/** A profile seen by the user who possess this profile */
export type PrivateProfile = {
  avatar?: Maybe<Scalars['AvatarUrl']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};


/** A profile seen by the user who possess this profile */
export type PrivateProfileAvatarArgs = {
  version?: Maybe<PictureVersion>;
};

/** Timeline missions for students */
export type PrivateStudentMissions = {
  __typename?: 'PrivateStudentMissions';
  current: StudentMission;
  next?: Maybe<StudentMission>;
  previous?: Maybe<StudentMission>;
};

/** Student profile queried by its owner */
export type PrivateStudentProfile = PrivateProfile & {
  __typename?: 'PrivateStudentProfile';
  appointments: Array<Appointment>;
  appointmentsCount: Scalars['Int'];
  assignments: Array<StudentParticipation>;
  avatar?: Maybe<Scalars['AvatarUrl']>;
  contactsRemainingCount: Scalars['Int'];
  conversationsCount: Scalars['Int'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  lastUpdatedAt: Scalars['ISO8601DateTime'];
  missions: PrivateStudentMissions;
  responseRate?: Maybe<Scalars['Percentage']>;
  signupAt: Scalars['ISO8601DateTime'];
  uniqueProfileViewsCount: Scalars['Int'];
  unreadConversationsCount: Scalars['Int'];
  wishedProfessionalsCount: Scalars['Int'];
};


/** Student profile queried by its owner */
export type PrivateStudentProfileAppointmentsArgs = {
  scope?: Maybe<AppointmentListScope>;
};


/** Student profile queried by its owner */
export type PrivateStudentProfileAppointmentsCountArgs = {
  scope?: Maybe<AppointmentListScope>;
};


/** Student profile queried by its owner */
export type PrivateStudentProfileAssignmentsArgs = {
  scope?: Maybe<AssignmentsListScope>;
};


/** Student profile queried by its owner */
export type PrivateStudentProfileAvatarArgs = {
  version?: Maybe<PictureVersion>;
};

export enum ProfessionalProfileQueryErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

/** All the possible type of a professional profile */
export enum ProfessionalProfileType {
  /** translation missing: en.graphql.schema.enums.professional_profile.employee */
  Employee = 'EMPLOYEE',
  /** translation missing: en.graphql.schema.enums.professional_profile.mentor */
  Mentor = 'MENTOR',
  /** translation missing: en.graphql.schema.enums.professional_profile.vip */
  Vip = 'VIP'
}

/** List of profiles */
export enum Profile {
  /** ambassador */
  Ambassador = 'AMBASSADOR',
  /** company admin */
  CompanyAdmin = 'COMPANY_ADMIN',
  /** expert */
  Expert = 'EXPERT',
  /** explorer */
  Explorer = 'EXPLORER',
  /** mentor */
  Mentor = 'MENTOR',
  /** mjg admin */
  MjgAdmin = 'MJG_ADMIN',
  /** moderator */
  Moderator = 'MODERATOR',
  /** school coach */
  SchoolCoach = 'SCHOOL_COACH',
  /** student */
  Student = 'STUDENT'
}

export type ProfileEducation = {
  __typename?: 'ProfileEducation';
  diplomaTitle?: Maybe<Scalars['String']>;
  diplomaType?: Maybe<DiplomaEnum>;
  endedAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  schoolName: Scalars['String'];
  startedAt?: Maybe<Scalars['ISO8601DateTime']>;
  user: AmbivalentUser;
};

export type Program = {
  __typename?: 'Program';
  author?: Maybe<CollaborationUser>;
  authorFirstName?: Maybe<Scalars['String']>;
  collaborations?: Maybe<Array<ProgramCollaboration>>;
  description?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  meetingsQuantity?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organizationName: Scalars['String'];
  programTemplate?: Maybe<ProgramProgramTemplate>;
  project?: Maybe<ProgramProject>;
  startAt?: Maybe<Scalars['ISO8601DateTime']>;
  templateName: Scalars['String'];
};

export type ProgramCollaboration = {
  __typename?: 'ProgramCollaboration';
  id: Scalars['ID'];
  user?: Maybe<CollaborationUser>;
};

export type ProgramProgramTemplate = {
  __typename?: 'ProgramProgramTemplate';
  displayedName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type ProgramProject = {
  __typename?: 'ProgramProject';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<ProjectOrganization>;
};

export type ProjectOrganization = {
  __typename?: 'ProjectOrganization';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export enum PublicationStatuses {
  ChangesToBeCommitted = 'CHANGES_TO_BE_COMMITTED',
  PublicationRequested = 'PUBLICATION_REQUESTED',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

/** A public professional profile */
export type PublicProfessionalProfile = PublicProfile & {
  __typename?: 'PublicProfessionalProfile';
  ambassadorPresentation?: Maybe<Scalars['String']>;
  aspirationPresentation?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['AvatarUrl']>;
  avatars: Avatar;
  banner?: Maybe<OrganizationCardBanner>;
  birthdate?: Maybe<Scalars['ISO8601Date']>;
  companyDescription?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<Scalars['LogoUrl']>;
  companyName?: Maybe<Scalars['String']>;
  companyRecruitingWorkingAreas?: Maybe<Array<Scalars['String']>>;
  companySector?: Maybe<Scalars['String']>;
  companySize: CompanySize;
  currentGrade?: Maybe<Grade>;
  currentSchoolLogo?: Maybe<OrganizationLogo>;
  currentSchoolName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  educations: Array<ProfileEducation>;
  email?: Maybe<Scalars['String']>;
  expertisePresentation?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  firstPublishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  galleryImages: Array<GalleryImage>;
  id: Scalars['ID'];
  interestsPresentation?: Maybe<Scalars['String']>;
  isPaidAmbassador: Scalars['Boolean'];
  isSubsidiaryCompany: Scalars['Boolean'];
  /** @deprecated Use position on AmbivalentUser */
  jobTitle?: Maybe<Scalars['String']>;
  languages: Array<Language>;
  lastName?: Maybe<Scalars['String']>;
  likeCount?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  meetingPlace?: Maybe<Scalars['String']>;
  meetingPreferences?: Maybe<Array<MeetingPreference>>;
  meetingsLeft: Scalars['Int'];
  memberPresentation?: Maybe<Scalars['String']>;
  onlyFrenchWarning: Scalars['Boolean'];
  onlyFrenchWarningConfirmText?: Maybe<Scalars['String']>;
  onlyFrenchWarningText?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  positiveLikeCount: Scalars['Int'];
  preparedDiplomaTitle?: Maybe<Scalars['String']>;
  presentation?: Maybe<Scalars['String']>;
  /** @deprecated Use company_name on AmbivalentUser */
  previousCompanyName?: Maybe<Scalars['String']>;
  /** @deprecated Use position on AmbivalentUser */
  previousJobTitle?: Maybe<Scalars['String']>;
  primarySituation?: Maybe<PrimarySituation>;
  profileType: ProfessionalProfileType;
  profileViews: Scalars['Int'];
  published: Scalars['Boolean'];
  recommendedProfessionals: Array<AmbassadorResults>;
  recommendedQualifications?: Maybe<Array<Scalars['String']>>;
  responseRate?: Maybe<Scalars['Percentage']>;
  responseTime?: Maybe<Scalars['Int']>;
  secondarySituation?: Maybe<SecondarySituation>;
  similarProfessionals: PublicProfessionalProfileConnection;
  skills: Array<Scalars['String']>;
  status: PublicProfessionalProfileStatus;
  studyLevels: Array<StudyLevel>;
  subsidiaryDesignationEn?: Maybe<Scalars['String']>;
  subsidiaryDesignationFr?: Maybe<Scalars['String']>;
  unavailableUntil?: Maybe<Scalars['ISO8601DateTime']>;
  user?: Maybe<AmbivalentUser>;
  userId: Scalars['ID'];
  userMetTag?: Maybe<UserMetTag>;
  visibilityStatus: VisibilityStatusEnum;
};


/** A public professional profile */
export type PublicProfessionalProfileAvatarArgs = {
  version?: Maybe<PictureVersion>;
};


/** A public professional profile */
export type PublicProfessionalProfileSimilarProfessionalsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for PublicProfessionalProfile. */
export type PublicProfessionalProfileConnection = {
  __typename?: 'PublicProfessionalProfileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PublicProfessionalProfileEdge>>;
  /** A list of nodes. */
  nodes: Array<PublicProfessionalProfile>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type PublicProfessionalProfileEdge = {
  __typename?: 'PublicProfessionalProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PublicProfessionalProfile>;
};

/** All the possible status of a public profile */
export enum PublicProfessionalProfileStatus {
  /** translation missing: en.graphql.schema.enums.public_profile_status.anonymized */
  Anonymized = 'ANONYMIZED',
  /** translation missing: en.graphql.schema.enums.public_profile_status.hibernated */
  Hibernated = 'HIBERNATED',
  /** translation missing: en.graphql.schema.enums.public_profile_status.iced_up_or_restricted */
  IcedUpOrRestricted = 'ICED_UP_OR_RESTRICTED',
  /** translation missing: en.graphql.schema.enums.public_profile_status.never_published */
  NeverPublished = 'NEVER_PUBLISHED',
  /** translation missing: en.graphql.schema.enums.public_profile_status.published */
  Published = 'PUBLISHED',
  /** translation missing: en.graphql.schema.enums.public_profile_status.red_carpet_only */
  RedCarpetOnly = 'RED_CARPET_ONLY',
  /** translation missing: en.graphql.schema.enums.public_profile_status.soft_deleted */
  SoftDeleted = 'SOFT_DELETED',
  /** translation missing: en.graphql.schema.enums.public_profile_status.unavailable */
  Unavailable = 'UNAVAILABLE',
  /** translation missing: en.graphql.schema.enums.public_profile_status.unpublished */
  Unpublished = 'UNPUBLISHED'
}

/** A public profile */
export type PublicProfile = {
  avatar?: Maybe<Scalars['AvatarUrl']>;
  avatars: Avatar;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};


/** A public profile */
export type PublicProfileAvatarArgs = {
  version?: Maybe<PictureVersion>;
};

/** A public student profile */
export type PublicStudentProfile = PublicProfile & {
  __typename?: 'PublicStudentProfile';
  avatar?: Maybe<Scalars['AvatarUrl']>;
  avatars: Avatar;
  birthdate?: Maybe<Scalars['ISO8601Date']>;
  confirmedAppointmentCount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  educations: Array<Education>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primarySituation?: Maybe<PrimarySituation>;
  professionalProjects: Array<StudentProfessionalProject>;
  secondarySituation?: Maybe<SecondarySituation>;
  status: StudentStatus;
  userId: Scalars['ID'];
};


/** A public student profile */
export type PublicStudentProfileAvatarArgs = {
  version?: Maybe<PictureVersion>;
};

export enum PublishErrorEnum {
  CannotPublishUserIcedUp = 'CANNOT_PUBLISH_USER_ICED_UP',
  CannotPublishUserInvalidated = 'CANNOT_PUBLISH_USER_INVALIDATED',
  CannotPublishUserNotCompleted = 'CANNOT_PUBLISH_USER_NOT_COMPLETED',
  CannotPublishUserPackIsFull = 'CANNOT_PUBLISH_USER_PACK_IS_FULL',
  CannotPublishUserSoftDeleted = 'CANNOT_PUBLISH_USER_SOFT_DELETED',
  CannotPublishUserUnapproved = 'CANNOT_PUBLISH_USER_UNAPPROVED',
  /** unauthorized */
  Unauthorized = 'UNAUTHORIZED',
  /** User already published */
  UserAlreadyPublished = 'USER_ALREADY_PUBLISHED',
  /** User cannot be published */
  UserCannotBePublished = 'USER_CANNOT_BE_PUBLISHED'
}

export type Query = {
  __typename?: 'Query';
  affiliatedAssignments: Array<StudentParticipation>;
  appointment?: Maybe<Appointment>;
  appointmentClaim?: Maybe<AppointmentClaim>;
  appointmentClaims?: Maybe<AppointmentClaimConnection>;
  appointments?: Maybe<AppointmentConnection>;
  /** Autocomplete for professionals using the elasticsearch services */
  autocompleteAmbassadors: Array<AmbassadorAutocompleteResult>;
  checkEmailAvailability: CheckEmailAvailabilityQueryPayload;
  /** Check the validity of invitation_token depending on flow */
  checkInvitationToken?: Maybe<UserInvitation>;
  companies?: Maybe<Array<Company>>;
  company?: Maybe<Company>;
  companyEmployee?: Maybe<CompanyEmployee>;
  companyEmployeeAppointment?: Maybe<CompanyEmployeeAppointment>;
  companyEmployeeAppointments: CompanyEmployeeAppointmentsPayload;
  companyEmployees?: Maybe<CompanyEmployeeConnection>;
  companyReport?: Maybe<CompanyReport>;
  /** A set that contains all of the company sectors */
  companySectors?: Maybe<Array<CompanySector>>;
  configuredAssignment: CoachAssignment;
  configuredAssignments?: Maybe<Array<CoachAssignment>>;
  conversation?: Maybe<Conversation>;
  conversations?: Maybe<ConversationConnection>;
  crmFeatureActivated: Scalars['Boolean'];
  currentUser?: Maybe<User>;
  currentUserV2?: Maybe<AmbivalentUser>;
  defaultMessageTemplates: Array<DefaultMessageTemplate>;
  departments?: Maybe<Array<Scalars['String']>>;
  getUser: AmbivalentUser;
  /** Search for schools based on text input and additional filters. */
  gouvSchoolSearch: Array<GouvSchoolSearchResults>;
  localME: PrivateData;
  /** Get all mandatory skills */
  mandatorySkillsAutocomplete: Array<MandatorySkill>;
  me: PrivateStudentProfile | PrivateCompanyEmployeeProfile;
  mjgEmployees: Array<MjgEmployee>;
  mobileNotificationSubscriptions: MobileNotificationSubscriptions;
  myProfile: PrivateStudentProfile | PrivateCompanyEmployeeProfile;
  notification: Notification;
  notifications: NotificationConnection;
  partnerSchool?: Maybe<PartnerSchool>;
  professionalProfile?: Maybe<PublicProfessionalProfile>;
  professionalRecruitingWorkingAreas: Array<Scalars['String']>;
  program?: Maybe<Program>;
  /** Search for professionals using the elasticsearch services */
  searchAmbassadors: AmbassadorSearchResult;
  /** Gets the facets for the professional search with the document count for each key. */
  searchFacets: Array<SearchFacet>;
  searchSchoolSubjects: Array<Scalars['String']>;
  settings?: Maybe<Settings>;
  storedMessages: Array<StoredMessage>;
  user: AmbivalentUser;
  userMessageTemplates: Array<UserMessageTemplate>;
  users?: Maybe<UserConnection>;
  wishlist?: Maybe<PublicProfessionalProfileConnection>;
};


export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryAppointmentClaimArgs = {
  id: Scalars['ID'];
};


export type QueryAppointmentClaimsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filters?: Maybe<AppointmentClaimsFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<AppointmentClaimsSortFieldsEnum>>;
};


export type QueryAppointmentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filters?: Maybe<AppointmentsFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<AppointmentsSortFieldsEnum>>;
};


export type QueryAutocompleteAmbassadorsArgs = {
  searchMode?: Maybe<SearchTypeEnum>;
  term: Array<Scalars['String']>;
};


export type QueryCheckEmailAvailabilityArgs = {
  email: Scalars['String'];
};


export type QueryCheckInvitationTokenArgs = {
  flow: Profile;
  invitationToken: Scalars['String'];
};


export type QueryCompaniesArgs = {
  asAdmin?: Maybe<Scalars['Boolean']>;
};


export type QueryCompanyArgs = {
  companyId: Scalars['ID'];
};


export type QueryCompanyEmployeeArgs = {
  employeeId: Scalars['ID'];
};


export type QueryCompanyEmployeeAppointmentArgs = {
  appointmentId: Scalars['ID'];
};


export type QueryCompanyEmployeeAppointmentsArgs = {
  companyId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryCompanyEmployeesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  companyId: Scalars['ID'];
  filters?: Maybe<CompenyEmployeesQueryFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<CompanyEmployeesQuerySortEnum>>;
};


export type QueryCompanyReportArgs = {
  companyId: Scalars['ID'];
  range?: CompanyReportRange;
};


export type QueryConfiguredAssignmentArgs = {
  assignmentId: Scalars['ID'];
};


export type QueryConversationArgs = {
  conversationId: Scalars['ID'];
};


export type QueryConversationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filters?: Maybe<ConversationsFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCrmFeatureActivatedArgs = {
  professionalUserId: Scalars['ID'];
};


export type QueryDefaultMessageTemplatesArgs = {
  userId: Scalars['ID'];
};


export type QueryDepartmentsArgs = {
  locale?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryGouvSchoolSearchArgs = {
  filters?: Maybe<GouvSchoolSearchFilter>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryMandatorySkillsAutocompleteArgs = {
  term: Scalars['String'];
};


export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filters?: Maybe<NotificationsFiltersInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProfessionalProfileArgs = {
  professionalId: Scalars['ID'];
};


export type QueryProfessionalRecruitingWorkingAreasArgs = {
  professionalUserId: Scalars['ID'];
};


export type QueryProgramArgs = {
  id?: Maybe<Scalars['ID']>;
  participationCode?: Maybe<Scalars['ID']>;
  programInvitationId?: Maybe<Scalars['ID']>;
};


export type QuerySearchAmbassadorsArgs = {
  availableAmbassadors?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  searchFacets?: Maybe<Array<SearchFacetInput>>;
  searchGooglePlaceId?: Maybe<Scalars['String']>;
  searchMode?: Maybe<SearchTypeEnum>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QuerySearchFacetsArgs = {
  availableAmbassadors?: Maybe<Scalars['Boolean']>;
  searchFacets?: Maybe<Array<SearchFacetInput>>;
  searchGooglePlaceId?: Maybe<Scalars['String']>;
  searchMode?: Maybe<SearchTypeEnum>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QuerySearchSchoolSubjectsArgs = {
  locale?: Maybe<Scalars['String']>;
  term: Scalars['String'];
};


export type QueryStoredMessagesArgs = {
  conversationId: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserMessageTemplatesArgs = {
  userId: Scalars['ID'];
};


export type QueryUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<UsersFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryWishlistArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum RefreshAccessTokenErrorEnum {
  /** Invalid refresh token */
  InvalidRefreshToken = 'INVALID_REFRESH_TOKEN'
}

/** Autogenerated return type of RefreshAccessToken. */
export type RefreshAccessTokenPayload = {
  __typename?: 'RefreshAccessTokenPayload';
  accessToken: Scalars['String'];
};

/** Autogenerated return type of RemoveRecruitingWorkingArea. */
export type RemoveRecruitingWorkingAreaPayload = {
  __typename?: 'RemoveRecruitingWorkingAreaPayload';
  result: Scalars['Boolean'];
  workingAreas: Array<Scalars['String']>;
};

/** Report a member */
export type Report = {
  __typename?: 'Report';
  explanation: Scalars['String'];
  id: Scalars['ID'];
  reason: ReportReason;
  reportedId: Scalars['ID'];
};

export enum ReportAppointmentDidNotTookPlaceMutationErrorEnum {
  AlreadyReported = 'ALREADY_REPORTED',
  CannotReportFutureAppointment = 'CANNOT_REPORT_FUTURE_APPOINTMENT',
  CannotReportReviewedAppointment = 'CANNOT_REPORT_REVIEWED_APPOINTMENT',
  DescriptionRequired = 'DESCRIPTION_REQUIRED',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedError = 'UNEXPECTED_ERROR'
}

export enum ReportMemberErrorEnum {
  MustHaveConversationTogether = 'MUST_HAVE_CONVERSATION_TOGETHER',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

export enum ReportReason {
  Behavior = 'BEHAVIOR',
  DidNotShow = 'DID_NOT_SHOW',
  IsRobot = 'IS_ROBOT',
  IsSpam = 'IS_SPAM',
  NotResponding = 'NOT_RESPONDING',
  Other = 'OTHER'
}

export enum RequestPublicationErrorEnum {
  CannotRequestPublication = 'CANNOT_REQUEST_PUBLICATION',
  CannotRequestPublicationUserFreemiumCannotRequestPublication = 'CANNOT_REQUEST_PUBLICATION_USER_FREEMIUM_CANNOT_REQUEST_PUBLICATION',
  CannotRequestPublicationUserModerationOngoing = 'CANNOT_REQUEST_PUBLICATION_USER_MODERATION_ONGOING',
  CannotRequestPublicationUserNotCompleted = 'CANNOT_REQUEST_PUBLICATION_USER_NOT_COMPLETED',
  CannotRequestPublicationUserPremiumCannotRequestPublication = 'CANNOT_REQUEST_PUBLICATION_USER_PREMIUM_CANNOT_REQUEST_PUBLICATION',
  CannotRequestPublicationUserSoftDeleted = 'CANNOT_REQUEST_PUBLICATION_USER_SOFT_DELETED',
  Unauthorized = 'UNAUTHORIZED'
}

export enum ReviewAppointmentAsAmbassadorMutationErrorEnum {
  CannotReviewFutureAppointment = 'CANNOT_REVIEW_FUTURE_APPOINTMENT',
  CurrentUserAlreadyReviewed = 'CURRENT_USER_ALREADY_REVIEWED',
  CurrentUserIsNotTheAmbassador = 'CURRENT_USER_IS_NOT_THE_AMBASSADOR',
  MessageForHrRequired = 'MESSAGE_FOR_HR_REQUIRED',
  MessageRequired = 'MESSAGE_REQUIRED',
  NotFound = 'NOT_FOUND',
  Question_2Required = 'QUESTION_2_REQUIRED',
  Question_3Required = 'QUESTION_3_REQUIRED',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedValidationError = 'UNEXPECTED_VALIDATION_ERROR'
}

export enum ReviewAppointmentAsMemberMutationErrorEnum {
  AspirationStartAtMustBeFuture = 'ASPIRATION_START_AT_MUST_BE_FUTURE',
  CannotReviewFutureAppointment = 'CANNOT_REVIEW_FUTURE_APPOINTMENT',
  CurrentUserAlreadyReviewed = 'CURRENT_USER_ALREADY_REVIEWED',
  CurrentUserIsNotTheMember = 'CURRENT_USER_IS_NOT_THE_MEMBER',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedValidationError = 'UNEXPECTED_VALIDATION_ERROR',
  WorkingAreaNotInTheList = 'WORKING_AREA_NOT_IN_THE_LIST'
}

export enum SaveNotificationTokenErrorEnum {
  Unauthorized = 'UNAUTHORIZED'
}

export type SaveTokenFields = {
  __typename?: 'SaveTokenFields';
  notificationPushToken: Scalars['String'];
};

/** A school Aspiration */
export type SchoolAspiration = {
  __typename?: 'SchoolAspiration';
  grade?: Maybe<Grade>;
  year?: Maybe<Scalars['String']>;
};

export type SchoolAspirationInput = {
  grade?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** A school coach profile */
export type SchoolCoach = PublicProfile & {
  __typename?: 'SchoolCoach';
  avatar?: Maybe<Scalars['AvatarUrl']>;
  avatars: Avatar;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  status: SchoolCoachStatus;
  userId: Scalars['ID'];
};


/** A school coach profile */
export type SchoolCoachAvatarArgs = {
  version?: Maybe<PictureVersion>;
};

/** The status of the school coach */
export enum SchoolCoachStatus {
  /** Anonymized */
  Anonymized = 'ANONYMIZED',
  /** Available */
  Available = 'AVAILABLE',
  /** Soft deleted */
  SoftDeleted = 'SOFT_DELETED'
}

export type SchoolInterestAvailableDates = {
  __typename?: 'SchoolInterestAvailableDates';
  key: Scalars['String'];
  name: Scalars['String'];
};


export type SchoolInterestAvailableDatesNameArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

export type SchoolInterestAvailableGrades = {
  __typename?: 'SchoolInterestAvailableGrades';
  key: Scalars['String'];
  name: Scalars['String'];
};


export type SchoolInterestAvailableGradesNameArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

export type SchoolTeacherDetails = {
  schoolName?: Maybe<Scalars['String']>;
  schoolTown?: Maybe<Scalars['String']>;
  schoolType?: Maybe<Scalars['String']>;
  schoolZipCode?: Maybe<Scalars['String']>;
};

export enum SearchAmbassadorsQueryErrorEnum {
  InvalidGooglePlaceId = 'INVALID_GOOGLE_PLACE_ID',
  /** page must be >= 0 */
  PageInvalid = 'PAGE_INVALID',
  /** perPage must be between 1 and 999 */
  PerPageInvalid = 'PER_PAGE_INVALID'
}

export type SearchFacet = {
  __typename?: 'SearchFacet';
  buckets: Array<Bucket>;
  key: FacetKeyEnum;
};

export type SearchFacetInput = {
  key: FacetKeyEnum;
  values: Array<Scalars['String']>;
};

export enum SearchFacetsQueryErrorEnum {
  InvalidGooglePlaceId = 'INVALID_GOOGLE_PLACE_ID'
}

export enum SearchTypeEnum {
  OnlyProfessionals = 'ONLY_PROFESSIONALS',
  OnlySchoolTeachers = 'ONLY_SCHOOL_TEACHERS',
  OnlyStudents = 'ONLY_STUDENTS'
}

export type SecondarySituation = {
  __typename?: 'SecondarySituation';
  grades?: Maybe<Array<GradeEnum>>;
  gradesV2?: Maybe<Array<Grade>>;
  key: SecondarySituationEnum;
  name: Scalars['String'];
  primarySituation?: Maybe<PrimarySituation>;
};


export type SecondarySituationNameArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

/** Secondary situations */
export enum SecondarySituationEnum {
  Autre = 'AUTRE',
  Cfa = 'CFA',
  College = 'COLLEGE',
  EcoleCommerce = 'ECOLE_COMMERCE',
  EcoleIngenieurs = 'ECOLE_INGENIEURS',
  Iut = 'IUT',
  LyceeAgr = 'LYCEE_AGR',
  LyceeGt = 'LYCEE_GT',
  LyceePro = 'LYCEE_PRO',
  Universite = 'UNIVERSITE'
}

export enum SendMessageMutationErrorEnum {
  ConversationDisabled = 'CONVERSATION_DISABLED',
  DuplicateMessage = 'DUPLICATE_MESSAGE',
  NotFound = 'NOT_FOUND',
  TextBlank = 'TEXT_BLANK',
  Unauthorized = 'UNAUTHORIZED'
}

/** Application settings */
export type Settings = {
  __typename?: 'Settings';
  comesFrom: Array<ComesFrom>;
  /** @deprecated deprecated :wave: */
  deprecatedTest?: Maybe<Scalars['String']>;
  diplomas: Array<Diploma>;
  franceLevel1AdministrativeAreas: Array<FranceLevel1AdministrativeArea>;
  franceTravailAuthorizationUrl: Scalars['String'];
  franceTravailMobileOauthRedirectUri: Scalars['String'];
  franceTravailOauthClientId: Scalars['String'];
  franceTravailOauthRealm: Scalars['String'];
  franceTravailOauthScope: Scalars['String'];
  grades: Array<Grade>;
  internshipTypes: Array<InternshipType>;
  jobSeekerSupportOrganizationTypes: Array<JobSeekerSupportOrganizationType>;
  jobSeekerSupportPrograms: Array<JobSeekerSupportProgram>;
  jobTitles: Array<JobTitle>;
  laReunionGooglePlaceId: Scalars['String'];
  laReunionMeetingPlace: Scalars['String'];
  linkedinAuthorizationUrl: Scalars['String'];
  linkedinMobileOauthRedirectUri: Scalars['String'];
  linkedinOauthClientId: Scalars['String'];
  linkedinOauthScope: Scalars['String'];
  notDeprecatedTest?: Maybe<Scalars['String']>;
  primarySituations: Array<PrimarySituation>;
  recommendedQualifications: Array<Scalars['String']>;
  secondarySituations: Array<SecondarySituation>;
};


/** Application settings */
export type SettingsLaReunionMeetingPlaceArgs = {
  locale?: Maybe<AvailableLanguagesEnum>;
};

export enum SigninErrorEnum {
  /** __Signin__: Needs email confirmation */
  EmailUnconfirmed = 'EMAIL_UNCONFIRMED',
  /** User not found error */
  NotFound = 'NOT_FOUND',
  /** __Signin__: Needs tutor email confirmation */
  TutorEmailUnconfirmed = 'TUTOR_EMAIL_UNCONFIRMED',
  /** __Signin__: User account is restricted */
  UserAccountRestricted = 'USER_ACCOUNT_RESTRICTED',
  /** __Signin__: User is soft deleted */
  UserSoftDeleted = 'USER_SOFT_DELETED'
}

export enum SignupErrorEnum {
  /**
   * __Validation error__:
   *   can't be blank
   */
  BirthdateBlank = 'BIRTHDATE_BLANK',
  /**
   * __Validation error__:
   *   Birthdate is invalid
   */
  BirthdateIsInvalid = 'BIRTHDATE_IS_INVALID',
  /**
   * __Validation error__:
   *   can't be blank
   */
  EmailBlank = 'EMAIL_BLANK',
  /**
   * __Validation error__:
   *   Email domain is blacklisted
   * 
   * 
   * - blacklist => "private"
   */
  EmailForbiddenDomain = 'EMAIL_FORBIDDEN_DOMAIN',
  /**
   * __Validation error__:
   *   is invalid
   * 
   * 
   * - with => "?"
   */
  EmailInvalid = 'EMAIL_INVALID',
  /**
   * __Validation error__:
   *   Email is already used
   */
  EmailTaken = 'EMAIL_TAKEN',
  /**
   * __Validation error__:
   *   Email is already used, but it has not been confirmed
   */
  EmailTakenAndUnconfirmed = 'EMAIL_TAKEN_AND_UNCONFIRMED',
  /**
   * __Validation error__:
   *   Email is already used, by a soft deleted user
   */
  EmailTakenBySoftDeletedUser = 'EMAIL_TAKEN_BY_SOFT_DELETED_USER',
  /** __Signin__: Needs email confirmation */
  EmailUnconfirmed = 'EMAIL_UNCONFIRMED',
  /** The provider identity is already used by another user */
  IdentityAlreadyUsedByAnotherUser = 'IDENTITY_ALREADY_USED_BY_ANOTHER_USER',
  /** Identity oauthNonce is not linked to any provider account */
  IdentityNotFound = 'IDENTITY_NOT_FOUND',
  /**
   * __Validation error__:
   *   Invitation token has already been used by another user
   */
  InvitationTokenAlreadyClaimed = 'INVITATION_TOKEN_ALREADY_CLAIMED',
  /**
   * __Validation error__:
   *   can't be blank
   */
  TutorEmailBlank = 'TUTOR_EMAIL_BLANK',
  /**
   * __Validation error__:
   *   is invalid
   * 
   * 
   * - with => "?"
   */
  TutorEmailInvalid = 'TUTOR_EMAIL_INVALID',
  /**
   * __Validation error__:
   *   Tutor email must be different from user email
   */
  TutorEmailMustBeDifferent = 'TUTOR_EMAIL_MUST_BE_DIFFERENT',
  /** __Signin__: Needs tutor email confirmation */
  TutorEmailUnconfirmed = 'TUTOR_EMAIL_UNCONFIRMED',
  /** __Signin__: User account is restricted */
  UserAccountRestricted = 'USER_ACCOUNT_RESTRICTED',
  /** __Signin__: User is soft deleted */
  UserSoftDeleted = 'USER_SOFT_DELETED'
}

export enum SnoozeAppointmentClaimReviewMutationErrorEnum {
  AlreadyResolved = 'ALREADY_RESOLVED',
  AlreadySnoozed = 'ALREADY_SNOOZED',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

export enum SnoozeReviewMutationErrorEnum {
  CannotSnoozeFutureAppointment = 'CANNOT_SNOOZE_FUTURE_APPOINTMENT',
  CannotSnoozeReviewedAppointment = 'CANNOT_SNOOZE_REVIEWED_APPOINTMENT',
  NotFound = 'NOT_FOUND',
  ReviewAlreadySnoozed = 'REVIEW_ALREADY_SNOOZED',
  Unauthorized = 'UNAUTHORIZED',
  UnexpectedError = 'UNEXPECTED_ERROR'
}

export enum SoftDeleteMutationErrorEnum {
  /** User is already soft deleted */
  AlreadySoftDeleted = 'ALREADY_SOFT_DELETED',
  /** User id is not found */
  NotFound = 'NOT_FOUND',
  /** Only Admin RH can soft delete a premium ambassador account */
  OnlyAdminCanSoftDelete = 'ONLY_ADMIN_CAN_SOFT_DELETE',
  /** You're not allowed to soft delete this user */
  Unauthorized = 'UNAUTHORIZED',
  /** Cannot soft delete a user with future appointments */
  UserHaveFutureAppointments = 'USER_HAVE_FUTURE_APPOINTMENTS'
}

export type StoredMessage = {
  __typename?: 'StoredMessage';
  id: Scalars['ID'];
  text: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  status: MessageLocalStatus;
  sender?: Maybe<PrivateData>;
  fromMessageTemplateId?: Maybe<Scalars['String']>;
};

/** A student Aspiration */
export type StudentAspiration = {
  __typename?: 'StudentAspiration';
  duration: Scalars['String'];
  postalCode: Scalars['String'];
  startsAtMonth: Scalars['Int'];
  startsAtYear: Scalars['Int'];
  type: StudentProfessionalProjectType;
};

/** an appointment made by a student while being in an assignment */
export type StudentAssignmentAppointment = {
  __typename?: 'StudentAssignmentAppointment';
  claim: Scalars['Boolean'];
  conversation?: Maybe<Conversation>;
  from: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  status: StudentAssignmentAppointmentStatus;
};

/** The status of an appointment belonging to an assignment */
export enum StudentAssignmentAppointmentStatus {
  /** Confirmed */
  Confirmed = 'CONFIRMED',
  /** Invalidated */
  Invalidated = 'INVALIDATED',
  /** Pending */
  Pending = 'PENDING'
}

/** Student mission */
export enum StudentMission {
  /** Account created */
  AccountCreated = 'ACCOUNT_CREATED',
  /** Fifteenth appointment occured */
  FifteenthAppointmentOccurred = 'FIFTEENTH_APPOINTMENT_OCCURRED',
  /** Fifth appointment occurred */
  FifthAppointmentOccurred = 'FIFTH_APPOINTMENT_OCCURRED',
  /** Fiftieth appointment occured */
  FiftiethAppointmentOccurred = 'FIFTIETH_APPOINTMENT_OCCURRED',
  /** First appointment occured */
  FirstAppointmentOccurred = 'FIRST_APPOINTMENT_OCCURRED',
  /** First appointment review */
  FirstAppointmentReview = 'FIRST_APPOINTMENT_REVIEW',
  /** First message sent */
  FirstMessageSent = 'FIRST_MESSAGE_SENT',
  /** Hundredth appointment occurred */
  HundredthAppointmentOccurred = 'HUNDREDTH_APPOINTMENT_OCCURRED',
  /** Profile completed */
  ProfileComplete = 'PROFILE_COMPLETE',
  /** Seventy fifth appointment occurred */
  SeventyFifthAppointmentOccurred = 'SEVENTY_FIFTH_APPOINTMENT_OCCURRED',
  /** Tenth appointment occurred */
  TenthAppointmentOccurred = 'TENTH_APPOINTMENT_OCCURRED',
  /** Third appointment occurred */
  ThirdAppointmentOccurred = 'THIRD_APPOINTMENT_OCCURRED',
  /** Thithieth appointment occured */
  ThirthiethAppointmentOccurred = 'THIRTHIETH_APPOINTMENT_OCCURRED',
  /** Twentieth appointment occured */
  TwentiethAppointmentOccurred = 'TWENTIETH_APPOINTMENT_OCCURRED'
}

/** A participation viewed by a student */
export type StudentParticipation = {
  __typename?: 'StudentParticipation';
  appointments: Array<StudentAssignmentAppointment>;
  appointmentsGoal: Scalars['Int'];
  coach?: Maybe<SchoolCoach>;
  confirmedAppointmentsCount: Scalars['Int'];
  description: Scalars['String'];
  endsAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  schoolName: Scalars['String'];
  startsAt: Scalars['ISO8601DateTime'];
};

/** An FDR assignment viewed by a student */
export type StudentProfessionalProject = {
  __typename?: 'StudentProfessionalProject';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  startsAtMonth: Scalars['Int'];
  startsAtYear: Scalars['Int'];
  status: StudentProfessionalProjectStatus;
  type: StudentProfessionalProjectType;
};

/** Student professional project status */
export enum StudentProfessionalProjectStatus {
  /** Have found */
  HaveFound = 'HAVE_FOUND',
  /** Looking for */
  LookingFor = 'LOOKING_FOR'
}

/** Student professional project type */
export enum StudentProfessionalProjectType {
  /** Apprenticeship */
  Apprenticeship = 'APPRENTICESHIP',
  /** Fixed-term contract job */
  Cdd = 'CDD',
  /** Open-ended contract job */
  Cdi = 'CDI',
  /** Internship */
  Internship = 'INTERNSHIP',
  /** International volunteering in administration */
  Via = 'VIA',
  /** International volunteering in business */
  Vie = 'VIE'
}

/** All the possible status of a student profile */
export enum StudentStatus {
  /** The student is anonymized */
  Anonymized = 'ANONYMIZED',
  /** the student is available */
  Available = 'AVAILABLE',
  /** The student is soft deleted */
  SoftDeleted = 'SOFT_DELETED'
}

export enum StudyLevel {
  Bac_2 = 'BAC_2',
  Bac_3 = 'BAC_3',
  Bac_4 = 'BAC_4',
  Bac_5 = 'BAC_5',
  BacGt = 'BAC_GT',
  BacPro = 'BAC_PRO',
  BacSuperior_5 = 'BAC_SUPERIOR_5',
  BepCap = 'BEP_CAP',
  None = 'NONE',
  NoStudyLevelRequired = 'NO_STUDY_LEVEL_REQUIRED',
  SpecificFormation = 'SPECIFIC_FORMATION'
}

export enum UnbookAppointmentMutationErrorEnum {
  AlreadyCancelled = 'ALREADY_CANCELLED',
  BookAppointmentUnauthorized = 'BOOK_APPOINTMENT_UNAUTHORIZED',
  CannotCancelPastAppointment = 'CANNOT_CANCEL_PAST_APPOINTMENT',
  MustBeTheConversationRecipient = 'MUST_BE_THE_CONVERSATION_RECIPIENT',
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

export enum UnpublishErrorEnum {
  CannotUnpublishUser = 'CANNOT_UNPUBLISH_USER',
  CannotUnpublishUserNotPublished = 'CANNOT_UNPUBLISH_USER_NOT_PUBLISHED',
  Unauthorized = 'UNAUTHORIZED'
}

export enum UnwishAmbassadorMutationErrorEnum {
  /** not found */
  NotFound = 'NOT_FOUND',
  /** already wished */
  NotWished = 'NOT_WISHED',
  /** unauthorized */
  Unauthorized = 'UNAUTHORIZED'
}

export enum UpdateMessageTemplateMutationErrorEnum {
  MessageTemplateInvalid = 'MESSAGE_TEMPLATE_INVALID',
  NotFound = 'NOT_FOUND',
  TitleBlank = 'TITLE_BLANK',
  TitleTooShort = 'TITLE_TOO_SHORT',
  Unauthorized = 'UNAUTHORIZED'
}

export enum UpdateMobileNotificationSubscriptionsMutationErrorEnum {
  Unauthorized = 'UNAUTHORIZED'
}

export enum UpdateUserErrorEnum {
  /**
   * __Validation error__:
   *   Minimagick raise an MiniMagick::Invalid error
   */
  AvatarInvalidFile = 'AVATAR_INVALID_FILE',
  /**
   * __Validation error__:
   *   File extension not in the allowlist: jpg jpeg gif png
   */
  AvatarInvalidFileExtension = 'AVATAR_INVALID_FILE_EXTENSION',
  /**
   * __Validation error__:
   *   Avatar must be a squared image
   */
  AvatarMustBeSquare = 'AVATAR_MUST_BE_SQUARE',
  /**
   * __Validation error__:
   *   Cannot edit premium ambassador company
   */
  CompanyNameUneditable = 'COMPANY_NAME_UNEDITABLE',
  /**
   * __Validation error__:
   *   can't be blank
   */
  CurrentPasswordBlank = 'CURRENT_PASSWORD_BLANK',
  /**
   * __Validation error__:
   *   Does not match with user's password
   */
  CurrentPasswordDoesNotMatch = 'CURRENT_PASSWORD_DOES_NOT_MATCH',
  /**
   * __Validation error__:
   *   can't be blank
   */
  EmailBlank = 'EMAIL_BLANK',
  /**
   * __Validation error__:
   *   Email domain is blacklisted
   * 
   * 
   * - blacklist => "private"
   */
  EmailForbiddenDomain = 'EMAIL_FORBIDDEN_DOMAIN',
  /**
   * __Validation error__:
   *   is invalid
   * 
   * 
   * - with => "?"
   */
  EmailInvalid = 'EMAIL_INVALID',
  /**
   * __Validation error__:
   *   has already been taken
   * 
   * 
   * - allow_blank => "true"
   */
  EmailTaken = 'EMAIL_TAKEN',
  /**
   * __Validation error__:
   *   first name blank
   */
  FirstNameBlank = 'FIRST_NAME_BLANK',
  /**
   * __Validation error__:
   *   is not included in the list
   * 
   * 
   * - in => "1..50"
   */
  MaxMeetingsPerMonthInclusion = 'MAX_MEETINGS_PER_MONTH_INCLUSION',
  /**
   * __Validation error__:
   *   doit être rempli(e)
   */
  MeetingPreferencesBlank = 'MEETING_PREFERENCES_BLANK',
  /**
   * __Validation error__:
   *   key is not in the list
   * 
   * 
   * - in => "["middle_school_student", "high_school_student", "graduate_student", "in_activity"]"
   */
  MeetingPreferencesKeyNotFound = 'MEETING_PREFERENCES_KEY_NOT_FOUND',
  /**
   * __Validation error__:
   *   Should be a E164 phone phone number
   */
  NormalizedPhoneMustBeAPlausiblePhoneNumber = 'NORMALIZED_PHONE_MUST_BE_A_PLAUSIBLE_PHONE_NUMBER',
  /**
   * __Validation error__:
   *   is too long (maximum is 150 characters)
   * 
   * 
   * - maximum => "150"
   */
  PositionTooLong = 'POSITION_TOO_LONG',
  /**
   * __Validation error__:
   *   is not included in the list
   * 
   * 
   * - in => "["none", "cep", "brevet", "cap", "bep", "bac_gt", "bac_pro", "bac_2", "bac_3", "bac_4", "bac_5", "doctorat"]"
   */
  RecommendedQualificationsInclusion = 'RECOMMENDED_QUALIFICATIONS_INCLUSION',
  /**
   * __Validation error__:
   *   Profile cannot be republished
   */
  RequestPublicationRequestPublication = 'REQUEST_PUBLICATION_REQUEST_PUBLICATION'
}


export type User = {
  __typename?: 'User';
  accessToken?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['AvatarUrl']>;
  companyAdminProfiles?: Maybe<Array<CompanyAdminProfile>>;
  companyName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isCoach: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  mustConfirmEmail: Scalars['Boolean'];
  mustConfirmTutorEmail: Scalars['Boolean'];
  primarySituation?: Maybe<PrimarySituationEnum>;
  professionalProfile?: Maybe<PublicProfessionalProfile>;
  profileCompletionStatus: Scalars['Percentage'];
  profiles: Array<Profile>;
  refreshToken?: Maybe<Scalars['String']>;
  studentProfile?: Maybe<PrivateStudentProfile>;
  tutorEmail?: Maybe<Scalars['String']>;
  unreadConversationsCount: Scalars['Int'];
};


export type UserAvatarArgs = {
  version?: Maybe<PictureVersion>;
};

export type UserAuthorization = {
  __typename?: 'UserAuthorization';
  canPublish: Scalars['Boolean'];
  canRequestAdminRhModeration: Scalars['Boolean'];
  canRequestAdminRhUnpublication: Scalars['Boolean'];
  canRequestModeration: Scalars['Boolean'];
  canUnpublish: Scalars['Boolean'];
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UserEdge>>;
  /** A list of nodes. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

/** A decrypted invitation_token / invitation */
export type UserInvitation = {
  __typename?: 'UserInvitation';
  email: Scalars['Email'];
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
};

export type UserMessageTemplate = {
  __typename?: 'UserMessageTemplate';
  body?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export enum UserMessageTemplatesQueryErrorEnum {
  NotFound = 'NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED'
}

/** Tag who represent type of users a pro want to met */
export type UserMetTag = {
  __typename?: 'UserMetTag';
  graduateStudent?: Maybe<Scalars['Boolean']>;
  inActivity?: Maybe<Scalars['Boolean']>;
  juniorHighSchoolStudent?: Maybe<Scalars['Boolean']>;
  schoolTeacher?: Maybe<Scalars['Boolean']>;
  seniorHighSchoolStudent?: Maybe<Scalars['Boolean']>;
};

export enum UserProfile {
  Empty = 'EMPTY',
  InOffice = 'IN_OFFICE',
  InSchool = 'IN_SCHOOL',
  PreviouslyInOffice = 'PREVIOUSLY_IN_OFFICE'
}

export type UserProfileTypeInterface = {
  profileType: UserProfile;
};

export enum UserQueryErrorEnum {
  /** User not found */
  NotFound = 'NOT_FOUND',
  /** Unauthorized */
  Unauthorized = 'UNAUTHORIZED'
}

/** List of filters for users query */
export type UsersFilter = {
  email?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Scalars['String']>>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type VerifyEmployeesListPayload = {
  __typename?: 'VerifyEmployeesListPayload';
  changes: Array<Change>;
  invitations: Array<Invitation>;
};

export enum VisibilityStatusEnum {
  Hibernated = 'HIBERNATED',
  IcedUp = 'ICED_UP',
  Invalidated = 'INVALIDATED',
  Published = 'PUBLISHED',
  Restricted = 'RESTRICTED',
  SoftDeleted = 'SOFT_DELETED',
  Unpublished = 'UNPUBLISHED'
}

export enum WishAmbassadorMutationErrorEnum {
  /** already wished */
  AlreadyWished = 'ALREADY_WISHED',
  /** not found */
  NotFound = 'NOT_FOUND',
  /** unauthorized */
  Unauthorized = 'UNAUTHORIZED',
  /** user is archived / soft_deleted / restricted */
  UserIsArchived = 'USER_IS_ARCHIVED',
  /** user is not ambassador */
  UserIsNotAmbassador = 'USER_IS_NOT_AMBASSADOR'
}

export type ChangeEmailMutationVariables = Exact<{
  userId: Scalars['ID'];
  email: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangeEmailMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'AmbivalentUser' }
    & Pick<AmbivalentUser, 'email'>
  )> }
);

export type RequestNewPasswordMutationVariables = Exact<{
  email: Scalars['Email'];
}>;


export type RequestNewPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestNewPassword'>
);

export type CreateHibernationMutationVariables = Exact<{
  startAt: Scalars['ISO8601Date'];
  endAt: Scalars['ISO8601Date'];
}>;


export type CreateHibernationMutation = (
  { __typename?: 'Mutation' }
  & { createHibernation?: Maybe<(
    { __typename?: 'Hibernation' }
    & Pick<Hibernation, 'id'>
  )> }
);

export type DestroyHibernationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DestroyHibernationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'destroyHibernation'>
);

export type GetFirstAppointmentClaimToForceReviewQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFirstAppointmentClaimToForceReviewQuery = (
  { __typename?: 'Query' }
  & { appointmentClaims?: Maybe<(
    { __typename?: 'AppointmentClaimConnection' }
    & { nodes: Array<(
      { __typename?: 'AppointmentClaim' }
      & Pick<AppointmentClaim, 'id' | 'currentUserMustReview'>
    )> }
  )> }
);

export type GetFirstAppointmentToForceReviewQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFirstAppointmentToForceReviewQuery = (
  { __typename?: 'Query' }
  & { appointments?: Maybe<(
    { __typename?: 'AppointmentConnection' }
    & { nodes: Array<(
      { __typename?: 'Appointment' }
      & Pick<Appointment, 'id' | 'currentUserCanReview' | 'currentUserMustReview' | 'currentUserIsAmbassador' | 'currentUserIsMember' | 'currentUserCanSnooze'>
    )> }
  )> }
);

export type GetCurrentUserWantToShareQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserWantToShareQuery = (
  { __typename?: 'Query' }
  & { currentUserV2?: Maybe<(
    { __typename?: 'AmbivalentUser' }
    & Pick<AmbivalentUser, 'wantToShare'>
  )> }
);

export type GetCurrentUserContextQueryVariables = Exact<{
  completionContext: CompletionContextEnum;
}>;


export type GetCurrentUserContextQuery = (
  { __typename?: 'Query' }
  & { currentUserV2?: Maybe<(
    { __typename?: 'AmbivalentUser' }
    & Pick<AmbivalentUser, 'id' | 'intercomHash' | 'email' | 'lastName' | 'firstName' | 'birthdate' | 'mustConfirmEmail' | 'premiumAmbassador' | 'premiumSchoolAmbassador' | 'canShare' | 'canContact' | 'currentUserCanContact' | 'currentUserCanContactErrors' | 'professionalId' | 'studentId' | 'isCoach' | 'isMjgAdmin' | 'isAmbassador' | 'bypassInteractionRules' | 'firstPublishedAt' | 'jobSeekerSupportPrograms' | 'messageExplanationDraft' | 'messagePresentationDraft' | 'publicationStatus' | 'newMessagesFromMembersCount' | 'newMessagesFromAmbassadorsCount' | 'createdAt' | 'updatedAt' | 'wishesCount' | 'locatedInLaReunion' | 'profileType'>
    & { avatars: (
      { __typename?: 'Avatar' }
      & Pick<Avatar, 'url'>
    ), hibernations: Array<(
      { __typename?: 'Hibernation' }
      & Pick<Hibernation, 'id' | 'effectiveWakeAt' | 'reason' | 'requestedWakeAt' | 'scheduledStartAt'>
    )>, completionFor: (
      { __typename?: 'ContextualCompletion' }
      & Pick<ContextualCompletion, 'percentage' | 'complete'>
    ), memberCompletion: (
      { __typename?: 'ContextualCompletion' }
      & Pick<ContextualCompletion, 'percentage' | 'complete'>
    ), stepByStepCompletion: (
      { __typename?: 'ContextualCompletion' }
      & Pick<ContextualCompletion, 'complete'>
    ), ambassadorCompletion: (
      { __typename?: 'ContextualCompletion' }
      & Pick<ContextualCompletion, 'percentage' | 'complete'>
    ), companyAdminProfiles?: Maybe<Array<(
      { __typename?: 'CompanyAdminProfile' }
      & Pick<CompanyAdminProfile, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    )>>, primarySituation?: Maybe<(
      { __typename?: 'PrimarySituation' }
      & Pick<PrimarySituation, 'key' | 'name'>
    )>, secondarySituation?: Maybe<(
      { __typename?: 'SecondarySituation' }
      & Pick<SecondarySituation, 'key' | 'name'>
    )>, accompaniedStudyLevels?: Maybe<Array<(
      { __typename?: 'Grade' }
      & Pick<Grade, 'key' | 'name'>
    )>>, availableConversationTypes: Array<(
      { __typename?: 'ConversationType' }
      & Pick<ConversationType, 'key' | 'name'>
    )> }
  )> }
);

export type Get_SettingsQueryVariables = Exact<{
  locale?: Maybe<AvailableLanguagesEnum>;
}>;


export type Get_SettingsQuery = (
  { __typename?: 'Query' }
  & { currentUserV2?: Maybe<(
    { __typename?: 'AmbivalentUser' }
    & Pick<AmbivalentUser, 'id'>
  )>, settings?: Maybe<(
    { __typename?: 'Settings' }
    & Pick<Settings, 'franceTravailAuthorizationUrl' | 'linkedinAuthorizationUrl'>
    & { primarySituations: Array<(
      { __typename?: 'PrimarySituation' }
      & Pick<PrimarySituation, 'key' | 'name'>
      & { secondarySituations?: Maybe<Array<(
        { __typename?: 'SecondarySituation' }
        & Pick<SecondarySituation, 'key' | 'name'>
      )>> }
    )>, secondarySituations: Array<(
      { __typename?: 'SecondarySituation' }
      & Pick<SecondarySituation, 'key' | 'name'>
    )> }
  )> }
);

export type UnwishAmbassadorSoftDeletionMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UnwishAmbassadorSoftDeletionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unwishAmbassador'>
);

export type Get_CompanyQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_CompanyQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'publishedEmployeesCount' | 'employeePackSize' | 'isPremiumSchool'>
    & { customAttributesSet: (
      { __typename?: 'CustomAttributesSet' }
      & Pick<CustomAttributesSet, 'customEmployeeAttribute1' | 'companyAttributeValues1' | 'customEmployeeAttribute2' | 'companyAttributeValues2' | 'customEmployeeAttribute3' | 'companyAttributeValues3'>
    ) }
  )> }
);

export type Get_Custom_Attributes_FieldsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_Custom_Attributes_FieldsQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { customAttributesSet: (
      { __typename?: 'CustomAttributesSet' }
      & Pick<CustomAttributesSet, 'customEmployeeAttribute1' | 'companyAttributeValues1' | 'customEmployeeAttribute2' | 'companyAttributeValues2' | 'customEmployeeAttribute3' | 'companyAttributeValues3'>
    ) }
  )> }
);

export type Add_EmployeeMutationVariables = Exact<{
  companyId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  companyAttribute1?: Maybe<Scalars['String']>;
  companyAttribute2?: Maybe<Scalars['String']>;
  companyAttribute3?: Maybe<Scalars['String']>;
}>;


export type Add_EmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteEmployee'>
);

export type Get_Company_Employee_PackQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_Company_Employee_PackQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'publishedEmployeesCount' | 'employeePackSize' | 'isPremiumSchool'>
  )> }
);

export type Get_Company_Employee_Custom_Attributes_DistributionQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_Company_Employee_Custom_Attributes_DistributionQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id'>
    & { employeesCustomAttribute1Distribution?: Maybe<(
      { __typename?: 'CustomAttributeDistribution' }
      & Pick<CustomAttributeDistribution, 'name' | 'total'>
      & { distribution: Array<(
        { __typename?: 'CustomAttributeDistributionItem' }
        & Pick<CustomAttributeDistributionItem, 'key' | 'count' | 'percentage'>
      )> }
    )>, employeesCustomAttribute2Distribution?: Maybe<(
      { __typename?: 'CustomAttributeDistribution' }
      & Pick<CustomAttributeDistribution, 'name' | 'total'>
      & { distribution: Array<(
        { __typename?: 'CustomAttributeDistributionItem' }
        & Pick<CustomAttributeDistributionItem, 'key' | 'count' | 'percentage'>
      )> }
    )>, employeesCustomAttribute3Distribution?: Maybe<(
      { __typename?: 'CustomAttributeDistribution' }
      & Pick<CustomAttributeDistribution, 'name' | 'total'>
      & { distribution: Array<(
        { __typename?: 'CustomAttributeDistributionItem' }
        & Pick<CustomAttributeDistributionItem, 'key' | 'count' | 'percentage'>
      )> }
    )> }
  )> }
);

export type Get_Company_Employee_Status_DistributionQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_Company_Employee_Status_DistributionQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'employeePackSize' | 'employeesVisibleCount' | 'employeesInCrisisCount' | 'employeesIcedUpCount' | 'employeesAvailablePlacesCount'>
  )> }
);

export type GetEmployeeListQueryVariables = Exact<{
  companyId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<CompanyEmployeesQuerySortEnum> | CompanyEmployeesQuerySortEnum>;
  filters?: Maybe<CompenyEmployeesQueryFilter>;
}>;


export type GetEmployeeListQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & { customAttributesSet: (
      { __typename?: 'CustomAttributesSet' }
      & Pick<CustomAttributesSet, 'companyAttributeValues1' | 'companyAttributeValues2' | 'companyAttributeValues3' | 'customEmployeeAttribute1' | 'customEmployeeAttribute2' | 'customEmployeeAttribute3' | 'customEmployeeAttribute1NormalizedName' | 'customEmployeeAttribute2NormalizedName' | 'customEmployeeAttribute3NormalizedName'>
    ) }
  )>, companyEmployees?: Maybe<(
    { __typename?: 'CompanyEmployeeConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ), nodes: Array<(
      { __typename?: 'CompanyEmployee' }
      & Pick<CompanyEmployee, 'id' | 'avatar' | 'firstName' | 'lastName' | 'jobTitle' | 'preparedDiplomaTitle' | 'email' | 'status' | 'lastInvitationEmailSentAt' | 'visibleInSearch' | 'companyAttribute1' | 'companyAttribute2' | 'companyAttribute3' | 'userId' | 'canApprove' | 'canArchive' | 'canPublish' | 'canUnpublish' | 'canReceiveInvitationReminder'>
    )> }
  )> }
);

export type UnpublishEmployeeMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UnpublishEmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unpublish'>
);

export type PublishEmployeeMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type PublishEmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'publish'>
);

export type ApproveEmployeeMutationVariables = Exact<{
  employeeId: Scalars['ID'];
}>;


export type ApproveEmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveEmployee'>
);

export type ArchiveEmployeeMutationVariables = Exact<{
  employeeId: Scalars['ID'];
}>;


export type ArchiveEmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'archiveEmployee'>
);

export type SendEmployeeInvitationEmailMutationVariables = Exact<{
  employeeId: Scalars['ID'];
}>;


export type SendEmployeeInvitationEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendEmployeeInvitationEmail'>
);

export type Get_Allowed_Employee_Actions_And_StatusQueryVariables = Exact<{
  employeeId: Scalars['ID'];
}>;


export type Get_Allowed_Employee_Actions_And_StatusQuery = (
  { __typename?: 'Query' }
  & { companyEmployee?: Maybe<(
    { __typename?: 'CompanyEmployee' }
    & Pick<CompanyEmployee, 'userId' | 'canPublish' | 'canApprove' | 'canUnpublish' | 'canArchive' | 'lastInvitationEmailSentAt' | 'visibleInSearch' | 'status'>
  )> }
);

export type Approve_EmployeeMutationVariables = Exact<{
  employeeId: Scalars['ID'];
}>;


export type Approve_EmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveEmployee'>
);

export type Archive_EmployeeMutationVariables = Exact<{
  employeeId: Scalars['ID'];
}>;


export type Archive_EmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'archiveEmployee'>
);

export type PublishMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type PublishMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'publish'>
);

export type UnpublishMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UnpublishMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unpublish'>
);

export type Verify_Employees_ListMutationVariables = Exact<{
  companyId: Scalars['ID'];
  file: Scalars['Upload'];
}>;


export type Verify_Employees_ListMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmployeesList?: Maybe<(
    { __typename?: 'VerifyEmployeesListPayload' }
    & { changes: Array<(
      { __typename?: 'Change' }
      & { employee: (
        { __typename?: 'CompanyEmployee' }
        & Pick<CompanyEmployee, 'id' | 'firstName' | 'lastName' | 'email'>
      ), changes: Array<(
        { __typename?: 'AttrChange' }
        & Pick<AttrChange, 'attr' | 'from' | 'to'>
      )> }
    )>, invitations: Array<(
      { __typename?: 'Invitation' }
      & Pick<Invitation, 'id' | 'firstName' | 'lastName' | 'email' | 'position'>
    )> }
  )> }
);

export type Import_Employees_ListMutationVariables = Exact<{
  companyId: Scalars['ID'];
  file: Scalars['Upload'];
}>;


export type Import_Employees_ListMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'importEmployeesList'>
);

export type Get_CompaniesQueryVariables = Exact<{
  asAdmin?: Maybe<Scalars['Boolean']>;
}>;


export type Get_CompaniesQuery = (
  { __typename?: 'Query' }
  & { companies?: Maybe<Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>> }
);

export type Get_Company_Employee_Appointments_TotalQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_Company_Employee_Appointments_TotalQuery = (
  { __typename?: 'Query' }
  & { companyEmployeeAppointments: (
    { __typename?: 'CompanyEmployeeAppointmentsPayload' }
    & Pick<CompanyEmployeeAppointmentsPayload, 'totalCount'>
  ) }
);

export type Get_Company_TypeQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_Company_TypeQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'isPremiumSchool'>
  )> }
);

export type Get_Company_Employee_AppointmentsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
}>;


export type Get_Company_Employee_AppointmentsQuery = (
  { __typename?: 'Query' }
  & { companyEmployeeAppointments: (
    { __typename?: 'CompanyEmployeeAppointmentsPayload' }
    & { appointments: Array<(
      { __typename?: 'CompanyEmployeeAppointment' }
      & Pick<CompanyEmployeeAppointment, 'id' | 'from'>
      & { professional?: Maybe<(
        { __typename?: 'CompanyEmployee' }
        & Pick<CompanyEmployee, 'id' | 'firstName' | 'lastName' | 'email' | 'avatar'>
      )>, member: (
        { __typename?: 'AppointmentInterlocutor' }
        & Pick<AppointmentInterlocutor, 'firstName' | 'lastName'>
      ), reviewFromProfessional: (
        { __typename?: 'AppointmentReviewFromProfessional' }
        & Pick<AppointmentReviewFromProfessional, 'appointmentStatus' | 'answer1' | 'answer2' | 'answer3' | 'messageForHr'>
      ), reviewFromStudent: (
        { __typename?: 'AppointmentReviewFromStudent' }
        & Pick<AppointmentReviewFromStudent, 'appointmentStatus' | 'answer1' | 'answer2' | 'answer3' | 'wantToBeRecontacted' | 'wantsToBeRecontactedBySchool'>
        & { aspiration?: Maybe<(
          { __typename?: 'StudentAspiration' }
          & Pick<StudentAspiration, 'type' | 'duration' | 'startsAtMonth' | 'startsAtYear' | 'postalCode'>
        )> }
      ) }
    )> }
  ) }
);

export type Get_Company_Employee_AppointmentQueryVariables = Exact<{
  appointmentId: Scalars['ID'];
}>;


export type Get_Company_Employee_AppointmentQuery = (
  { __typename?: 'Query' }
  & { companyEmployeeAppointment?: Maybe<(
    { __typename?: 'CompanyEmployeeAppointment' }
    & Pick<CompanyEmployeeAppointment, 'id' | 'from'>
    & { professional?: Maybe<(
      { __typename?: 'CompanyEmployee' }
      & Pick<CompanyEmployee, 'id' | 'firstName' | 'lastName' | 'email' | 'avatar' | 'status' | 'jobTitle' | 'companyAttribute1' | 'companyAttribute2' | 'companyAttribute3'>
    )>, member: (
      { __typename?: 'AppointmentInterlocutor' }
      & Pick<AppointmentInterlocutor, 'firstName' | 'lastName' | 'email' | 'gender' | 'phoneNumber' | 'memberPresentation' | 'meetingPlace' | 'currentGradeOrDiploma' | 'anonymizedOrDeleted' | 'acquiredDiplomaTitle' | 'acquiredDiplomaBeginYear' | 'acquiredDiplomaAcquisitionYear' | 'acquiredDiplomaSchoolName'>
      & { avatar: (
        { __typename?: 'Avatar' }
        & Pick<Avatar, 'url'>
      ), secondarySituation?: Maybe<(
        { __typename?: 'SecondarySituation' }
        & Pick<SecondarySituation, 'key' | 'name'>
      )>, acquiredDiplomaLevel?: Maybe<(
        { __typename?: 'Diploma' }
        & Pick<Diploma, 'key' | 'name'>
      )> }
    ), reviewFromProfessional: (
      { __typename?: 'AppointmentReviewFromProfessional' }
      & Pick<AppointmentReviewFromProfessional, 'appointmentStatus' | 'answer1' | 'answer2' | 'answer3' | 'messageForHr'>
    ), reviewFromStudent: (
      { __typename?: 'AppointmentReviewFromStudent' }
      & Pick<AppointmentReviewFromStudent, 'appointmentStatus' | 'answer1' | 'answer2' | 'answer3' | 'wantToBeRecontacted' | 'wantsToBeRecontactedBySchool'>
      & { aspiration?: Maybe<(
        { __typename?: 'StudentAspiration' }
        & Pick<StudentAspiration, 'type' | 'duration' | 'startsAtMonth' | 'startsAtYear' | 'postalCode'>
      )> }
    ) }
  )> }
);

export type Get_Company_Created_AtQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_Company_Created_AtQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'createdAt' | 'name'>
  )> }
);

export type Get_Company_For_ConfigurationQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_Company_For_ConfigurationQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'description' | 'companySize' | 'companySector' | 'companyLogo' | 'recruitingWorkingAreas'>
    & { customAttributesSet: (
      { __typename?: 'CustomAttributesSet' }
      & Pick<CustomAttributesSet, 'customEmployeeAttribute1' | 'companyAttributeValues1' | 'customEmployeeAttribute2' | 'companyAttributeValues2' | 'customEmployeeAttribute3' | 'companyAttributeValues3'>
    ) }
  )>, companySectors?: Maybe<Array<(
    { __typename?: 'CompanySector' }
    & Pick<CompanySector, 'id' | 'name'>
  )>> }
);

export type Get_Company_Custom_AttributesQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_Company_Custom_AttributesQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'isPremiumSchool'>
    & { customAttributesSet: (
      { __typename?: 'CustomAttributesSet' }
      & Pick<CustomAttributesSet, 'customEmployeeAttribute1' | 'companyAttributeValues1' | 'customEmployeeAttribute2' | 'companyAttributeValues2' | 'customEmployeeAttribute3' | 'companyAttributeValues3'>
    ) }
  )> }
);

export type Update_Company_Custom_AttributesMutationVariables = Exact<{
  companyId: Scalars['ID'];
  number: Scalars['Int'];
  customEmployeeAttribute: Scalars['String'];
  companyAttributeValues: Array<Scalars['String']> | Scalars['String'];
}>;


export type Update_Company_Custom_AttributesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCustomAttribute'>
);

export type Get_Company_General_InformationsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_Company_General_InformationsQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'companySize' | 'companySector' | 'companyLogo' | 'isPremiumSchool'>
    & { establishmentType?: Maybe<(
      { __typename?: 'SecondarySituation' }
      & Pick<SecondarySituation, 'key' | 'name'>
    )> }
  )> }
);

export type Get_Company_SectorsQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Company_SectorsQuery = (
  { __typename?: 'Query' }
  & { companySectors?: Maybe<Array<(
    { __typename?: 'CompanySector' }
    & Pick<CompanySector, 'id' | 'name'>
  )>> }
);

export type Update_Company_General_InformationsMutationVariables = Exact<{
  companyId: Scalars['ID'];
  sectorId?: Maybe<Scalars['ID']>;
  size?: Maybe<CompanySize>;
  establishmentType?: Maybe<SecondarySituationEnum>;
}>;


export type Update_Company_General_InformationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompany'>
);

export type Get_Company_PresentationQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_Company_PresentationQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'description' | 'isPremiumSchool'>
  )> }
);

export type Update_Company_DescriptionMutationVariables = Exact<{
  companyId: Scalars['ID'];
  description: Scalars['String'];
}>;


export type Update_Company_DescriptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompany'>
);

export type Get_Company_Recruiting_Working_AreasQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_Company_Recruiting_Working_AreasQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'recruitingWorkingAreas' | 'isPremiumSchool'>
  )> }
);

export type Add_Company_Recruiting_AreaMutationVariables = Exact<{
  companyId: Scalars['ID'];
  workingArea: Scalars['String'];
}>;


export type Add_Company_Recruiting_AreaMutation = (
  { __typename?: 'Mutation' }
  & { addCompanyRecruitingWorkArea: (
    { __typename?: 'AddRecruitingWorkingAreaPayload' }
    & Pick<AddRecruitingWorkingAreaPayload, 'workingAreas'>
  ) }
);

export type Remove_Company_Recruiting_AreaMutationVariables = Exact<{
  companyId: Scalars['ID'];
  workingArea: Scalars['String'];
}>;


export type Remove_Company_Recruiting_AreaMutation = (
  { __typename?: 'Mutation' }
  & { removeCompanyRecruitingWorkArea: (
    { __typename?: 'RemoveRecruitingWorkingAreaPayload' }
    & Pick<RemoveRecruitingWorkingAreaPayload, 'workingAreas'>
  ) }
);

export type Get_Global_Company_ReportQueryVariables = Exact<{
  range: CompanyReportRange;
  companyId: Scalars['ID'];
}>;


export type Get_Global_Company_ReportQuery = (
  { __typename?: 'Query' }
  & { companyReport?: Maybe<(
    { __typename?: 'CompanyReport' }
    & Pick<CompanyReport, 'companyId' | 'from' | 'to' | 'lastUpdatedAt' | 'newConversationCount' | 'contactedEmployeesCount' | 'responseRate' | 'appointmentCount' | 'publishedEmployeeCount' | 'publishedEmployeesWithAppointmentRate' | 'employeesViewCount' | 'uniqueProfileViewsFromStudents' | 'studentsSeeingThemselvesInTheSectorRate' | 'unfollowedConversationsCount' | 'wishCount' | 'likeCount' | 'isPremiumSchool'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name'>
    ), bestProfiles: Array<(
      { __typename?: 'CompanyReportTopEmployee' }
      & Pick<CompanyReportTopEmployee, 'id' | 'avatar' | 'firstName' | 'lastName' | 'jobTitle' | 'appointmentCount' | 'medal'>
    )> }
  )> }
);

export type Get_Company_Activity_ReportQueryVariables = Exact<{
  range: CompanyReportRange;
  companyId: Scalars['ID'];
}>;


export type Get_Company_Activity_ReportQuery = (
  { __typename?: 'Query' }
  & { companyReport?: Maybe<(
    { __typename?: 'CompanyReport' }
    & Pick<CompanyReport, 'companyId' | 'newConversationCount' | 'contactedEmployeesCount' | 'responseRate' | 'appointmentCount' | 'publishedEmployeeCount' | 'publishedEmployeesWithAppointmentRate' | 'isPremiumSchool'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'name'>
    ) }
  )> }
);

export type Get_Company_Attractiveness_ReportQueryVariables = Exact<{
  range: CompanyReportRange;
  companyId: Scalars['ID'];
}>;


export type Get_Company_Attractiveness_ReportQuery = (
  { __typename?: 'Query' }
  & { companyReport?: Maybe<(
    { __typename?: 'CompanyReport' }
    & Pick<CompanyReport, 'companyId' | 'employeesViewCount' | 'uniqueProfileViewsFromStudents' | 'wishCount' | 'likeCount' | 'studentsSeeingThemselvesInTheSectorRate' | 'isPremiumSchool'>
  )> }
);

export type Get_Last_Generated_DatetimeQueryVariables = Exact<{
  range: CompanyReportRange;
  companyId: Scalars['ID'];
}>;


export type Get_Last_Generated_DatetimeQuery = (
  { __typename?: 'Query' }
  & { companyReport?: Maybe<(
    { __typename?: 'CompanyReport' }
    & Pick<CompanyReport, 'companyId' | 'lastUpdatedAt'>
  )> }
);

export type Get_Company_Report_Date_RangeQueryVariables = Exact<{
  range: CompanyReportRange;
  companyId: Scalars['ID'];
}>;


export type Get_Company_Report_Date_RangeQuery = (
  { __typename?: 'Query' }
  & { companyReport?: Maybe<(
    { __typename?: 'CompanyReport' }
    & Pick<CompanyReport, 'companyId' | 'from' | 'to'>
  )> }
);

export type Get_Company_Information_For_DashboardQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_Company_Information_For_DashboardQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'companyLogo' | 'publishedEmployeesCount' | 'employeePackSize' | 'activeProfilePercentage' | 'lastReportGeneratedAt' | 'isPremiumSchool'>
  )> }
);

export type Get_Last_Report_DataQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type Get_Last_Report_DataQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'lastReportGeneratedAt'>
  )> }
);

export type Get_Company_Report_Top_EmployeesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  range: CompanyReportRange;
}>;


export type Get_Company_Report_Top_EmployeesQuery = (
  { __typename?: 'Query' }
  & { companyReport?: Maybe<(
    { __typename?: 'CompanyReport' }
    & { bestProfiles: Array<(
      { __typename?: 'CompanyReportTopEmployee' }
      & Pick<CompanyReportTopEmployee, 'id' | 'avatar' | 'firstName' | 'lastName' | 'jobTitle' | 'appointmentCount' | 'medal'>
    )> }
  )> }
);

export type Get_Employee_User_IdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Get_Employee_User_IdQuery = (
  { __typename?: 'Query' }
  & { companyEmployee?: Maybe<(
    { __typename?: 'CompanyEmployee' }
    & Pick<CompanyEmployee, 'ambivalent' | 'userId'>
  )> }
);

export type Get_Companies_ListQueryVariables = Exact<{
  asAdmin?: Maybe<Scalars['Boolean']>;
}>;


export type Get_Companies_ListQuery = (
  { __typename?: 'Query' }
  & { companies?: Maybe<Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>> }
);

export type Get_Appointment_Claim_To_ReviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Get_Appointment_Claim_To_ReviewQuery = (
  { __typename?: 'Query' }
  & { appointmentClaim?: Maybe<(
    { __typename?: 'AppointmentClaim' }
    & Pick<AppointmentClaim, 'id' | 'from' | 'type' | 'createdAt' | 'updatedAt' | 'meetingPlace' | 'currentUserCanSnooze'>
    & { conversation: (
      { __typename?: 'Conversation' }
      & Pick<Conversation, 'id'>
    ), member: (
      { __typename?: 'AppointmentInterlocutor' }
      & Pick<AppointmentInterlocutor, 'id' | 'firstName' | 'lastName' | 'profileType' | 'normalizedPhone' | 'companyName' | 'currentSchoolName' | 'previousCompanyName'>
      & { avatar: (
        { __typename?: 'Avatar' }
        & { small: (
          { __typename?: 'Image' }
          & Pick<Image, 'url'>
        ) }
      ), primarySituation?: Maybe<(
        { __typename?: 'PrimarySituation' }
        & Pick<PrimarySituation, 'key'>
      )>, secondarySituation?: Maybe<(
        { __typename?: 'SecondarySituation' }
        & Pick<SecondarySituation, 'key'>
      )> }
    ) }
  )> }
);

export type Approve_Appointment_Claim_MutationMutationVariables = Exact<{
  appointmentClaimId: Scalars['ID'];
}>;


export type Approve_Appointment_Claim_MutationMutation = (
  { __typename?: 'Mutation' }
  & { approveAppointmentClaim: (
    { __typename?: 'AppointmentClaim' }
    & Pick<AppointmentClaim, 'id'>
    & { appointment?: Maybe<(
      { __typename?: 'Appointment' }
      & Pick<Appointment, 'id'>
    )> }
  ) }
);

export type Snooze_Appointment_Claim_MutationMutationVariables = Exact<{
  appointmentClaimId: Scalars['ID'];
}>;


export type Snooze_Appointment_Claim_MutationMutation = (
  { __typename?: 'Mutation' }
  & { snoozeAppointmentClaimReview: (
    { __typename?: 'AppointmentClaim' }
    & Pick<AppointmentClaim, 'id'>
  ) }
);

export type Deny_Appointment_Claim_MutationMutationVariables = Exact<{
  appointmentClaimId: Scalars['ID'];
  reason: AppointmentDidNotTookPlaceReasonEnum;
  description?: Maybe<Scalars['String']>;
}>;


export type Deny_Appointment_Claim_MutationMutation = (
  { __typename?: 'Mutation' }
  & { denyAppointmentClaim: (
    { __typename?: 'AppointmentClaim' }
    & Pick<AppointmentClaim, 'id'>
  ) }
);

export type Get_Appointment_ListQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  filters?: Maybe<AppointmentsFilter>;
  sort?: Maybe<Array<AppointmentsSortFieldsEnum> | AppointmentsSortFieldsEnum>;
}>;


export type Get_Appointment_ListQuery = (
  { __typename?: 'Query' }
  & { appointments?: Maybe<(
    { __typename?: 'AppointmentConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'AppointmentEdge' }
      & { node?: Maybe<(
        { __typename?: 'Appointment' }
        & Pick<Appointment, 'id' | 'type' | 'from' | 'startAt' | 'conversationId' | 'googleCalendarUrl' | 'yahooCalendarUrl' | 'meetingPlace' | 'currentUserCanReview' | 'currentUserReviewMessage' | 'currentUserInterlocutorReviewMessage' | 'currentUserRecommendInterlocutor' | 'currentUserRecommendedByInterlocutor'>
        & { conversation: (
          { __typename?: 'Conversation' }
          & Pick<Conversation, 'id' | 'currentUserIsInitiator'>
        ), currentUserInterlocutor: (
          { __typename?: 'AppointmentInterlocutor' }
          & Pick<AppointmentInterlocutor, 'firstName' | 'companyName' | 'jobTitle' | 'schoolName' | 'previousCompanyName' | 'previousPosition' | 'normalizedPhone'>
          & { primarySituation?: Maybe<(
            { __typename?: 'PrimarySituation' }
            & Pick<PrimarySituation, 'key'>
          )>, avatars: (
            { __typename?: 'Avatar' }
            & { small: (
              { __typename?: 'Image' }
              & Pick<Image, 'url'>
            ) }
          ) }
        ) }
      )> }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);

export type Get_Conversations_ListQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type Get_Conversations_ListQuery = (
  { __typename?: 'Query' }
  & { conversations?: Maybe<(
    { __typename?: 'ConversationConnection' }
    & { nodes: Array<(
      { __typename?: 'Conversation' }
      & Pick<Conversation, 'id' | 'currentUserAnswered' | 'currentUserAcknowledged' | 'currentUserIsInitiator'>
      & { interlocutor?: Maybe<(
        { __typename?: 'AmbivalentUser' }
        & Pick<AmbivalentUser, 'id' | 'firstName' | 'companyName' | 'previousCompanyName' | 'jobTitle' | 'previousPosition' | 'currentSchoolName'>
        & { avatars: (
          { __typename?: 'Avatar' }
          & Pick<Avatar, 'url'>
        ), primarySituation?: Maybe<(
          { __typename?: 'PrimarySituation' }
          & Pick<PrimarySituation, 'key' | 'name'>
        )>, secondarySituation?: Maybe<(
          { __typename?: 'SecondarySituation' }
          & Pick<SecondarySituation, 'key' | 'name'>
        )> }
      )>, lastMessage?: Maybe<(
        { __typename?: 'Message' }
        & Pick<Message, 'createdAt' | 'text' | 'seenAt'>
        & { sender?: Maybe<(
          { __typename?: 'PublicProfessionalProfile' }
          & Pick<PublicProfessionalProfile, 'id'>
        ) | (
          { __typename?: 'PublicStudentProfile' }
          & Pick<PublicStudentProfile, 'id'>
        ) | (
          { __typename?: 'SchoolCoach' }
          & Pick<SchoolCoach, 'id'>
        )> }
      )> }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'startCursor' | 'hasPreviousPage'>
    ) }
  )> }
);

export type Get_ConversationQueryVariables = Exact<{
  conversationId: Scalars['ID'];
}>;


export type Get_ConversationQuery = (
  { __typename?: 'Query' }
  & { conversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'createdAt' | 'disabled' | 'currentUserIsInitiator' | 'currentUserCanClaimAppointment' | 'currentUserCanBookAppointment' | 'currentUserCanBookAppointmentErrors' | 'conversationType'>
    & { lastMessage?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'seenAt'>
      & { sender?: Maybe<(
        { __typename?: 'PublicProfessionalProfile' }
        & Pick<PublicProfessionalProfile, 'id'>
      ) | (
        { __typename?: 'PublicStudentProfile' }
        & Pick<PublicStudentProfile, 'id'>
      ) | (
        { __typename?: 'SchoolCoach' }
        & Pick<SchoolCoach, 'id'>
      )> }
    )>, messages: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'text' | 'createdAt'>
      & { user: (
        { __typename?: 'ConversationInterlocutor' }
        & Pick<ConversationInterlocutor, 'id' | 'firstName' | 'lastName'>
        & { avatar: (
          { __typename?: 'Avatar' }
          & { thumb: (
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          ) }
        ) }
      ), attachment?: Maybe<(
        { __typename?: 'MessageAttachment' }
        & Pick<MessageAttachment, 'name' | 'url' | 'scannedByAntivirus'>
      )> }
    )>, interlocutorV2?: Maybe<(
      { __typename?: 'ConversationInterlocutor' }
      & Pick<ConversationInterlocutor, 'id' | 'firstName' | 'lastName' | 'normalizedPhone' | 'profileType' | 'position' | 'companyName' | 'currentGradeOrDiploma' | 'currentSchoolName' | 'previousCompanyName' | 'previousPosition' | 'meetingPlace' | 'memberPresentation' | 'ambassadorPresentation' | 'acquiredDiplomaTitle' | 'acquiredDiplomaSchoolName' | 'acquiredDiplomaBeginYear' | 'acquiredDiplomaAcquisitionYear' | 'softDeleted' | 'minor' | 'superMinor'>
      & { avatar: (
        { __typename?: 'Avatar' }
        & Pick<Avatar, 'url'>
      ), acquiredDiplomaLevel?: Maybe<(
        { __typename?: 'Diploma' }
        & Pick<Diploma, 'name'>
      )> }
    )> }
  )> }
);

export type Get_Conversation_Unresolved_Appointment_ClaimsQueryVariables = Exact<{
  conversationId: Scalars['ID'];
}>;


export type Get_Conversation_Unresolved_Appointment_ClaimsQuery = (
  { __typename?: 'Query' }
  & { appointmentClaims?: Maybe<(
    { __typename?: 'AppointmentClaimConnection' }
    & { nodes: Array<(
      { __typename?: 'AppointmentClaim' }
      & Pick<AppointmentClaim, 'id' | 'type' | 'startAt' | 'resolvedAt' | 'currentUserIsAmbassador' | 'currentUserCanReview' | 'meetingPlace'>
    )> }
  )> }
);

export type Get_Conversation_AppointmentsQueryVariables = Exact<{
  filters?: Maybe<AppointmentsFilter>;
  sort?: Maybe<Array<AppointmentsSortFieldsEnum> | AppointmentsSortFieldsEnum>;
}>;


export type Get_Conversation_AppointmentsQuery = (
  { __typename?: 'Query' }
  & { appointments?: Maybe<(
    { __typename?: 'AppointmentConnection' }
    & { nodes: Array<(
      { __typename?: 'Appointment' }
      & Pick<Appointment, 'id' | 'type' | 'meetingPlace' | 'videoconferenceLink' | 'googleCalendarUrl' | 'yahooCalendarUrl' | 'conversationId' | 'status' | 'startAt' | 'currentUserIsMember' | 'currentUserIsAmbassador' | 'currentUserCanReview' | 'currentUserCanCancel' | 'currentUserCanMove' | 'currentUserRecommendedByInterlocutor'>
      & { ambassadorReview?: Maybe<(
        { __typename?: 'AppointmentReviewFromProfessional' }
        & Pick<AppointmentReviewFromProfessional, 'appointmentStatus'>
      )>, memberReview?: Maybe<(
        { __typename?: 'AppointmentReviewFromStudent' }
        & Pick<AppointmentReviewFromStudent, 'appointmentStatus' | 'liked'>
      )> }
    )> }
  )> }
);

export type Get_Conversation_MessagesQueryVariables = Exact<{
  conversationId: Scalars['ID'];
}>;


export type Get_Conversation_MessagesQuery = (
  { __typename?: 'Query' }
  & { conversation?: Maybe<(
    { __typename?: 'Conversation' }
    & { messages: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'text' | 'createdAt' | 'seenAt'>
      & { user: (
        { __typename?: 'ConversationInterlocutor' }
        & Pick<ConversationInterlocutor, 'id' | 'firstName' | 'lastName'>
        & { avatar: (
          { __typename?: 'Avatar' }
          & { thumb: (
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          ) }
        ) }
      ), attachment?: Maybe<(
        { __typename?: 'MessageAttachment' }
        & Pick<MessageAttachment, 'name' | 'url' | 'scannedByAntivirus'>
      )> }
    )> }
  )> }
);

export type AcknowledgeConversationMutationVariables = Exact<{
  conversationId: Scalars['ID'];
}>;


export type AcknowledgeConversationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'acknowledgeConversation'>
);

export type Get_Conversation_Pro_ProfileQueryVariables = Exact<{
  conversationId: Scalars['ID'];
}>;


export type Get_Conversation_Pro_ProfileQuery = (
  { __typename?: 'Query' }
  & { conversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id'>
    & { professional?: Maybe<(
      { __typename?: 'PublicProfessionalProfile' }
      & Pick<PublicProfessionalProfile, 'id' | 'status' | 'unavailableUntil' | 'meetingsLeft'>
    )> }
  )> }
);

export type Report_MemberMutationVariables = Exact<{
  reportedId: Scalars['ID'];
  explanation: Scalars['String'];
  reason: ReportReason;
}>;


export type Report_MemberMutation = (
  { __typename?: 'Mutation' }
  & { reportMember?: Maybe<(
    { __typename?: 'Report' }
    & Pick<Report, 'id'>
  )> }
);

export type Get_Conversation_Messages_And_InterlocutorsQueryVariables = Exact<{
  conversationId: Scalars['ID'];
}>;


export type Get_Conversation_Messages_And_InterlocutorsQuery = (
  { __typename?: 'Query' }
  & { conversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id'>
    & { professional?: Maybe<(
      { __typename?: 'PublicProfessionalProfile' }
      & Pick<PublicProfessionalProfile, 'id'>
    )>, student?: Maybe<(
      { __typename?: 'PublicStudentProfile' }
      & Pick<PublicStudentProfile, 'id'>
    )>, messages: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'text' | 'createdAt'>
      & { sender?: Maybe<(
        { __typename?: 'PublicProfessionalProfile' }
        & Pick<PublicProfessionalProfile, 'id' | 'firstName' | 'lastName' | 'avatar'>
      ) | (
        { __typename?: 'PublicStudentProfile' }
        & Pick<PublicStudentProfile, 'id' | 'firstName' | 'lastName' | 'avatar'>
      ) | (
        { __typename?: 'SchoolCoach' }
        & Pick<SchoolCoach, 'id' | 'firstName' | 'lastName' | 'avatar'>
      )> }
    )> }
  )> }
);

export type Get_Default_Template_ListQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type Get_Default_Template_ListQuery = (
  { __typename?: 'Query' }
  & { defaultMessageTemplates: Array<(
    { __typename?: 'DefaultMessageTemplate' }
    & Pick<DefaultMessageTemplate, 'id' | 'title' | 'body'>
  )> }
);

export type Get_User_Template_ListQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type Get_User_Template_ListQuery = (
  { __typename?: 'Query' }
  & { userMessageTemplates: Array<(
    { __typename?: 'UserMessageTemplate' }
    & Pick<UserMessageTemplate, 'id' | 'title' | 'body'>
  )> }
);

export type Create_Message_TemplateMutationVariables = Exact<{
  userId: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
}>;


export type Create_Message_TemplateMutation = (
  { __typename?: 'Mutation' }
  & { createMessageTemplate: (
    { __typename?: 'UserMessageTemplate' }
    & Pick<UserMessageTemplate, 'id'>
  ) }
);

export type Edit_Message_TemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
}>;


export type Edit_Message_TemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateMessageTemplate: (
    { __typename?: 'UserMessageTemplate' }
    & Pick<UserMessageTemplate, 'id'>
  ) }
);

export type Delete_Message_TemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Delete_Message_TemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'destroyMessageTemplate'>
);

export type Get_Interlocutor_Received_Review_As_Members_QueryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Get_Interlocutor_Received_Review_As_Members_QueryQuery = (
  { __typename?: 'Query' }
  & { conversation?: Maybe<(
    { __typename?: 'Conversation' }
    & { interlocutorV2?: Maybe<(
      { __typename?: 'ConversationInterlocutor' }
      & { receivedReviewAsMembers: Array<(
        { __typename?: 'PastReview' }
        & Pick<PastReview, 'reviewerTitle' | 'reviewerOrganizationName' | 'feedback'>
      )> }
    )> }
  )> }
);

export type Send_MessageMutationVariables = Exact<{
  conversationId: Scalars['ID'];
  text: Scalars['String'];
  fromMessageTemplateId?: Maybe<Scalars['ID']>;
  attachment?: Maybe<Scalars['Upload']>;
}>;


export type Send_MessageMutation = (
  { __typename?: 'Mutation' }
  & { sendMessage: (
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'text' | 'createdAt'>
    & { sender?: Maybe<(
      { __typename?: 'PublicProfessionalProfile' }
      & Pick<PublicProfessionalProfile, 'id' | 'avatar' | 'firstName' | 'lastName'>
      & { professionalStatus: PublicProfessionalProfile['status'] }
    ) | (
      { __typename?: 'PublicStudentProfile' }
      & Pick<PublicStudentProfile, 'id' | 'avatar' | 'firstName' | 'lastName'>
      & { studentStatus: PublicStudentProfile['status'] }
    ) | (
      { __typename?: 'SchoolCoach' }
      & Pick<SchoolCoach, 'id' | 'avatar' | 'firstName' | 'lastName'>
    )>, attachment?: Maybe<(
      { __typename?: 'MessageAttachment' }
      & Pick<MessageAttachment, 'name' | 'url' | 'scannedByAntivirus'>
    )> }
  ) }
);

export type Get_Conversation_Created_AtQueryVariables = Exact<{
  conversationId: Scalars['ID'];
}>;


export type Get_Conversation_Created_AtQuery = (
  { __typename?: 'Query' }
  & { conversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'createdAt'>
  )> }
);

export type Get_Conversation_TypeQueryVariables = Exact<{
  conversationId: Scalars['ID'];
}>;


export type Get_Conversation_TypeQuery = (
  { __typename?: 'Query' }
  & { conversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'conversationType'>
  )> }
);

export type Get_Participants_InformationsQueryVariables = Exact<{
  conversationId: Scalars['ID'];
}>;


export type Get_Participants_InformationsQuery = (
  { __typename?: 'Query' }
  & { conversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id'>
    & { recipient: (
      { __typename?: 'AmbivalentUser' }
      & Pick<AmbivalentUser, 'id' | 'birthdate'>
    ), initiator: (
      { __typename?: 'AmbivalentUser' }
      & Pick<AmbivalentUser, 'id' | 'birthdate'>
    ) }
  )> }
);

export type GetAppointmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAppointmentQuery = (
  { __typename?: 'Query' }
  & { appointment?: Maybe<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'from' | 'meetingPlace' | 'videoconferenceLink' | 'type' | 'currentUserCanMove'>
  )> }
);

export type BookAppointmentMutationVariables = Exact<{
  userId: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
  type: AppointmentTypesEnum;
  meetingPlace?: Maybe<Scalars['String']>;
  videoconferenceLink?: Maybe<Scalars['String']>;
}>;


export type BookAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { bookAppointment: (
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id'>
  ) }
);

export type MoveAppointmentMutationVariables = Exact<{
  id: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
  type: AppointmentTypesEnum;
  meetingPlace?: Maybe<Scalars['String']>;
  videoconferenceLink?: Maybe<Scalars['String']>;
}>;


export type MoveAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { moveAppointment: (
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id'>
  ) }
);

export type ClaimAppointmentMutationVariables = Exact<{
  userId: Scalars['ID'];
  conversationId: Scalars['ID'];
  startAt: Scalars['ISO8601DateTime'];
  type: AppointmentTypesEnum;
  meetingPlace?: Maybe<Scalars['String']>;
}>;


export type ClaimAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { claimAppointment: (
    { __typename?: 'AppointmentClaim' }
    & Pick<AppointmentClaim, 'id'>
  ) }
);

export type UnbookAppointmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnbookAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { unbookAppointment: (
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id'>
  ) }
);

export type Get_Review_Interlocutor_Partial_InfosQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Get_Review_Interlocutor_Partial_InfosQuery = (
  { __typename?: 'Query' }
  & { appointment?: Maybe<(
    { __typename?: 'Appointment' }
    & { conversation: (
      { __typename?: 'Conversation' }
      & Pick<Conversation, 'currentUserIsInitiator'>
    ), professional?: Maybe<(
      { __typename?: 'PublicProfessionalProfile' }
      & Pick<PublicProfessionalProfile, 'isSubsidiaryCompany' | 'subsidiaryDesignationEn' | 'subsidiaryDesignationFr'>
    )> }
  )> }
);

export type Get_Appointment_To_ReviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Get_Appointment_To_ReviewQuery = (
  { __typename?: 'Query' }
  & { appointment?: Maybe<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'from' | 'currentUserIsAmbassador' | 'currentUserIsMember' | 'currentUserCanSnooze'>
    & { conversation: (
      { __typename?: 'Conversation' }
      & Pick<Conversation, 'id'>
    ), member: (
      { __typename?: 'AppointmentInterlocutor' }
      & Pick<AppointmentInterlocutor, 'id' | 'firstName' | 'lastName' | 'companyName' | 'previousCompanyName' | 'currentSchoolName'>
      & { avatar: (
        { __typename?: 'Avatar' }
        & Pick<Avatar, 'url'>
      ), primarySituation?: Maybe<(
        { __typename?: 'PrimarySituation' }
        & Pick<PrimarySituation, 'key'>
      )> }
    ), ambassador: (
      { __typename?: 'AppointmentInterlocutor' }
      & Pick<AppointmentInterlocutor, 'id' | 'firstName' | 'lastName' | 'companyName' | 'currentSchoolName' | 'previousCompanyName' | 'profileType'>
      & { avatar: (
        { __typename?: 'Avatar' }
        & Pick<Avatar, 'url'>
      ), primarySituation?: Maybe<(
        { __typename?: 'PrimarySituation' }
        & Pick<PrimarySituation, 'key'>
      )> }
    ), appointmentClaim?: Maybe<(
      { __typename?: 'AppointmentClaim' }
      & Pick<AppointmentClaim, 'id' | 'accepted'>
    )> }
  )> }
);

export type Get_Crm_Feature_Active_FlagQueryVariables = Exact<{
  professionalUserId: Scalars['ID'];
}>;


export type Get_Crm_Feature_Active_FlagQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'crmFeatureActivated'>
);

export type Get_Professional_Recruiting_Working_AreasQueryVariables = Exact<{
  professionalUserId: Scalars['ID'];
}>;


export type Get_Professional_Recruiting_Working_AreasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'professionalRecruitingWorkingAreas'>
);

export type Report_Appointment_Did_Not_Took_Place_MutationMutationVariables = Exact<{
  appointmentId: Scalars['ID'];
  reason: AppointmentDidNotTookPlaceReasonEnum;
  description?: Maybe<Scalars['String']>;
}>;


export type Report_Appointment_Did_Not_Took_Place_MutationMutation = (
  { __typename?: 'Mutation' }
  & { reportAppointmentDidNotTookPlace: (
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'currentUserCanReview' | 'currentUserMustReview' | 'memberReportedStatus' | 'ambassadorReportedStatus' | 'cancelled'>
  ) }
);

export type Get_Review_InfosQueryVariables = Exact<{
  id: Scalars['ID'];
  locale?: Maybe<AvailableLanguagesEnum>;
}>;


export type Get_Review_InfosQuery = (
  { __typename?: 'Query' }
  & { appointment?: Maybe<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'currentUserCanReview' | 'currentUserMustReview' | 'currentUserIsAmbassador' | 'currentUserIsMember'>
    & { ambassadorReview?: Maybe<(
      { __typename?: 'AppointmentReviewFromProfessional' }
      & Pick<AppointmentReviewFromProfessional, 'reviewQuestion1' | 'reviewQuestion2' | 'reviewQuestion3' | 'question2Skipable' | 'question3Skipable' | 'messageForHrRequired' | 'message' | 'messageForHr' | 'reviewedAt'>
    )>, memberReview?: Maybe<(
      { __typename?: 'AppointmentReviewFromStudent' }
      & Pick<AppointmentReviewFromStudent, 'reviewQuestion0' | 'reviewQuestion1' | 'reviewQuestion2' | 'reviewQuestion3' | 'schoolInterestWantToBeRecontactedTitle' | 'schoolInterestWantToBeRecontactedQuestion' | 'schoolInterestLevelAndYearQuestionTitle' | 'schoolInterestLevelQuestion' | 'schoolInterestYearQuestion' | 'message' | 'canFillAspiration' | 'canFillSchoolAspiration' | 'availableWorkingAreas' | 'companySubsidiaryDesignation' | 'reviewedAt'>
    )> }
  )> }
);

export type Review_Appointment_As_Member_MutationMutationVariables = Exact<{
  id: Scalars['ID'];
  question1: Scalars['Int'];
  question2: Scalars['Int'];
  question3: Scalars['Int'];
  aspiration?: Maybe<AspirationsInput>;
  schoolAspiration?: Maybe<SchoolAspirationInput>;
  message: Scalars['String'];
  liked: Scalars['Boolean'];
}>;


export type Review_Appointment_As_Member_MutationMutation = (
  { __typename?: 'Mutation' }
  & { reviewAppointmentAsMember: (
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id'>
    & { memberReview?: Maybe<(
      { __typename?: 'AppointmentReviewFromStudent' }
      & Pick<AppointmentReviewFromStudent, 'answer1' | 'answer2' | 'answer3' | 'message'>
    )> }
  ) }
);

export type Review_Appointment_As_Ambassador_MutationMutationVariables = Exact<{
  id: Scalars['ID'];
  question1: Scalars['Int'];
  question2?: Maybe<Scalars['Int']>;
  question3?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
  message_for_hr?: Maybe<Scalars['String']>;
}>;


export type Review_Appointment_As_Ambassador_MutationMutation = (
  { __typename?: 'Mutation' }
  & { reviewAppointmentAsAmbassador: (
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id'>
    & { ambassadorReview?: Maybe<(
      { __typename?: 'AppointmentReviewFromProfessional' }
      & Pick<AppointmentReviewFromProfessional, 'answer1' | 'answer2' | 'answer3' | 'message'>
    )> }
  ) }
);

export type SnoozeReviewMutationVariables = Exact<{
  appointmentId: Scalars['ID'];
}>;


export type SnoozeReviewMutation = (
  { __typename?: 'Mutation' }
  & { snoozeReview: (
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'currentUserCanReview' | 'currentUserMustReview' | 'currentUserCanSnooze'>
  ) }
);

export type Get_Unreadmessages_For_Mobile_NotificationQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Unreadmessages_For_Mobile_NotificationQuery = (
  { __typename?: 'Query' }
  & { localME: (
    { __typename?: 'PrivateData' }
    & Pick<PrivateData, 'unreadMessageCount'>
  ) }
);

export type Configure_FdrMutationVariables = Exact<{
  schoolName: Scalars['String'];
  diplomaLevel: Array<DiplomaLevel> | DiplomaLevel;
  campusName: Scalars['String'];
  customIdentifier?: Maybe<Scalars['String']>;
  supervisorEmail?: Maybe<Scalars['String']>;
  startsAt: Scalars['ISO8601DateTime'];
  endsAt: Scalars['ISO8601DateTime'];
  academicYear: Scalars['String'];
  numberOfMandatoryAppointmentsPerStudent: Scalars['Int'];
  appointmentBonusThreshold1?: Maybe<Scalars['Int']>;
  appointmentBonusThreshold2?: Maybe<Scalars['Int']>;
  emailReminderActivated: Scalars['Boolean'];
  emailReminderWeeklyRate?: Maybe<Scalars['Int']>;
  emailReminderLanguage?: Maybe<CommunicationLanguage>;
  conversationBonusActivated: Scalars['Boolean'];
  conversationBonusMessageCount?: Maybe<Scalars['Int']>;
  conversationBonusDeadline?: Maybe<Scalars['Int']>;
  conversationPenaltyActivated: Scalars['Boolean'];
  description: Scalars['String'];
}>;


export type Configure_FdrMutation = (
  { __typename?: 'Mutation' }
  & { createContractualFdr: (
    { __typename?: 'FDRConfigurationSuccess' }
    & Pick<FdrConfigurationSuccess, 'fdrCoachLink'>
  ) }
);

export type Get_Professional_InterlocutorQueryVariables = Exact<{
  professionalId: Scalars['ID'];
}>;


export type Get_Professional_InterlocutorQuery = (
  { __typename?: 'Query' }
  & { professionalProfile?: Maybe<(
    { __typename?: 'PublicProfessionalProfile' }
    & Pick<PublicProfessionalProfile, 'companySector'>
    & { meetingPreferences?: Maybe<Array<(
      { __typename?: 'MeetingPreference' }
      & Pick<MeetingPreference, 'key' | 'value' | 'description' | 'name'>
    )>>, user?: Maybe<(
      { __typename?: 'AmbivalentUser' }
      & Pick<AmbivalentUser, 'id' | 'firstName' | 'position' | 'companyName' | 'acceptGroupConversations'>
      & { avatars: (
        { __typename?: 'Avatar' }
        & Pick<Avatar, 'url'>
      ), companyLogo?: Maybe<(
        { __typename?: 'OrganizationLogo' }
        & Pick<OrganizationLogo, 'url'>
      )> }
    )> }
  )> }
);

export type Create_ConversationMutationVariables = Exact<{
  userId: Scalars['ID'];
  message: Scalars['String'];
  conversationType?: Maybe<ConversationTypeKeyEnum>;
  groupSessionInput?: Maybe<GroupSessionInput>;
}>;


export type Create_ConversationMutation = (
  { __typename?: 'Mutation' }
  & { createConversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id'>
  )> }
);

export type GetUserIdFromProfessionalQueryVariables = Exact<{
  professionalId: Scalars['ID'];
}>;


export type GetUserIdFromProfessionalQuery = (
  { __typename?: 'Query' }
  & { professionalProfile?: Maybe<(
    { __typename?: 'PublicProfessionalProfile' }
    & Pick<PublicProfessionalProfile, 'userId' | 'unavailableUntil' | 'published'>
  )> }
);

export type AmbassadorCompanyPresentationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AmbassadorCompanyPresentationQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'AmbivalentUser' }
    & Pick<AmbivalentUser, 'isPremiumAmbassador' | 'companyName' | 'companyDescription'>
  ) }
);

export type AmbassadorContactQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AmbassadorContactQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'AmbivalentUser' }
    & Pick<AmbivalentUser, 'remainingMeetingsAsAmbassadorThisMonth' | 'responseRateAsAmbassador' | 'responseTimeAsAmbassador' | 'currentUserCanContact' | 'currentUserCanContactErrors' | 'unavailableUntil' | 'onlyFrenchWarning' | 'onlyFrenchWarningConfirmText'>
  ) }
);

export type AmbassadorFurtherInformationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AmbassadorFurtherInformationsQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'AmbivalentUser' }
    & Pick<AmbivalentUser, 'languages' | 'studyLevels' | 'mandatorySkills'>
    & { educations: Array<(
      { __typename?: 'ProfileEducation' }
      & Pick<ProfileEducation, 'diplomaTitle' | 'schoolName' | 'endedAt'>
    )> }
  ) }
);

export type AmbassadorGalleryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AmbassadorGalleryQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'AmbivalentUser' }
    & { galleryImages: Array<(
      { __typename?: 'GalleryImage' }
      & Pick<GalleryImage, 'imageUrl'>
    )> }
  ) }
);

export type AmbassadorIdentityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AmbassadorIdentityQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'AmbivalentUser' }
    & Pick<AmbivalentUser, 'profileType' | 'isPremiumAmbassador' | 'firstName' | 'lastName' | 'position' | 'companyName' | 'meetingPlace' | 'likesReceivedCount' | 'seenProfilesCount' | 'unavailableUntil' | 'ambassadorProfileViewsCount' | 'currentGrade' | 'currentSchoolName' | 'preparedDiplomaTitle' | 'previousCompanyName' | 'previousPosition'>
    & { profileBannerDesktop?: Maybe<(
      { __typename?: 'OrganizationProfileBannerDesktop' }
      & Pick<OrganizationProfileBannerDesktop, 'url'>
    )>, profileBannerMobile?: Maybe<(
      { __typename?: 'OrganizationProfileBannerMobile' }
      & Pick<OrganizationProfileBannerMobile, 'url'>
    )>, avatars: (
      { __typename?: 'Avatar' }
      & Pick<Avatar, 'url'>
    ), companyLogo?: Maybe<(
      { __typename?: 'OrganizationLogo' }
      & Pick<OrganizationLogo, 'url'>
    )>, previousCompanyLogo?: Maybe<(
      { __typename?: 'OrganizationLogo' }
      & Pick<OrganizationLogo, 'url'>
    )> }
  ) }
);

export type CurrentUserWishedAmbassadorQueryVariables = Exact<{
  ambassadorId: Scalars['ID'];
}>;


export type CurrentUserWishedAmbassadorQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'AmbivalentUser' }
    & Pick<AmbivalentUser, 'currentUserWishedAmbassador'>
  ) }
);

export type WishAmbassadorMutationVariables = Exact<{
  ambassadorId: Scalars['ID'];
}>;


export type WishAmbassadorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'wishAmbassador'>
);

export type UnwishAmbassadorMutationVariables = Exact<{
  ambassadorId: Scalars['ID'];
}>;


export type UnwishAmbassadorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unwishAmbassador'>
);

export type AmbassadorPresentationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AmbassadorPresentationQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'AmbivalentUser' }
    & Pick<AmbivalentUser, 'ambassadorPresentation' | 'expertisePresentation' | 'aspirationPresentation' | 'interestsPresentation' | 'onlyFrenchWarning' | 'onlyFrenchWarningText' | 'onlyFrenchWarningConfirmText'>
  ) }
);

export type GetSimilarProsFromProfessionalQueryVariables = Exact<{
  professionalId: Scalars['ID'];
}>;


export type GetSimilarProsFromProfessionalQuery = (
  { __typename?: 'Query' }
  & { professionalProfile?: Maybe<(
    { __typename?: 'PublicProfessionalProfile' }
    & { recommendedProfessionals: Array<(
      { __typename?: 'AmbassadorResults' }
      & Pick<AmbassadorResults, 'id' | 'unavailableUntil' | 'positiveLikeCount' | 'professionalId' | 'firstName' | 'publicLastName' | 'lastName' | 'profileType' | 'premium' | 'currentGradeOrDiploma' | 'currentSchoolName' | 'previousPosition' | 'previousCompanyName' | 'position' | 'companyName'>
      & { avatar: (
        { __typename?: 'Avatar' }
        & Pick<Avatar, 'url'>
      ), banner?: Maybe<(
        { __typename?: 'OrganizationCardBanner' }
        & Pick<OrganizationCardBanner, 'url'>
      )>, currentSchoolLogo?: Maybe<(
        { __typename?: 'OrganizationLogo' }
        & Pick<OrganizationLogo, 'url'>
      )>, companyLogo?: Maybe<(
        { __typename?: 'OrganizationLogo' }
        & Pick<OrganizationLogo, 'url'>
      )> }
    )> }
  )> }
);

export type GetFacetsQueryVariables = Exact<{
  searchQuery?: Maybe<Scalars['String']>;
  searchMode: SearchTypeEnum;
  searchFacets?: Maybe<Array<SearchFacetInput> | SearchFacetInput>;
}>;


export type GetFacetsQuery = (
  { __typename?: 'Query' }
  & { searchFacets: Array<(
    { __typename?: 'SearchFacet' }
    & Pick<SearchFacet, 'key'>
    & { buckets: Array<(
      { __typename?: 'Bucket' }
      & Pick<Bucket, 'key' | 'label' | 'docCount'>
    )> }
  )> }
);

export type AutocompleteAmbassadorsQueryVariables = Exact<{
  term: Array<Scalars['String']> | Scalars['String'];
  searchMode: SearchTypeEnum;
}>;


export type AutocompleteAmbassadorsQuery = (
  { __typename?: 'Query' }
  & { autocompleteAmbassadors: Array<(
    { __typename?: 'AmbassadorAutocompleteResult' }
    & Pick<AmbassadorAutocompleteResult, 'type'>
    & { matchingResults: Array<(
      { __typename?: 'MatchingResult' }
      & Pick<MatchingResult, 'text'>
      & { bold: Array<(
        { __typename?: 'Bold' }
        & Pick<Bold, 'offset' | 'length'>
      )> }
    )> }
  )> }
);

export type GetSearchFacetsQueryVariables = Exact<{
  searchMode: SearchTypeEnum;
}>;


export type GetSearchFacetsQuery = (
  { __typename?: 'Query' }
  & { searchFacets: Array<(
    { __typename?: 'SearchFacet' }
    & Pick<SearchFacet, 'key'>
    & { buckets: Array<(
      { __typename?: 'Bucket' }
      & Pick<Bucket, 'key' | 'label' | 'docCount'>
    )> }
  )> }
);

export type GetAmbassadorsQueryVariables = Exact<{
  searchQuery: Scalars['String'];
  page: Scalars['Int'];
  searchMode: SearchTypeEnum;
  searchFacets: Array<SearchFacetInput> | SearchFacetInput;
  searchGooglePlaceId?: Maybe<Scalars['String']>;
  availableAmbassadorsOnly?: Maybe<Scalars['Boolean']>;
}>;


export type GetAmbassadorsQuery = (
  { __typename?: 'Query' }
  & { searchAmbassadors: (
    { __typename?: 'AmbassadorSearchResult' }
    & Pick<AmbassadorSearchResult, 'total'>
    & { results: Array<(
      { __typename?: 'AmbassadorResults' }
      & Pick<AmbassadorResults, 'profileType' | 'premium' | 'firstName' | 'lastName' | 'publicLastName' | 'companyName' | 'previousCompanyName' | 'previousPosition' | 'position' | 'positiveLikeCount' | 'unavailableUntil' | 'professionalId' | 'currentSchoolName' | 'currentGradeOrDiploma'>
      & { avatar: (
        { __typename?: 'Avatar' }
        & Pick<Avatar, 'url'>
      ), companyLogo?: Maybe<(
        { __typename?: 'OrganizationLogo' }
        & Pick<OrganizationLogo, 'url'>
      )>, banner?: Maybe<(
        { __typename?: 'OrganizationCardBanner' }
        & Pick<OrganizationCardBanner, 'url'>
      )>, currentSchoolLogo?: Maybe<(
        { __typename?: 'OrganizationLogo' }
        & Pick<OrganizationLogo, 'url'>
      )> }
    )> }
  ) }
);

export type Get_Student_WishlistQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
}>;


export type Get_Student_WishlistQuery = (
  { __typename?: 'Query' }
  & { wishlist?: Maybe<(
    { __typename?: 'PublicProfessionalProfileConnection' }
    & { nodes: Array<(
      { __typename?: 'PublicProfessionalProfile' }
      & Pick<PublicProfessionalProfile, 'id' | 'firstName' | 'lastName' | 'avatar' | 'companyName' | 'jobTitle' | 'companyLogo' | 'status' | 'profileType' | 'likeCount' | 'unavailableUntil' | 'userId'>
      & { background: PublicProfessionalProfile['avatar'] }
      & { banner?: Maybe<(
        { __typename?: 'OrganizationCardBanner' }
        & Pick<OrganizationCardBanner, 'url'>
      )> }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  )> }
);

export type GetFutureAppointmentsQueryVariables = Exact<{
  filters?: Maybe<AppointmentsFilter>;
}>;


export type GetFutureAppointmentsQuery = (
  { __typename?: 'Query' }
  & { appointments?: Maybe<(
    { __typename?: 'AppointmentConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'AppointmentEdge' }
      & { node?: Maybe<(
        { __typename?: 'Appointment' }
        & Pick<Appointment, 'id'>
      )> }
    )>> }
  )> }
);

export type SoftDeleteMutationVariables = Exact<{
  reason: Scalars['String'];
  userId: Scalars['ID'];
}>;


export type SoftDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'softDelete'>
);

export type GetProfileInformationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileInformationsQuery = (
  { __typename?: 'Query' }
  & { currentUserV2?: Maybe<(
    { __typename?: 'AmbivalentUser' }
    & Pick<AmbivalentUser, 'firstName' | 'lastName' | 'isAmbassador' | 'unavailableUntil' | 'createdAt' | 'publicationStatus' | 'professionalId' | 'canShare'>
    & { avatars: (
      { __typename?: 'Avatar' }
      & Pick<Avatar, 'url'>
    ), secondarySituation?: Maybe<(
      { __typename?: 'SecondarySituation' }
      & Pick<SecondarySituation, 'key'>
    )> }
  )> }
);

export type GetUpcomingAppointmentsQueryVariables = Exact<{
  now?: Maybe<Scalars['ISO8601DateTime']>;
}>;


export type GetUpcomingAppointmentsQuery = (
  { __typename?: 'Query' }
  & { upcomingAppointments?: Maybe<(
    { __typename?: 'AppointmentConnection' }
    & { nodes: Array<(
      { __typename?: 'Appointment' }
      & Pick<Appointment, 'startAt'>
      & { interlocutor: (
        { __typename?: 'AppointmentInterlocutor' }
        & Pick<AppointmentInterlocutor, 'firstName' | 'profileType' | 'currentSchoolName' | 'companyName'>
        & { avatars: (
          { __typename?: 'Avatar' }
          & Pick<Avatar, 'url'>
        ), companyLogo?: Maybe<(
          { __typename?: 'OrganizationLogo' }
          & Pick<OrganizationLogo, 'url'>
        )> }
      ), conversation: (
        { __typename?: 'Conversation' }
        & Pick<Conversation, 'id'>
      ) }
    )> }
  )> }
);

export type GetActivityStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActivityStatsQuery = (
  { __typename?: 'Query' }
  & { statistics?: Maybe<(
    { __typename?: 'AmbivalentUser' }
    & Pick<AmbivalentUser, 'conversationsAsAmbassadorCount' | 'conversationsAsMemberCount' | 'ambassadorProfileViewsCount' | 'seenProfilesCount' | 'wishesReceivedCount' | 'wishesCount' | 'meetingsAsAmbassadorCount' | 'collectiveMeetingsAsAmbassadorCount' | 'impactedMembersAsAmbassadorCount' | 'meetingsAsMemberCount' | 'likesReceivedCount' | 'responseRateAsAmbassador' | 'responseRateAsMember'>
  )> }
);

export type GetAmbassadorProgressionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAmbassadorProgressionQuery = (
  { __typename?: 'Query' }
  & { progression?: Maybe<(
    { __typename?: 'AmbivalentUser' }
    & { ambassadorBadges: Array<(
      { __typename?: 'Badget' }
      & Pick<Badget, 'name' | 'completed'>
    )> }
  )> }
);

export type GetMemberProgressionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMemberProgressionQuery = (
  { __typename?: 'Query' }
  & { progression?: Maybe<(
    { __typename?: 'AmbivalentUser' }
    & { memberBadges: Array<(
      { __typename?: 'Badget' }
      & Pick<Badget, 'name' | 'completed'>
    )> }
  )> }
);

export type GetScoreQueryVariables = Exact<{ [key: string]: never; }>;


export type GetScoreQuery = (
  { __typename?: 'Query' }
  & { score?: Maybe<(
    { __typename?: 'AmbivalentUser' }
    & Pick<AmbivalentUser, 'gamificationScore' | 'gamificationRank'>
    & { gamificationScoreDetails: Array<(
      { __typename?: 'GamificationScoreEntry' }
      & Pick<GamificationScoreEntry, 'name' | 'total' | 'formula'>
    )> }
  )> }
);

export type GetAvailabilityQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvailabilityQuery = (
  { __typename?: 'Query' }
  & { availability?: Maybe<(
    { __typename?: 'AmbivalentUser' }
    & Pick<AmbivalentUser, 'remainingMeetingsAsAmbassadorThisMonth' | 'maxMeetingsAsAmbassadorPerMonth' | 'remainingContactsCount' | 'maxContactsPerWeek'>
  )> }
);

export type GetProgramsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProgramsQuery = (
  { __typename?: 'Query' }
  & { programs?: Maybe<(
    { __typename?: 'AmbivalentUser' }
    & { participations: Array<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id' | 'confirmedMeetingCount' | 'certificationStatus'>
      & { program?: Maybe<(
        { __typename?: 'Program' }
        & Pick<Program, 'name' | 'meetingsQuantity' | 'startAt' | 'endAt' | 'organizationName' | 'description'>
        & { author?: Maybe<(
          { __typename?: 'CollaborationUser' }
          & Pick<CollaborationUser, 'firstName' | 'lastName'>
        )> }
      )>, contacts?: Maybe<Array<(
        { __typename?: 'HermesContact' }
        & Pick<HermesContact, 'conversationId' | 'avatarUrl' | 'firstName' | 'state'>
      )>> }
    )> }
  )> }
);
